<template>
  <b-input-group>
    <template #prepend>
      <b-input-group-text>
        <img src="@/assets/icon/bs-calendar.svg" />
      </b-input-group-text>
    </template>
    <b-input type="date" v-model="strValue" @input="onInput" />
  </b-input-group>
</template>

<script lang="ts">
import { Component, Vue, Watch, Prop } from "vue-property-decorator";

@Component
export default class cDate extends Vue {
  @Prop(String) value!: string;
  strValue: string | null = this.formatDate(); //this.value || "";

  @Watch("value")
  onValueChanged() {
    this.strValue = this.formatDate();
  }

  formatDate(): string | null {
    if (this.value) return this.value.substring(0, 10);
    else return null;
  }

  onInput() {
    this.$emit("input", this.strValue);
  }
}
</script>
