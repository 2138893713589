<template>
  <b-container fluid="xl">
    <div v-if="!submissionComplete">
      <h1 class="h2">{{ $t("STEMClub.Header") }}</h1>
      <p>
        {{ $t("STEMClub.Intro.Text") }}
      </p>

      <!-- webinar table -->
      <table>
        <thead>
          <tr>
            <th>{{ $t("STEMClub.Labels.Cohort") }}</th>
            <th>{{ $t("STEMClub.Labels.StartDate") }}</th>
            <th>{{ $t("STEMClub.Labels.EndDate") }}</th>
            <th>{{ $t("STEMClub.Labels.Language") }}</th>
            <th>{{ $t("STEMClub.Labels.DateAndTime") }}</th>
            <th>{{ $t("STEMClub.Labels.MaterialsAvailable") }}</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(cw, idx) in cohortWebinars" :key="idx">
            <td>{{ $t("STEMClub.Labels.Cohort") }} {{ cw.cohort_id }}</td>
            <td>{{ cw.start_date }}</td>
            <td>{{ cw.end_date }}</td>
            <td>{{ $t(`STEMClub.Labels.${cw.language}`) }}</td>
            <td>{{ cw.webinar_display }}</td>
            <td>
              <template v-if="cw.materials_available">
                {{ $t("STEMClub.Labels.Yes") }}
              </template>
              <template v-else>
                {{ $t("STEMClub.Labels.No") }}
              </template>
            </td>
          </tr>
        </tbody>
      </table>

      <hr />
      <cohort
        v-model="model.CohortId"
        :options="cohortOptions"
        @input="() => (model.WebinarId = undefined)"
      />
      <language
        v-model="model.Language"
        hide-label
        @input="() => (model.WebinarId = undefined)"
      />
      <!-- Select Date and time -->
      <div>
        <validation-provider
          v-slot="{ errors, valid }"
          name="Webinar"
          :rules="{ required: true }"
        >
          <b-form-group
            id="webinar-group"
            :label="$t('STEMClub.Questions.Webinar')"
            label-for="webinar"
            class="required-field"
          >
            <b-form-select
              id="webinar"
              class="binput"
              v-model="model.WebinarId"
              :options="webinarOptions"
              :state="errors[0] ? false : valid ? true : null"
            />
          </b-form-group>
          <b-form-invalid-feedback :state="valid">
            <span v-for="(error, index) in errors" :key="index">{{
              $t(error)
            }}</span>
          </b-form-invalid-feedback>
        </validation-provider>
      </div>

      <validation-observer v-slot="{ handleSubmit }" v-if="model.WebinarId">
        <b-form @submit.prevent="handleSubmit(onSubmit)">
          <!-- School / Organization Information -->
          <div>
            <your-school v-model="model.School" />
          </div>

          <!-- Participation Information  -->
          <div>
            <h2 class="h5">
              {{ $t("STEMClub.Questions.Classes.Label") }}
            </h2>
            <validation-provider
              v-slot="{ errors, valid }"
              name="classcollection"
              :rules="{ required: true, min: 1 }"
            >
              <class-collection
                ref="classCollection"
                id="classcollection"
                v-model="model.Classes"
                description-values
              />
              <b-form-invalid-feedback :state="valid">
                <span v-for="(error, index) in errors" :key="index">{{
                  $t(error)
                }}</span>
              </b-form-invalid-feedback>
            </validation-provider>
          </div>

          <!-- Additional Information -->
          <div>
            <h2 class="h5">
              {{ $t("STEMClub.Labels.AdditionalInformation") }}
            </h2>

            <number-of-adults
              v-model="model.NumAdults"
              allow-zero
              :label="$t('STEMClub.Questions.NumAdults')"
            />

            <learned-about-project
              :label="$t('STEMClub.Questions.HeardAboutProgram')"
              v-model="model.LearnedAbout"
            />
            <mailing-list id="mailinglist" v-model="model.MailingList" />

            <role v-model="model.Role" />
          </div>

          <p class="question" id="other-info-q">
            {{ $t("STEMClub.Questions.OtherInfo") }}
          </p>
          <b-form-group id="other-info-group">
            <b-form-textarea
              id="other-info"
              class="binput"
              style="min-width: 500px"
              v-model="model.OtherInfo"
            />
          </b-form-group>

          <!-- Material Request -->
          <div v-if="materialsAvailable">
            <h2 class="h5">
              {{ $t("STEMClub.MaterialRequest.Header") }}
            </h2>

            <p class="phelp">
              {{ $t("STEMClub.MaterialRequest.SubHeader") }}
            </p>

            <yes-no-radio-options
              v-model="model.Materials.RequestsMaterials"
              :label="$t('STEMClub.MaterialRequest.Questions.RequestMaterials')"
              :required="true"
              name="RequestMaterial"
              @input="onRequestsMaterialsChanged"
            />

            <div id="relevantfields" v-if="model.Materials.RequestsMaterials">
              <validation-provider
                v-slot="{ errors, valid }"
                name="benefit"
                :rules="{ required: true }"
              >
                <b-form-group
                  :label="$t('STEMClub.MaterialRequest.Questions.Benefit')"
                  label-for="benefit"
                  class="required-field question"
                >
                  <b-form-textarea
                    id="benefit"
                    v-model="model.Materials.Benefit"
                    style="min-width: 500px"
                  />
                  <b-form-invalid-feedback :state="valid">
                    <span v-for="(error, index) in errors" :key="index">{{
                      $t(error)
                    }}</span>
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>

              <yes-no-radio-options
                :id="'RuralRemoteNorthern'"
                :label="
                  $t('STEMClub.MaterialRequest.Questions.RuralRemoteNorthern')
                "
                :name="'RuralRemoteNorthern'"
                :required="true"
                v-model="model.Materials.RuralRemoteNorthern"
              />

              <validation-provider
                v-slot="{ errors, valid }"
                name="ParticipationRating"
                :rules="{ required: true }"
              >
                <b-form-group
                  id="ParticipationRating-group"
                  :label="
                    $t('STEMClub.MaterialRequest.Questions.ParticipationRating')
                  "
                  label-for="ParticipationRating"
                  class="required-field"
                  style="font-weight: bold"
                >
                  <b-form-select
                    id="ParticipationRating"
                    class="binput"
                    v-model="model.Materials.ParticipationRating"
                    :options="participationRatings"
                    :state="errors[0] ? false : valid ? true : null"
                  />
                </b-form-group>
                <b-form-invalid-feedback :state="valid">
                  <span v-for="(error, index) in errors" :key="index">{{
                    $t(error)
                  }}</span>
                </b-form-invalid-feedback>
              </validation-provider>

              <YesNoRadioOptions
                id="SocioEconomicYouth"
                :label="
                  $t('STEMClub.MaterialRequest.Questions.SocioEconomicYouth')
                "
                name="SocioEconomicYouth"
                :required="true"
                v-model="model.Materials.SocioEconomicYouth"
              />

              <validation-provider
                v-slot="{ errors, valid }"
                name="kitsRequesting"
                :rules="{
                  required: true,
                  min_value: 1,
                  numeric: true,
                }"
              >
                <b-form-group
                  id="NumberOfKits-group"
                  :label="$t('STEMClub.MaterialRequest.Questions.NumberOfKits')"
                  label-for="NumberOfKits"
                  class="required-field"
                  style="font-weight: bold"
                >
                  <b-form-input
                    type="number"
                    id="NumberOfKits"
                    v-model="model.Materials.NumberOfKits"
                    :state="errors[0] ? false : valid ? true : null"
                  />
                </b-form-group>
                <b-form-invalid-feedback :state="valid">
                  <span v-for="(error, index) in errors" :key="index">{{
                    $t(error)
                  }}</span>
                </b-form-invalid-feedback>
                <p class="text-muted" style="font-style: italic">
                  {{ $t("STEMClub.MaterialRequest.Questions.KitAvailability") }}
                </p>
              </validation-provider>

              <!-- Shipping Details -->
              <canada-post-shipping-address v-model="model.ShippingAddress" />
            </div>
          </div>

          <hr />
          <terms-and-conditions v-model="AcceptsTerms" />

          <b-button
            id="btn_submit"
            type="submit"
            class="mt-2"
            variant="primary"
            :disabled="!AcceptsTerms"
            >{{ $t("Submit") }}</b-button
          >
        </b-form>
      </validation-observer>
    </div>
    <div v-else>
      <b-container id="confirmationheader">
        <h3>{{ $t("STEMClub.Confirmation.Header") }}</h3>
      </b-container>
      <hr />
      <p id="confirmationsub">
        <strong>
          {{ $t("STEMClub.Confirmation.SubHeader") }}
        </strong>
      </p>
      <p id="confirmationbody" v-html="$t('STEMClub.Confirmation.Body')" />
    </div>
  </b-container>
</template>

<script lang="ts">
import { Component } from "vue-property-decorator";
import { extend, ValidationProvider, ValidationObserver } from "vee-validate";
import { min, numeric, min_value } from "vee-validate/dist/rules";
import YourSchool from "@/components/questions/YourSchool.vue";
import FormBase from "@/types/FormBase";
import Language from "@/components/questions/Language.vue";
import Cohort from "@/components/questions/Cohort.vue";
import ClassCollection from "@/components/ClassCollection.vue";
import NumberOfAdults from "@/components/questions/NumberOfAdults.vue";
import LearnedAboutProject from "@/components/questions/LearnedAboutProject.vue";
import MailingList from "@/components/questions/MailingList.vue";
import TermsAndConditions from "@/components/questions/TermsAndConditions.vue";
import YesNoRadioOptions from "@/components/questions/YesNoRadioOptions.vue";
import CanadaPostShippingAddress from "@/components/CanadaPostShippingAddress.vue";
import Role from "@/components/questions/Role.vue";
import i18n from "@/i18n";

extend("min", {
  ...min,
  message: "Validation.MinLength",
});

extend("min_value", {
  ...min_value,
  message: "Validation.ProvideNumericGreaterThanZero",
});

extend("numeric", {
  ...numeric,
  message: "Validation.Numeric",
});

/*
{
  "cohorts": [
    {
      "id": 1,
      "end_date": {
        "en": "November 1st, 2023",
        "fr": "1er novembre 2023"
      },
      "webinars": [
        {
          "id": "86790130721",
          "display": {
            "en": "Tuesdays @ 11:30 a.m. ET",
            "fr": "Les mardis à 11 h 30 HNE"
          },
          "language": "English"
        },
      ],
      "start_date": {
        "en": "September 26th, 2023",
        "fr": "26 septembre 2023"
      },
      "materials_available": false
    },
  ]
}
*/

@Component({
  components: {
    ValidationProvider,
    Language,
    Cohort,
    ValidationObserver,
    YourSchool,
    ClassCollection,
    NumberOfAdults,
    LearnedAboutProject,
    MailingList,
    TermsAndConditions,
    YesNoRadioOptions,
    CanadaPostShippingAddress,
    Role,
  },
})
export default class StemClub extends FormBase {
  model: any = {
    Materials: {},
    ShippingAddress: {},
    WebinarId: null,
  };

  dateTime = "";
  AcceptsTerms = false;

  get Form(): string {
    return "STEMClub";
  }

  get Instance(): string | null {
    return this.$route.params.instance;
  }

  mounted() {
    this.GetFormData();
  }

  get participationRatings(): Array<any> {
    return [
      {
        text: this.$root.$t("STEMClub.Questions.Ratings.Unable"),
        value: "Unable",
      },
      {
        text: this.$root.$t("STEMClub.Questions.Ratings.VeryUnlikely"),
        value: "VeryUnlikely",
      },
      {
        text: this.$root.$t("STEMClub.Questions.Ratings.SomewhatUnlikely"),
        value: "SomewhatUnlikely",
      },
      {
        text: this.$root.$t("STEMClub.Questions.Ratings.SomewhatLikely"),
        value: "SomewhatLikely",
      },
      {
        text: this.$root.$t("STEMClub.Questions.Ratings.Likely"),
        value: "Likely",
      },
    ];
  }

  get materialsAvailable(): boolean {
    if (this.Metadata.cohorts) {
      const c = this.Metadata.cohorts.find(
        (c: any) => c.id === this.model.CohortId
      );
      if (c) {
        return c.materials_available ?? false;
      }
    }

    return false;
  }

  get cohortOptions(): Array<object> {
    if (this.Metadata.cohorts) {
      return this.Metadata.cohorts.map((c: any) => {
        return {
          text: `${this.$t("STEMClub.Labels.Cohort")} ${c.id}`,
          value: c.id,
        };
      });
    } else {
      return [];
    }
  }

  get webinarOptions(): Array<object> {
    if (this.Metadata.cohorts) {
      const c: any = this.Metadata.cohorts.find(
        (c: any) => c.id === this.model.CohortId
      );
      if (c && c.webinars) {
        return c.webinars
          .filter((w: any) => w.language === this.model.Language)
          .map((w: any) => {
            return {
              text: w.display[i18n.locale],
              value: w.id,
            };
          });
      }
    }

    return [];
  }

  get cohortWebinars(): Array<any> {
    if (this.Metadata.cohorts) {
      return this.Metadata.cohorts
        .map((c: any) => {
          return c.webinars.map((w: any) => {
            return {
              cohort_id: c.id,
              start_date: c.start_date[i18n.locale],
              end_date: c.end_date[i18n.locale],
              materials_available: c.materials_available,
              webinar_id: w.id,
              language: w.language,
              webinar_display: w.display[i18n.locale],
            };
          });
        })
        .flat();
    }
    return [];
  }

  onRequestsMaterialsChanged(newValue: boolean) {
    if (!newValue) {
      this.model.Materials = {};
      this.model.ShippingAddress = {};
      this.model.Materials.RequestsMaterials = newValue;
    }
  }

  onSubmit() {
    const classCollectionValid = (
      this.$refs.classCollection as ClassCollection
    ).validate();

    if (classCollectionValid === true) {
      this.SaveSubmission(this.model);
    }
  }
}
</script>

<style scoped>
hr {
  background-color: rgb(145, 145, 145);
}

.question {
  padding-top: 20px;
  font-weight: bold;
}

table,
th,
td {
  border: 1px solid black;
  padding: 10px;
  margin-top: 10px;
}

thead {
  background-color: #0053a1;
  color: white;
}
</style>
