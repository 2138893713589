import axios from "axios";
import UseCaseResponse from "@/types/UseCaseResponse";
import BaseApiService from "./BaseApiService";

export default class TomatosphereApiService extends BaseApiService {
  async GetSeedGroups(): Promise<UseCaseResponse<object[]>> {
    await this.defineHeader();
    return axios
      .get(`${this.url}/api/registration/tomatosphere/SeedGroup`)
      .then((resp) => resp.data as UseCaseResponse<object[]>);
  }

  async CreateSeedGroup(group: object): Promise<UseCaseResponse<string>> {
    await this.defineHeader();
    return axios
      .post(`${this.url}/api/registration/tomatosphere/SeedGroup`, group)
      .then((resp) => resp.data as UseCaseResponse<string>);
  }

  async UpdateSeedGroup(group: object): Promise<UseCaseResponse<boolean>> {
    await this.defineHeader();
    return axios
      .put(
        `${this.url}/api/registration/tomatosphere/SeedGroup/${
          (group as any).Id
        }`,
        group
      )
      .then((resp) => resp.data as UseCaseResponse<boolean>);
  }

  async DeleteSeedGroup(id: string): Promise<UseCaseResponse<boolean>> {
    await this.defineHeader();
    return axios
      .delete(`${this.url}/api/registration/tomatosphere/SeedGroup/${id}`)
      .then((resp) => resp.data as UseCaseResponse<boolean>);
  }
}
