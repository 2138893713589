/* eslint-disable */

export default class ConfigurationService {
  private static instance: ConfigurationService;

  // add additional configuration collections here
  private urls: any;
  private showVersion = true;

  /* eslint @typescript-eslint/no-empty-function: "off" */
  private constructor() {}

  public static init(config: any): void {
    ConfigurationService.instance = new ConfigurationService();
    ConfigurationService.instance.urls = config.urls;
    ConfigurationService.instance.showVersion =
      config.showVersion == 1 ? true : false;
  }

  public static getInstance(): ConfigurationService {
    if (!ConfigurationService.instance) throw "Instance not instantiated.";
    return ConfigurationService.instance;
  }

  public getUrl(key: string): any {
    return this.urls[key];
  }

  public getShowVersionFlag(): boolean {
    return this.showVersion;
  }
}
