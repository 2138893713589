import axios from "axios";
import UseCaseResponse from "@/types/UseCaseResponse";
import BaseApiService from "./BaseApiService";

export default class ZoomFormApiService extends BaseApiService {
  async GetByZoomId(zId: number) {
    await this.defineHeader();
    return axios
      .get(`${this.url}/api/registration/ZoomForm/${zId}`)
      .then((resp) => resp.data as UseCaseResponse<object>);
  }

  async GetAll() {
    await this.defineHeader();
    return axios
      .get(`${this.url}/api/registration/ZoomForm`)
      .then((resp) => resp.data as UseCaseResponse<object[]>);
  }
}
