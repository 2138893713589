<template>
  <b-container fluid="xl">
    <div class="mb-4">{{ $t("HomePageContent") }}</div>
  </b-container>
</template>

<script lang="ts">
import { Component, Vue } from "vue-property-decorator";

@Component
export default class Home extends Vue {}
</script>
