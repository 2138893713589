<template>
  <b-card
    :id="id"
    class="mt-4"
    :header="$t('CanadaPostShippingAddress.Header')"
  >
    <b-card-text>
      <b-container>
        <b-row>
          <b-col sm="6">
            <validation-provider
              v-slot="{ errors, valid }"
              name="CPRecipient"
              :rules="{ required: true }"
            >
              <b-form-group
                :id="`${id}-cp-recipient-group`"
                :label="$t('CanadaPostShippingAddress.Recipient')"
                :label-for="`${id}-cp-recipient`"
                class="required-field"
              >
                <b-input
                  :id="`${id}-cp-recipient`"
                  class="b-in"
                  type="text"
                  v-model="model.Recipient"
                />
              </b-form-group>
              <b-form-invalid-feedback :state="valid">
                <span v-for="(error, index) in errors" :key="index">{{
                  $t(error)
                }}</span>
              </b-form-invalid-feedback>
            </validation-provider>
          </b-col>
        </b-row>

        <b-row>
          <b-col sm="6">
            <b-form-group
              :id="`${id}-cp-school-group`"
              :label="$t('CanadaPostShippingAddress.SchoolOrCompanyName.Label')"
              :label-for="`${id}-cp-school`"
            >
              <b-input
                :id="`${id}-cp-school`"
                class="b-in"
                type="text"
                v-model="model.School"
              />
            </b-form-group>
            <p class="text-muted" style="font-style: italic">
              {{
                $t("CanadaPostShippingAddress.SchoolOrCompanyName.Instructions")
              }}
            </p>
          </b-col>
        </b-row>

        <b-row>
          <b-col sm="6">
            <b-checkbox
              :id="`${id}-no-address`"
              class="mt-4 b-in"
              switch
              v-model="model.CantFindAddress"
              @input="onCantFindAddressInput"
            >
              {{ $t("CanadaPostShippingAddress.CantFindAddress") }}
            </b-checkbox>
          </b-col>
        </b-row>
        <template v-if="!model.CantFindAddress">
          <b-row>
            <validation-provider
              v-slot="{ errors, valid }"
              name="CPAddressLookup"
              :rules="{ required: true }"
              class="b-in ml-3 mr-3"
            >
              <canada-post-address-lookup
                :id="`${id}-cpal`"
                v-model="model.Address"
              />

              <b-form-invalid-feedback :state="valid">
                <span v-for="(error, index) in errors" :key="index">{{
                  $t(error)
                }}</span>
              </b-form-invalid-feedback>
            </validation-provider>
          </b-row>
        </template>
        <template v-else>
          <div>
            <div style="margin-top: 20px">
              {{ $t("CanadaPostShippingAddress.AddressLine1") }}
            </div>
            <b-row>
              <b-col>
                <validation-provider
                  v-slot="{ errors, valid }"
                  name="StreetNum"
                  rules="required"
                >
                  <b-form-group
                    id="streetnum-group"
                    :aria-label="$t('CanadaPostShippingAddress.StreetNumber')"
                    label-for="streetnum-in"
                  >
                    <b-input
                      id="streetnum-in"
                      type="text"
                      class="b-in"
                      v-model="model.Address.StreetNum"
                      @input="onInput"
                    />
                    <b-form-text>{{
                      $t("CanadaPostShippingAddress.StreetNumber")
                    }}</b-form-text>
                  </b-form-group>
                  <b-form-invalid-feedback :state="valid">
                    <span v-for="(error, index) in errors" :key="index">{{
                      $t(error)
                    }}</span>
                  </b-form-invalid-feedback>
                </validation-provider>
              </b-col>
              <b-col>
                <validation-provider
                  v-slot="{ errors, valid }"
                  name="StreetName"
                  rules="required"
                >
                  <b-form-group
                    id="streetname-group"
                    :aria-label="$t('CanadaPostShippingAddress.StreetName')"
                    label-for="streetname-in"
                  >
                    <b-input
                      id="streetname-in"
                      type="text"
                      class="b-in"
                      v-model="model.Address.StreetName"
                      @input="onInput"
                    />
                    <b-form-text>{{
                      $t("CanadaPostShippingAddress.StreetName")
                    }}</b-form-text>
                  </b-form-group>
                  <b-form-invalid-feedback :state="valid">
                    <span v-for="(error, index) in errors" :key="index">{{
                      $t(error)
                    }}</span>
                  </b-form-invalid-feedback>
                </validation-provider>
              </b-col>
              <b-col>
                <validation-provider
                  v-slot="{ errors, valid }"
                  name="StreetType"
                  rules="required"
                >
                  <b-form-group
                    id="streettype-group"
                    :aria-label="$t('CanadaPostShippingAddress.StreetType')"
                    label-for="streettype-in"
                  >
                    <b-form-select
                      id="streettype-in"
                      class="b-in"
                      v-model="model.Address.StreetType"
                      :options="streetTypeOptions"
                    >
                      <template #first>
                        <b-form-select-option :value="undefined" disabled>{{
                          $t("CanadaPostShippingAddress.StreetType")
                        }}</b-form-select-option>
                      </template>
                    </b-form-select>
                    <b-form-text>{{
                      $t("CanadaPostShippingAddress.StreetType")
                    }}</b-form-text>
                  </b-form-group>
                  <b-form-invalid-feedback :state="valid">
                    <span v-for="(error, index) in errors" :key="index">{{
                      $t(error)
                    }}</span>
                  </b-form-invalid-feedback>
                </validation-provider>
              </b-col>
              <b-col>
                <b-form-group
                  id="direction-group"
                  :aria-label="$t('CanadaPostShippingAddress.Direction')"
                  label-for="direction-in"
                >
                  <b-form-select
                    id="direction-in"
                    class="b-in"
                    v-model="model.Address.StreetDirection"
                    :options="directionOptions"
                  >
                    <template #first>
                      <b-form-select-option :value="undefined" disabled>{{
                        $t("CanadaPostShippingAddress.Direction")
                      }}</b-form-select-option>
                    </template>
                  </b-form-select>
                  <b-form-text>{{
                    $t("CanadaPostShippingAddress.Direction")
                  }}</b-form-text>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col sm="6">
                <b-form-group
                  id="suitenum-group"
                  :label="$t('CanadaPostShippingAddress.SuiteNumber')"
                  label-for="suitenum-in"
                >
                  <b-input
                    id="suitenum-in"
                    class="b-in"
                    type="text"
                    v-model="model.Address.SuiteNum"
                    @input="onInput"
                  />
                </b-form-group>
              </b-col>
            </b-row>

            <b-row>
              <b-col sm="6">
                <b-form-group
                  id="addressline2-group"
                  :label="$t('CanadaPostShippingAddress.AddressLine2')"
                  label-for="addressline2-in"
                >
                  <b-input
                    id="addressline2-in"
                    class="b-in"
                    type="text"
                    v-model="model.Address.Line2"
                    @input="onInput"
                  />
                </b-form-group>
              </b-col>
            </b-row>

            <b-row>
              <b-col sm="6">
                <validation-provider
                  v-slot="{ errors, valid }"
                  name="City"
                  rules="required"
                >
                  <b-form-group
                    id="city-group"
                    :label="$t('CanadaPostShippingAddress.City')"
                    label-for="city-in"
                  >
                    <b-input
                      id="city-in"
                      class="b-in"
                      type="text"
                      v-model="model.Address.City"
                      @input="onInput"
                    />
                  </b-form-group>
                  <b-form-invalid-feedback :state="valid">
                    <span v-for="(error, index) in errors" :key="index">{{
                      $t(error)
                    }}</span>
                  </b-form-invalid-feedback>
                </validation-provider>
              </b-col>
            </b-row>

            <b-row>
              <b-col sm="3">
                <validation-provider
                  v-slot="{ errors, valid }"
                  name="Province"
                  rules="required"
                >
                  <province-select v-model="model.Address.Province" />
                  <b-form-invalid-feedback :state="valid">
                    <span v-for="(error, index) in errors" :key="index">{{
                      $t(error)
                    }}</span>
                  </b-form-invalid-feedback>
                </validation-provider>
              </b-col>
              <b-col sm="3">
                <postal-code-input v-model="model.Address.PostalCode" />
              </b-col>
            </b-row>
          </div>
        </template>

        <b-row>
          <b-col sm="6">
            <validation-provider
              v-slot="{ errors, valid }"
              name="Phone"
              rules="required|phoneNum"
            >
              <b-form-group
                :id="`${id}-phone-group`"
                :label="$t('CanadaPostShippingAddress.PhoneNumber.Label')"
                :label-for="`${id}-phone-in`"
                :description="
                  $t('CanadaPostShippingAddress.PhoneNumber.Description')
                "
                class="required-field"
              >
                <b-input
                  :id="`${id}-phone-in`"
                  class="b-in"
                  type="text"
                  v-model="model.Phone"
                  @input="onInput"
                />
              </b-form-group>
              <b-form-invalid-feedback :state="valid">
                <span v-for="(error, index) in errors" :key="index">{{
                  $t(error)
                }}</span>
              </b-form-invalid-feedback>
            </validation-provider>
          </b-col>
        </b-row>

        <b-row>
          <b-col sm="6">
            <b-form-group
              :id="`${id}-instructions-group`"
              :label="$t('CanadaPostShippingAddress.SpecialInstructions')"
              label-for="instructions-in"
              style="margin-top: 10px"
            >
              <b-textarea
                :id="`${id}-instructions-in`"
                class="b-in"
                type="text"
                v-model="model.SpecialInstructions"
                @input="onInput"
              />
            </b-form-group>
          </b-col>
        </b-row>
      </b-container>
    </b-card-text>
  </b-card>
</template>

<script lang="ts">
import { Component, Vue, Prop, Watch } from "vue-property-decorator";
import { extend, ValidationProvider } from "vee-validate";
import PostalCodeInput from "./PostalCodeInput.vue";
import CanadaPostAddressLookup from "./inputs/CanadaPostAddressLookup.vue";
import ProvinceSelect from "./ProvinceSelect.vue";

extend("phoneNum", (value) => {
  if (isNaN(value)) return "Validation.PhoneNum.NumbersOnly";
  else if (value.length !== 10) return "Validation.PhoneNum.MustBe10";
  else return true;
});

@Component({
  components: {
    ValidationProvider,
    PostalCodeInput,
    CanadaPostAddressLookup,
    ProvinceSelect,
  },
})
export default class CanadaPostShippingAddress extends Vue {
  @Prop(String) id!: string;
  @Prop(Object) value!: any;

  model: any = this.value || this.newModel();

  newModel() {
    return {
      CantFindAddress: false,
    };
  }

  @Watch("model")
  onModelChanged() {
    this.onInput();
  }

  @Watch("value")
  onValueChanged() {
    this.model = this.value || this.newModel();
  }

  onCantFindAddressInput() {
    this.model.Address = {};
  }

  onInput() {
    this.$emit("input", this.model);
  }

  directionOptions = [
    { text: "", value: "" },
    { text: "E", value: "E" },
    { text: "N", value: "N" },
    { text: "NE", value: "NE" },
    { text: "NO", value: "NO" },
    { text: "NW", value: "NW" },
    { text: "O", value: "O" },
    { text: "S", value: "S" },
    { text: "SE", value: "SE" },
    { text: "SO", value: "SO" },
    { text: "SW", value: "SW" },
    { text: "W", value: "W" },
  ];

  streetTypeOptions = [
    { text: "Abbey", value: "ABBEY" },
    { text: "Acres", value: "ACRES" },
    { text: "Allée", value: "ALLEE" },
    { text: "Alley", value: "ALLEY" },
    { text: "Autoroute", value: "AUT" },
    { text: "Avenue (E)", value: "AVE" },
    { text: "avenue (F)", value: "AV" },
    { text: "Bay", value: "BAY" },
    { text: "Beach", value: "BEACH" },
    { text: "Bend", value: "BEND" },
    { text: "Boulevard (E)", value: "BLVD" },
    { text: "Boulevard (F)", value: "BOUL" },
    { text: "Branch", value: "BR" },
    { text: "By-pass", value: "BYPASS" },
    { text: "Campus", value: "CAMPUS" },
    { text: "Cape", value: "CAPE" },
    { text: "Carré", value: "CAR" },
    { text: "Carrefour", value: "CARREF" },
    { text: "Centre (E)", value: "CTR" },
    { text: "Centre (F)", value: "C" },
    { text: "Cercle", value: "CERCLE" },
    { text: "Chase", value: "CHASE" },
    { text: "Chemin", value: "CH" },
    { text: "Circle", value: "CIR" },
    { text: "Circuit", value: "CIRCT" },
    { text: "Close", value: "CLOSE" },
    { text: "Common", value: "COMMON" },
    { text: "Concession", value: "CONC" },
    { text: "Corners", value: "CRNRS" },
    { text: "Côte", value: "COTE" },
    { text: "Cour", value: "COUR" },
    { text: "Cours", value: "COURS" },
    { text: "Court", value: "CRT" },
    { text: "Cove", value: "COVE" },
    { text: "Crescent", value: "CRES" },
    { text: "Crest", value: "CREST" },
    { text: "Croissant", value: "CROIS" },
    { text: "Crossing", value: "CROSS" },
    { text: "Cul-de-sac", value: "CDS" },
    { text: "Dale", value: "DALE" },
    { text: "Dell", value: "DELL" },
    { text: "Diversion", value: "DIVERS" },
    { text: "Downs", value: "DOWNS" },
    { text: "Drive", value: "DR" },
    { text: "End", value: "END" },
    { text: "Esplanade", value: "ESPL" },
    { text: "Estates", value: "ESTATE" },
    { text: "Expressway", value: "EXPY" },
    { text: "Extension", value: "EXTEN" },
    { text: "Field", value: "FIELD" },
    { text: "Forest", value: "FOREST" },
    { text: "Freeway", value: "FWY" },
    { text: "Front", value: "FRONT" },
    { text: "Gardens", value: "GDNS" },
    { text: "Gate", value: "GATE" },
    { text: "Glade", value: "GLADE" },
    { text: "Glen", value: "GLEN" },
    { text: "Green", value: "GREEN" },
    { text: "Grounds", value: "GRNDS" },
    { text: "Grove", value: "GROVE" },
    { text: "Harbour", value: "HARBR" },
    { text: "Heath", value: "HEATH" },
    { text: "Height", value: "HEIGHT" },
    { text: "Heights", value: "HTS" },
    { text: "Highlands", value: "HGHLDS" },
    { text: "Highway", value: "HWY" },
    { text: "Hill", value: "HILL" },
    { text: "Hollow", value: "HOLLOW" },
    { text: "Île", value: "ILE" },
    { text: "Impasse", value: "IMP" },
    { text: "Inlet", value: "INLET" },
    { text: "Island", value: "ISLAND" },
    { text: "Key", value: "KEY" },
    { text: "Knoll", value: "KNOLL" },
    { text: "Landing", value: "LANDNG" },
    { text: "Lane", value: "LANE" },
    { text: "Limits", value: "LMTS" },
    { text: "Line", value: "LINE" },
    { text: "Link", value: "LINK" },
    { text: "Lookout", value: "LKOUT" },
    { text: "Loop", value: "LOOP" },
    { text: "Mall", value: "MALL" },
    { text: "Manor", value: "MANOR" },
    { text: "Maze", value: "MAZE" },
    { text: "Meadow", value: "MEADOW" },
    { text: "Mews", value: "MEWS" },
    { text: "Montée", value: "MONTEE" },
    { text: "Mount", value: "MOUNT" },
    { text: "Mountain", value: "MTN" },
    { text: "Parade", value: "PARADE" },
    { text: "Parc", value: "PARC" },
    { text: "Park", value: "PK" },
    { text: "Parkway", value: "PKY" },
    { text: "Passage", value: "PASS" },
    { text: "Path", value: "PATH" },
    { text: "Pathway", value: "PTWAY" },
    { text: "Pines", value: "PINES" },
    { text: "Place (E)", value: "PL" },
    { text: "Place (F)", value: "PLACE" },
    { text: "Plateau", value: "PLAT" },
    { text: "Plaza", value: "PLAZA" },
    { text: "Point", value: "PT" },
    { text: "Pointe", value: "POINTE" },
    { text: "Port", value: "PORT" },
    { text: "Private", value: "PVT" },
    { text: "Promenade", value: "PROM" },
    { text: "Quai", value: "QUAI" },
    { text: "Quay", value: "QUAY" },
    { text: "Ramp", value: "RAMP" },
    { text: "Rang", value: "RANG" },
    { text: "Ridge", value: "RIDGE" },
    { text: "Rise", value: "RISE" },
    { text: "Road", value: "RD" },
    { text: "Route", value: "RTE" },
    { text: "Row", value: "ROW" },
    { text: "Rue", value: "RUE" },
    { text: "Ruelle", value: "RLE" },
    { text: "Run", value: "RUN" },
    { text: "Sentier", value: "SENT" },
    { text: "Square", value: "SQ" },
    { text: "Street", value: "ST" },
    { text: "Subdivision", value: "SUBDIV" },
    { text: "Terrace", value: "TERR" },
    { text: "Terrasse", value: "TSSE" },
    { text: "Townline", value: "TLINE" },
    { text: "Trail", value: "TRAIL" },
    { text: "Turnabout", value: "TRNABT" },
    { text: "Vale", value: "VALE" },
    { text: "View", value: "VIEW" },
    { text: "Village", value: "VILLGE" },
    { text: "Villas", value: "VILLAS" },
    { text: "Vista", value: "VISTA" },
    { text: "Voie", value: "VOIE" },
    { text: "Walk", value: "WALK" },
    { text: "Way", value: "WAY" },
    { text: "Wharf", value: "WHARF" },
    { text: "Wood", value: "WOOD" },
    { text: "Wynd", value: "WYND" },
  ];
}
</script>
<style scoped>
.b-in {
  width: 100%;
}
</style>
