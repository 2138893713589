import axios from "axios";
import UseCaseResponse from "@/types/UseCaseResponse";
import BaseApiService from "./BaseApiService";
import CreateSubmissionRequest from "@/types/CreateSubmissionRequest";

export default class SubmissionApiService extends BaseApiService {
  async CreateSubmission(
    formName: string,
    instanceName: string | null,
    formData: object
  ) {
    await this.defineHeader();
    let url = `${this.url}/api/registration/Submission/${formName}`;
    if (instanceName) {
      url += `?instanceName=${instanceName}`;
    }

    return axios
      .post(url, formData)
      .then((resp) => resp.data as UseCaseResponse<string>)
      .catch((ex) => {
        const data = ex.response?.data;
        return {
          Value: ex,
          Success: false,
          Message: data?.Message,
          Errors: data?.Errors,
        };
      });
  }

  async CreateSubmissions(
    submissions: CreateSubmissionRequest[]
  ): Promise<UseCaseResponse<string>> {
    await this.defineHeader();
    return axios
      .post(`${this.url}/api/registration/Submission`, submissions)
      .then((resp) => resp.data as UseCaseResponse<string>);
  }

  async GetSubmissions(): Promise<UseCaseResponse<object[]>> {
    await this.defineHeader();
    return axios
      .get(`${this.url}/api/registration/Submission`)
      .then((resp) => resp.data as UseCaseResponse<object[]>);
  }

  async GetSubmissionById(id: string): Promise<UseCaseResponse<object>> {
    await this.defineHeader();
    return axios
      .get(`${this.url}/api/registration/Submission/${id}`)
      .then((resp) => resp.data as UseCaseResponse<object>);
  }
}
