<template>
  <b-container fluid="xl">
    <div style="max-width: 100%; margin: 0 auto; margin-top: 40px">
      <b-img
        fluid
        src="@/assets/surveys/school_organization/LTS_Survey_Header.png"
        alt="School / Organization Survey Header"
        class="headerimg"
      />
    </div>

    <h1 class="h2">{{ $t("SchoolOrganizationSurvey.Header") }}</h1>

    <div v-if="!submissionComplete">
      <p>
        {{ $t("SchoolOrganizationSurvey.Intro") }}
      </p>
      <hr />

      <validation-observer v-slot="{ handleSubmit }">
        <b-form @submit.prevent="handleSubmit(onSubmit)">
          <your-school v-model="model.School" />
          <b-button
            id="btn_submit"
            type="submit"
            class="mt-4"
            variant="primary"
            :disabled="saving"
            >{{ $t("Submit") }}</b-button
          >
        </b-form>
      </validation-observer>
    </div>

    <!-- Confirmation -->
    <div v-else>
      <hr />
      <p id="confirmationbody">
        {{ $t("SchoolOrganizationSurvey.Confirmation") }}
      </p>
    </div>
  </b-container>
</template>

<script lang="ts">
import { Component } from "vue-property-decorator";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import OrganizationApiService from "@/services/OrganizationApiService";
import YourSchool from "@/components/questions/YourSchool.vue";
import { Vue } from "vue-property-decorator";
import SurveyApiService from "@/services/SurveyApiService";

@Component({
  components: {
    ValidationProvider,
    ValidationObserver,
    YourSchool,
  },
})
export default class SchoolOrganization extends Vue {
  model: any = {
    School: {},
  };

  apiService: SurveyApiService = new SurveyApiService();

  get SurveyName(): string {
    return "SchoolOrganization";
  }

  get SurveyVersion(): number {
    return 1;
  }

  orgApiService: OrganizationApiService = new OrganizationApiService();
  submissionComplete = false;
  saving = false;

  mounted() {
    console.log("mounted");
  }

  onSubmit() {
    this.apiService
      .CreateResponse(this.SurveyName, this.SurveyVersion, this.model)
      .then((resp) => {
        if (resp.Success) {
          this.submissionComplete = true;
        } else {
          console.log(resp);
        }
      });
  }
}
</script>
