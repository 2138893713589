<template>
  <b-form-group
    :id="id"
    :label="Label"
    :label-for="`${id}-input`"
    class="required-field"
  >
    <b-input-group class="mb-2">
      <b-input
        :id="`${id}-input`"
        type="text"
        v-model="search"
        @keydown.enter="onItemSelected"
        @keydown.down="onDown"
        @keydown.up="onUp"
        @keydown.esc="onEsc"
        @input="onSearchInput"
        :disabled="disabled"
        autocomplete="off"
      />
      <b-input-group-append>
        <b-btn
          :id="`${id}_btn_clear`"
          @click="onClearClick"
          :disabled="disabled"
          >{{ $t("SchoolLookup.Clear") }}</b-btn
        >
      </b-input-group-append>
    </b-input-group>

    <b-list-group>
      <div style="overflow-y: auto; max-height: 300px">
        <b-list-group-item
          v-for="(item, idx) in items"
          :key="idx"
          :id="`${id}_addr_item_${idx}`"
          @click="onItemSelected"
          @mouseenter="currentIdx = idx"
          :class="idx === currentIdx ? 'active' : ''"
        >
          {{ item.ItemText }}
          <template v-if="item.Next === 'Find'">
            <b-icon-arrow-right
              v-b-tooltip.hover
              :title="$t('FindUnitNumber')"
            />
          </template>
        </b-list-group-item>
      </div>
    </b-list-group>
  </b-form-group>
</template>

<script lang="ts">
import Vue from "vue";
import axios from "axios";
import { Component, Prop } from "vue-property-decorator";

class FindItem {
  Id = "";
  Text = "";
  Description = "";
  Next = "";
  LastId = "";

  get ItemText(): string {
    return `${this.Text} - ${this.Description}`;
  }

  constructor(i: string, t: string, d: string, n: string, lid: string) {
    this.Id = i;
    this.Text = t;
    this.Description = d;
    this.Next = n;
    this.LastId = lid;
  }
}

@Component
export default class CanadaPostAddressLookup extends Vue {
  @Prop(String) id: string | undefined;
  @Prop(String) label: string | undefined;
  @Prop(Object) value!: object | undefined;
  @Prop(String) placeholder: string | undefined;
  @Prop(Boolean) disabled: boolean | undefined;

  selectedItem: FindItem | null = null;
  items: FindItem[] = [];

  loading = false;
  search = "";
  typeTimer: number = 1000 * 0.25; //1000 * seconds = milliseconds
  timeout: any | null;
  apiKey = "TM73-RB34-RJ36-CD59";
  findApi =
    "https://ws1.postescanada-canadapost.ca/AddressComplete/Interactive/Find/v2.10/json3.ws";
  retrieveApi =
    "https://ws1.postescanada-canadapost.ca/AddressComplete/Interactive/Retrieve/v2.11/json3.ws";

  currentIdx = 0;

  get Label(): string {
    return this.label || this.$t("CanadaPostAddressLookupLabel").toString();
  }

  onUp() {
    if (this.currentIdx > 0) this.currentIdx--;
  }

  onDown() {
    if (this.currentIdx < this.items.length - 1) this.currentIdx++;
  }

  onEsc() {
    this.clear();
  }

  onItemSelected() {
    this.selectedItem = this.items[this.currentIdx];

    if (this.selectedItem.Next === "Find") {
      this.getUnitNumbers(this.selectedItem);
    } else {
      this.search = this.items[this.currentIdx].ItemText;
      this.items = [];
      this.onInput();
    }
  }

  onSearchInput() {
    this.currentIdx = 0;
    this.selectedItem = null;
    this.onInput();
    if (this.timeout) clearTimeout(this.timeout);

    if (this.search.length > 2) {
      this.timeout = setTimeout(this.doSearch, this.typeTimer);
    } else {
      this.items = [];
    }
  }

  getUnitNumbers(item: FindItem) {
    const params: object = {
      Key: this.apiKey,
      SearchTerm: item.Text,
      SearchFor: "Everything",
      Country: "CAN",
      LanguagePreference: this.$i18n.locale,
      MaxSuggestions: 10,
      MaxResults: 500,
      LastId: item.LastId,
    };

    this.doSearch(params);
  }

  doSearch(p: object | undefined) {
    // Items have already been requested
    if (this.loading) return;

    this.loading = true;

    let params: object = {};
    if (p) {
      params = p;
    } else {
      params = {
        Key: this.apiKey,
        SearchTerm: this.search,
        SearchFor: "Everything",
        Country: "CAN",
        LanguagePreference: this.$i18n.locale,
        MaxSuggestions: 10,
        MaxResults: 100,
      };
    }

    axios
      .get(this.findApi, {
        params: params,
      })
      .then((response) => {
        const findItems: FindItem[] = [];
        response.data.Items.forEach((i: any) => {
          if (!i.Error) {
            const lastId: string = i.Next === "Find" ? i.Id : "";
            findItems.push(
              new FindItem(i.Id, i.Text, i.Description, i.Next, lastId)
            );
          }
        });

        this.items = findItems;
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => (this.loading = false));
  }

  onInput() {
    if (this.selectedItem) {
      const params = {
        Key: this.apiKey,
        Id: this.selectedItem.Id,
      };

      axios
        .get(this.retrieveApi, {
          params: params,
        })
        .then((response) => {
          const found: any | undefined = response.data.Items.find(
            (x: any) => x.Language === "ENG"
          );

          if (found) {
            this.$emit("input", found);
          }
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          this.loading = false;
        });
    } else {
      this.$emit("input", null);
    }
  }

  clear() {
    this.selectedItem = null;
    this.search = "";
    this.items = [];
    this.onInput();
  }

  onClearClick() {
    this.clear();
    this.$emit("click:clear");
  }
}
</script>
