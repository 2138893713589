import VueRouter from "vue-router";
import ConfigurationService from "./ConfigurationService";
import axios from "axios";
import UseCaseResponse from "@/types/UseCaseResponse";

enum InteractionType {
  Input,
  ButtonClick,
  SubmissionComplete,
}

class InputInteraction {
  ElementId = "";
  Start: number;
  End: number | null = null;
  ElapsedMS: number | null = null;
  Type: InteractionType;

  constructor(id: string, t: InteractionType) {
    this.ElementId = id;
    this.Type = t;
    this.Start = window.performance.now();
  }

  Complete(): InputInteraction {
    this.End = window.performance.now();
    this.ElapsedMS = this.End - this.Start;
    return this;
  }
}

class PageInteraction {
  Id: string;
  Url: string;

  constructor(url: string) {
    this.Id = "";
    this.Url = url;
  }
}

export default class AnalyticService {
  private page_interaction: PageInteraction | null = null;
  private input_interaction: InputInteraction | null = null;

  private router: VueRouter;

  private observer: MutationObserver | null = null;
  private observer_options: object = {
    subtree: true,
    childList: true,
  };

  url: string = ConfigurationService.getInstance().getUrl("ltsol_apis");

  private enabled = false;
  get Enabled(): boolean {
    return this.enabled;
  }

  set Enabled(e: boolean) {
    this.enabled = e;
  }

  constructor(router: VueRouter) {
    this.router = router;

    //catches initial page load
    window.addEventListener("DOMContentLoaded", () => this.routeChanged());

    //when a form submission completes
    window.addEventListener("ltscr_form_submit", this.formSubmit);

    this.router.afterEach(() => {
      this.routeChanged();
    });
  }

  private domMutated() {
    this.connectInputs();
  }

  private routeChanged() {
    if (!this.enabled) return;

    //catches render changes if components are added or removed (such as v-if)
    this.observer = new MutationObserver(() => {
      this.domMutated();
    });

    this.observer.observe(document.documentElement, this.observer_options);

    this.page_interaction = new PageInteraction(
      this.router.currentRoute.fullPath
    );

    this.createPageInteraction(this.page_interaction.Url).then((resp) => {
      if (resp.Success && this.page_interaction) {
        this.page_interaction.Id = resp.Value;
      } else {
        this.page_interaction = null;
      }
    });

    this.connectInputs();
  }

  private connectInputs() {
    const ins: NodeList = document.querySelectorAll("input,select,textarea");
    ins.forEach((n) => {
      n.removeEventListener("focus", this.focus);
      n.removeEventListener("blur", this.blur);

      n.addEventListener("focus", this.focus);
      n.addEventListener("blur", this.blur);
    });

    const btns: NodeList = document.querySelectorAll("button");
    btns.forEach((b) => {
      b.removeEventListener("click", this.btn_clicked);
      b.addEventListener("click", this.btn_clicked);
    });
  }

  private focus = (e: any) => {
    if (this.page_interaction) {
      this.input_interaction = new InputInteraction(
        e.target.id,
        InteractionType.Input
      );
    }
  };

  private blur = () => {
    if (this.page_interaction && this.input_interaction) {
      this.completeInputInteraction(this.input_interaction);
    }
  };

  private btn_clicked = (e: any) => {
    if (this.page_interaction) {
      this.completeInputInteraction(
        new InputInteraction(e.target.id, InteractionType.ButtonClick)
      );
    }
  };

  private formSubmit = () => {
    if (this.page_interaction) {
      this.completeInputInteraction(
        new InputInteraction(
          this.page_interaction.Url,
          InteractionType.SubmissionComplete
        )
      );
    }
  };

  private async createPageInteraction(
    url: string
  ): Promise<UseCaseResponse<string>> {
    return axios
      .post(`${this.url}/api/Analytics/PageInteraction?url=${url}`)
      .then((resp) => resp.data as UseCaseResponse<string>)
      .catch((ex) => {
        this.onError();
        return ex.response.data as UseCaseResponse<string>;
      });
  }

  private completeInputInteraction(ii: InputInteraction) {
    ii.Complete();
    if (this.Enabled && this.page_interaction && this.page_interaction.Id) {
      axios
        .post(
          `${this.url}/api/Analytics/PageInteraction/${this.page_interaction?.Id}/InputInteractions`,
          ii
        )
        .catch(() => {
          this.onError();
        });
    }
  }

  private onError() {
    console.log(
      "An exception occurred saving analytics.  Disabling analytics service."
    );
    this.Enabled = false;
  }
}
