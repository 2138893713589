<template>
  <div>
    <h2 class="h5">{{ Label }}</h2>
    <div class="pad">
      <vue-signature-pad ref="sig_pad" :options="{ onEnd }" />
    </div>
    <b-button class="mt-2" size="sm" variant="outline-primary" @click="clear">{{
      $t("Signature.Clear")
    }}</b-button>
  </div>
</template>

<script lang="ts">
import { Component, Vue, Prop, Watch } from "vue-property-decorator";
/*
https://www.npmjs.com/package/vue-signature-pad?activeTab=readme
https://github.com/szimek/signature_pad

// Returns signature image as data URL (see https://mdn.io/todataurl for the list of possible parameters)
signaturePad.toDataURL(); // save image as PNG
signaturePad.toDataURL("image/jpeg"); // save image as JPEG
signaturePad.toDataURL("image/jpeg", 0.5); // save image as JPEG with 0.5 image quality
signaturePad.toDataURL("image/svg+xml"); // save image as SVG data url
*/
@Component
export default class Signature extends Vue {
  @Prop(String) value!: string;
  @Prop(String) id!: string;
  @Prop(String) label!: string;

  data = this.value || "";

  get Pad(): any {
    return this.$refs.sig_pad as any;
  }

  get Label(): string {
    return this.label
      ? this.label
      : this.$root.$t("Signature.Label").toString();
  }

  @Watch("value")
  onValueChanged() {
    this.data = this.value;
    this.setImg();
  }

  mounted() {
    this.setImg();
  }

  setImg() {
    if (this.data) {
      this.Pad.fromDataURL(this.data);
    }
  }

  clear() {
    this.Pad.clearSignature();
    this.onInput();
  }

  onEnd() {
    this.onInput();
  }

  onInput() {
    const { data: data_url } = this.Pad.saveSignature();
    this.data = data_url;
    this.$emit("input", this.data);
  }
}
</script>
<style scoped>
.pad {
  border: 1px solid black;
  border-radius: 5px;
}
</style>
