<template>
  <b-form>
    <validation-provider
      v-slot="{ errors, valid }"
      name="NewSchoolName"
      rules="required"
    >
      <b-form-group
        id="newschool-name-group"
        :label="$t('SchoolInfo.Name')"
        label-for="newschool-name"
        class="required-field"
      >
        <b-input
          id="newschool-name"
          type="text"
          v-model="schoolModel.Name"
          @input="onInput"
        />
      </b-form-group>
      <b-form-invalid-feedback :state="valid">
        <span v-for="(error, index) in errors" :key="index">{{
          $t(error)
        }}</span>
      </b-form-invalid-feedback>
    </validation-provider>

    <validation-provider
      v-slot="{ errors, valid }"
      name="NewSchoolAddress"
      rules="required"
    >
      <b-form-group
        id="newschool-address-group"
        :label="$t('SchoolInfo.Address')"
        label-for="newschool-address"
        class="required-field"
      >
        <b-input
          id="newschool-address"
          type="text"
          v-model="schoolModel.Address"
          @input="onInput"
        />
      </b-form-group>
      <b-form-invalid-feedback :state="valid">
        <span v-for="(error, index) in errors" :key="index">{{
          $t(error)
        }}</span>
      </b-form-invalid-feedback>
    </validation-provider>

    <postal-code-input
      :label="$t('SchoolInfo.PostalCode')"
      v-model="schoolModel.PostalCode"
    />
  </b-form>
</template>

<script lang="ts">
import { Component, Vue, Prop } from "vue-property-decorator";
import { ValidationProvider } from "vee-validate";
import PostalCodeInput from "../PostalCodeInput.vue";

@Component({
  components: {
    ValidationProvider,
    PostalCodeInput,
  },
})
export default class SchoolInfo extends Vue {
  @Prop(String) id!: string;
  @Prop(String) label!: string;
  @Prop(Object) value!: any;

  schoolModel: any = this.value || {};

  onInput() {
    this.$emit("input", this.schoolModel);
  }
}
</script>
