<template>
  <b-container fluid="xl">
    <div
      style="width: 500px; max-width: 100%; margin: 0 auto; margin-top: 40px"
    >
      <b-img
        fluid
        v-if="Locale === 'fr'"
        src="@/assets/clothing-4-climate/c4c-header-fr.png"
        alt="Le Clothing for Climate"
      />
      <b-img
        fluid
        v-else
        src="@/assets/clothing-4-climate/c4c-header-en.png"
        alt="Clothing for Climate"
      />
    </div>

    <div v-if="!submissionComplete">
      <h1 class="h2">{{ $t("Clothing4Climate.Header") }}</h1>

      <p
        v-html="
          $t('Clothing4Climate.Intro.Text', {
            link: $t('Clothing4Climate.Intro.Link'),
          })
        "
      />
      <hr />

      <validation-observer v-slot="{ handleSubmit }">
        <b-form @submit.prevent="handleSubmit(onSubmit)">
          <your-school v-model="model.School" />

          <h2 class="h5">
            {{ $t("ClassesOrGroups.Label") }}
          </h2>
          <validation-provider
            v-slot="{ errors, valid }"
            name="classcollection"
            :rules="{ required: true, min: 1 }"
          >
            <class-collection
              ref="classCollection"
              id="classcollection"
              v-model="model.Classes"
              description-values
            />
            <b-form-invalid-feedback :state="valid">
              <span v-for="(error, index) in errors" :key="index">{{
                $t(error)
              }}</span>
            </b-form-invalid-feedback>
          </validation-provider>

          <h2 class="h5">
            {{ $t("AboutYou") }}
          </h2>

          <number-of-adults v-model="model.NumAdults" />

          <project-context v-model="model.Context" />

          <language
            :label="$t('Clothing4Climate.Questions.Language')"
            v-model="model.Language"
          />

          <learned-about-project v-model="model.LearnedAbout" />

          <template v-if="CrestRequest">
            <yes-no-radio-options
              id="crest-request"
              v-model="model.CrestRequest"
              :required="true"
              name="CrestRequest"
              :label="$t('Clothing4Climate.CrestRequest')"
            />

            <template v-if="model.CrestRequest === true">
              <language
                :label="$t('Clothing4Climate.CrestLanguage')"
                v-model="model.CrestLanguage"
              />
              <canada-post-shipping-address v-model="model.ShippingAddress" />
            </template>
          </template>

          <feedback v-model="model.Feedback" />

          <mailing-list v-model="model.MailingList" />

          <b-button
            id="btn_submit"
            type="submit"
            class="mt-2"
            variant="primary"
            :disabled="saving"
            >{{ $t("Submit") }}</b-button
          >
        </b-form>
      </validation-observer>
    </div>

    <!-- Confirmation -->
    <div v-else>
      <b-container id="confirmationheader">
        <h3>{{ $t("Clothing4Climate.Confirmation.Header") }}</h3>
      </b-container>
      <hr />
      <p
        id="confirmationbody"
        v-html="$t('Clothing4Climate.Confirmation.Body')"
      />
      <b-button
        variant="primary"
        :href="$t('Clothing4Climate.Confirmation.EducatorGuideButtonLink')"
        size="lg"
      >
        {{ $t("Clothing4Climate.Confirmation.EducatorGuideButtonText") }}
      </b-button>
      <b-button
        class="ml-4"
        variant="primary"
        :href="$t('Clothing4Climate.Confirmation.YouthHomepageButtonLink')"
        size="lg"
      >
        {{ $t("Clothing4Climate.Confirmation.YouthHomepageButtonText") }}
      </b-button>
    </div>
  </b-container>
</template>

<script lang="ts">
import { Component } from "vue-property-decorator";
import FormBase from "@/types/FormBase";
import { extend, ValidationProvider, ValidationObserver } from "vee-validate";
import { min, numeric, min_value } from "vee-validate/dist/rules";
import SchoolLookup from "@/components/school/SchoolLookup.vue";
import SchoolInfo from "@/components/school/SchoolInfo.vue";
import ClassCollection from "@/components/ClassCollection.vue";
import ProjectContext from "@/components/questions/ProjectContext.vue";
import LearnedAboutProject from "@/components/questions/LearnedAboutProject.vue";
import Feedback from "@/components/questions/Feedback.vue";
import MailingList from "@/components/questions/MailingList.vue";
import Language from "@/components/questions/Language.vue";
import NumberOfAdults from "@/components/questions/NumberOfAdults.vue";
import YourSchool from "@/components/questions/YourSchool.vue";
import YesNoRadioOptions from "@/components/questions/YesNoRadioOptions.vue";
import CanadaPostShippingAddress from "@/components/CanadaPostShippingAddress.vue";

extend("min", {
  ...min,
  message: "Validation.MinLength",
});

extend("min_value", {
  ...min_value,
  message: "Validation.ProvideNumericGreaterThanZero",
});

extend("numeric", {
  ...numeric,
  message: "Validation.Numeric",
});

@Component({
  components: {
    ValidationProvider,
    ValidationObserver,
    SchoolLookup,
    SchoolInfo,
    ClassCollection,
    ProjectContext,
    LearnedAboutProject,
    Language,
    MailingList,
    Feedback,
    NumberOfAdults,
    YourSchool,
    YesNoRadioOptions,
    CanadaPostShippingAddress,
  },
})
export default class Clothing4Climate extends FormBase {
  name = "";
  model: any = {};

  get Form(): string {
    return "Clothing4Climate";
  }

  get Instance(): string | null {
    return this.$route.params.instance;
  }

  get Locale(): string {
    return this.$i18n.locale;
  }

  get CrestRequest(): boolean {
    return this.Metadata.crest_request ?? false;
  }

  mounted() {
    this.GetFormData().then((resp: any) => {
      this.submissionComplete = resp.Submissions > 0;
    });
  }

  onSubmit() {
    const classCollectionValid = (
      this.$refs.classCollection as ClassCollection
    ).validate();

    if (classCollectionValid === true) {
      this.SaveSubmission(this.model);
    }
  }
}
</script>
<style scoped>
.headerimg {
  max-width: 500px;
}

.question {
  padding-top: 20px;
}

#form {
  padding: 10px;
}

#submitbtn {
  margin-top: 25px;
}

.binput {
  max-width: 250px;
}
textarea.binput {
  max-width: 100%;
}

.step-progress {
  display: inline;
}

hr {
  background-color: rgb(145, 145, 145);
}

.phelp {
  font-style: italic;
}

#noschool-details:invalid {
  border: 1px solid red;
}

#noschool-details:valid {
  border: 1px solid green;
}

#confirmationheader {
  margin-top: 30px;
  text-align: center;
}

#confirmationsub {
  margin-top: 30px;
}

#confirmationbody {
  margin-top: 30px;
}

#confirmationbtncontainer {
  margin-top: 30px;
}

#headercontainer {
  text-align: center;
}

.question {
  font-weight: bold;
}
</style>
<style>
@media (max-width: 1199px) {
  .two-col-checkboxes .custom-control-inline,
  .three-col-checkboxes .custom-control-inline {
    width: 100%;
  }
}

@media (min-width: 1200px) {
  .two-col-checkboxes .custom-control-inline {
    margin-right: 1%;
    width: 49%;
  }

  .three-col-checkboxes .custom-control-inline {
    margin-right: 1%;
    width: 32%;
  }
}
</style>
@/types/FormBase
