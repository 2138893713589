<template>
  <b-form-group
    :id="id"
    :label="Label"
    label-for="province"
    :description="hint"
  >
    <b-form-select
      :id="`${id}-province`"
      class="binput"
      :options="provinceOptions"
      v-model="regionId"
      @input="onInput"
    >
      <template #first>
        <slot name="first-item" />
      </template>
    </b-form-select>
  </b-form-group>
</template>

<script lang="ts">
import OrganizationApiService from "@/services/OrganizationApiService";
import GetOrgEnumResponse from "@/types/GetOrgEnumResponse";
import { Component, Vue, Prop, Watch } from "vue-property-decorator";

@Component
export default class OrgRegionSelect extends Vue {
  @Prop(String) id!: string;
  @Prop(String) value!: string;
  @Prop(String) label: string | undefined;
  @Prop(String) hint: string | undefined;

  regionId: string = this.value || "";

  orgProvinces: GetOrgEnumResponse[] = [];
  orgApiService: OrganizationApiService = new OrganizationApiService();

  provinces: string[] = [
    "Yukon Territory",
    "Quebec",
    "Alberta",
    "Nunavut",
    "Manitoba",
    "Saskatchewan",
    "British Columbia",
    "Nova Scotia",
    "Northwest Territories",
    "Ontario",
    "New Brunswick",
    "Newfoundland and Labrador",
    "Prince Edward Island",
    "Colombie-Britannique",
    "Nouveau-Brunswick",
    "Terre-Neuve-et-Labrador",
    "Nouvelle-Écosse",
    "Territoires du Nord-Ouest",
    "Île-du-Prince-Édouard",
    "Québec",
    "Territoire du Yukon",
  ];

  get Locale(): string {
    return this.$i18n.locale;
  }

  get Label(): string {
    return this.label || this.$t("ProvinceTerritory").toString();
  }

  get provinceOptions(): object[] {
    return this.orgProvinces.map((x) => {
      return {
        text: x.Text,
        value: x.Id,
      };
    });
  }

  @Watch("Locale")
  onLocaleChanged() {
    this.getOrgProvinces();
  }

  @Watch("value")
  onValueChanged() {
    this.regionId = this.value || "";
  }

  mounted() {
    this.regionId = this.value || "";
    this.getOrgProvinces();
  }

  getOrgProvinces() {
    this.orgApiService
      .GetEnumerationList("Region", this.Locale === "fr" ? "fr-CA" : "en-CA")
      .then((resp) => {
        this.orgProvinces = resp.Value.filter((x) =>
          this.provinces.includes(x.Text)
        );
      });
  }

  onInput() {
    this.$emit("input", this.regionId);
  }
}
</script>
