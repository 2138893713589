<template>
  <b-container fluid="xl">
    <div>{{ $t("FormClosedContent") }}</div>
  </b-container>
</template>

<script lang="ts">
import { Component, Vue } from "vue-property-decorator";

@Component
export default class FormClosed extends Vue {}
</script>
