/* eslint-disable */
import Oidc from 'oidc-client';
//import 'babel-polyfill';
import configurationService from "@/services/ConfigurationService";

var locale = 'en-CA';

if (window.location.hostname.lastIndexOf("parlonssciences.ca") > 0) locale = 'fr-CA';

export default class SecurityService {
  mgr: Oidc.UserManager;
  private static instance: SecurityService;

  public static getInstance(): SecurityService {
    if(!SecurityService.instance) {
      SecurityService.instance = new SecurityService();
    }

    return SecurityService.instance;
  }


  private constructor() {
    let that = this;
    let config = configurationService.getInstance();

    this.mgr = new Oidc.UserManager({
      userStore: new Oidc.WebStorageStateStore({ store: window.sessionStorage }),
      authority: config.getUrl('ltsol_id'),
      client_id: 'ltsol_registration',
      redirect_uri: window.location.origin + '/static/callback.html',
      response_type: 'code',
      scope: "openid LTSOL_Apis profile role",
      post_logout_redirect_uri: window.location.origin + '/index.html',
      silent_redirect_uri: window.location.origin + '/static/silent-renew.html',
      accessTokenExpiringNotificationTime: 10,
      filterProtocolClaims: true,
      loadUserInfo: true,      
      ui_locales: locale
    });

    Oidc.Log.logger = console;
    Oidc.Log.level = Oidc.Log.INFO;

    this.mgr.events.addUserLoaded(function (user) {
      console.log('New User Loaded：', arguments);
      console.log('Access_token: ', user.access_token);
    });

    this.mgr.events.addAccessTokenExpiring(function () {
      console.log('AccessToken Expiring：', arguments);
    });

    this.mgr.events.addAccessTokenExpired(function () {
      that.mgr.signoutRedirect().then(function (resp) {
        console.log('Signed out', resp);
      }).catch(function (err) {
         console.log(err);
      })
    });

    this.mgr.events.addSilentRenewError(function () {
      console.error('Silent Renew Error：', arguments);
    });

    this.mgr.events.addUserSignedOut(function () {
      console.log('UserSignedOut：', arguments);
      that.mgr.signoutRedirect().then(function (resp) {
        console.log('Signed out', resp);
      }).catch(function (err) {
        console.log(err);
      })
    });

    this.mgr.startSilentRenew();
  }

  // Renew the token manually
  renewToken() {
    let self = this;
    return new Promise((resolve, reject) => {
      this.mgr.signinSilent().then(function (user) {
        if (user == null) {
          self.signIn();
        } else {
          return resolve(user);
        }
      }).catch(function (err) {
        console.log(err);
        return reject(err);
      });
    })
  }

  // Get the user who is logged in
  getUser() {
    let self = this;
    return new Promise((resolve, reject) => {
      this.mgr.getUser().then(function (user) {
        if (user == null) {
          self.signIn();
          return resolve(null);
        } else {          
          return resolve(user);
        }
      }).catch(function (err) {
        console.log(err);
        return reject(err);
      });
    })
  }

  // Check if there is any user logged in
  getSignedIn(): Promise<boolean> {
    let self = this;
    return new Promise((resolve, reject) => {
      this.mgr.getUser().then(function (user) {
        if (user == null) {
          self.signIn();
          return resolve(false);
        } else {
          return resolve(true);
        }
      }).catch(function (err) {
        console.log(err);
        return reject(err);
      });
    })
  }

  // Redirect of the current window to the authorization endpoint.
  signIn() {
    this.mgr.signinRedirect({ state: window.location.href }).catch(function (err) {
      console.log(err);
    })
  }

  // Redirect of the current window to the end session endpoint
  signOut() {
    this.mgr.signoutRedirect().then(function () {
      console.log('signed out');
    }).catch(function (err) {
      console.log(err);
    })
  }

  // Get the profile of the user logged in
  getProfile() {
    let self = this;
    return new Promise((resolve, reject) => {
      this.mgr.getUser().then(function (user) {
        if (user == null) {
          self.signIn();
          return resolve(null);
        } else {
          return resolve(user.profile);
        }
      }).catch(function (err) {
        console.log(err);
        return reject(err);
      });
    })
  }

  // Get the token id
  getIdToken() {
    let self = this;
    return new Promise((resolve, reject) => {
      this.mgr.getUser().then(function (user) {
        if (user == null) {
          self.signIn();
          return resolve(null);
        } else {
          console.log("getIdToken");
          console.log(user);
          return resolve(user.id_token);
        }
      }).catch(function (err) {
        console.log(err);
        return reject(err);
      });
    })
  }

  // Get the session state
  getSessionState() {
    let self = this;
    return new Promise((resolve, reject) => {
      this.mgr.getUser().then(function (user) {
        if (user == null) {
          self.signIn();
          return resolve(null);
        } else {
          return resolve(user.session_state);
        }
      }).catch(function (err) {
        console.log(err);
        return reject(err);
      });
    })
  }

  // Get the access token of the logged in user
  getAccessToken() {
    let self = this;
    return new Promise((resolve, reject) => {
      this.mgr.getUser().then(function (user) {
        if (user == null) {
          self.signIn();
          return resolve(null);
        } else {
          return resolve(user.access_token);
        }
      }).catch(function (err) {
        console.log(err);
        return reject(err);
      });
    })
  }

  // Takes the scopes of the logged in user
  getScopes() {
    let self = this;
    return new Promise((resolve, reject) => {
      this.mgr.getUser().then(function (user) {
        if (user == null) {
          self.signIn();
          return resolve(null);
        } else {
          return resolve(user.scopes);
        }
      }).catch(function (err) {
        console.log(err);
        return reject(err);
      });
    })
  }

  // Get the user roles logged in
  getRole() {
    let self = this;
    return new Promise((resolve, reject) => {
      this.mgr.getUser().then(function (user) {
        if (user == null) {
          self.signIn();
          return resolve(null);
        } else {
          return resolve(user.profile.role);
        }
      }).catch(function (err) {
        console.log(err);
        return reject(err);
      });
    })
  }
}