import { render, staticRenderFns } from "./YVEDelivery.vue?vue&type=template&id=b0a49486&scoped=true"
import script from "./YVEDelivery.vue?vue&type=script&lang=ts"
export * from "./YVEDelivery.vue?vue&type=script&lang=ts"
import style0 from "./YVEDelivery.vue?vue&type=style&index=0&id=b0a49486&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "b0a49486",
  null
  
)

export default component.exports