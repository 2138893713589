export enum EventDeliveryModes {
  InPerson,
  Virtual,
}

export enum EventStatus {
  Draft,
  Open,
  Waitlisted,
  Closed,
  Completed,
  Cancelled,
}

export enum EventLanguages {
  English,
  French,
}

export default class LTSCEvent {
  Id = "";
  Deleted = false;
  ExternalId = "";
  EventDate: string | null = null;
  DisplayDateEnglish: string | null = null;
  DisplayDateFrench: string | null = null;
  Location = "";
  City = "";
  Province = "";
  Status: EventStatus = EventStatus.Draft;
  DeliveryMode: EventDeliveryModes = EventDeliveryModes.InPerson;
  Capacity = 0;
  RemainingCapacity = 0;
  MaxTeams = 0;
  MaxTeamsPerEducator = 0;
  Language: EventLanguages = EventLanguages.English;
  WaitlistRegistrationLimit = 0;
  CutoffDate = "";
  StreetAddress = "";
  PostalCode = "";
  LogisticDetails = "";
  RoomInformation = "";
  ZoomFinaleEventId = "";
  SiteId: string | null = null;
}
