<template>
  <div>
    <p class="question required-field" id="q_comp_contextQuestion">
      {{ $t("ProjectFacilitatingContextField.Question") }}
    </p>

    <validation-provider
      v-slot="{ errors, valid }"
      name="context"
      :rules="{ required: true }"
    >
      <b-form-group class="two-col-checkboxes">
        <b-form-radio-group
          id="context"
          v-model="ctx"
          :options="contextOptions"
          aria-describedby="q_comp_contextQuestion"
        />
      </b-form-group>
      <b-form-invalid-feedback :state="valid">
        <span v-for="(error, index) in errors" :key="index">{{
          $t(error)
        }}</span>
      </b-form-invalid-feedback>
    </validation-provider>
  </div>
</template>

<script lang="ts">
import { Component, Vue, Watch, Prop } from "vue-property-decorator";
import { ValidationProvider } from "vee-validate";

@Component({
  components: {
    ValidationProvider,
  },
})
export default class ProjectContext extends Vue {
  @Prop(String) value!: string;
  ctx = this.value || "";

  @Watch("value")
  onValueChanged() {
    this.ctx = this.value;
  }

  @Watch("ctx")
  onContextChanged() {
    this.$emit("input", this.ctx);
  }

  get contextOptions() {
    return [
      {
        text: this.$root.$t(
          "ProjectFacilitatingContextField.Options.CommunityGroup"
        ),
        value: "Community group",
      },
      {
        text: this.$root.$t(
          "ProjectFacilitatingContextField.Options.SchoolClub"
        ),
        value: "School club",
      },
      {
        text: this.$root.$t(
          "ProjectFacilitatingContextField.Options.WholeClass"
        ),
        value: "Whole Class",
      },
      {
        text: this.$root.$t(
          "ProjectFacilitatingContextField.Options.PartialClass"
        ),
        value: "Partial Class",
      },
      {
        text: this.$root.$t("ProjectFacilitatingContextField.Options.AtHome"),
        value: "At home",
      },
      {
        text: this.$root.$t(
          "ProjectFacilitatingContextField.Options.Homeschool"
        ),
        value: "Homeschool",
      },
      {
        text: this.$root.$t("ProjectFacilitatingContextField.Options.Other"),
        value: "Other",
      },
    ];
  }
}
</script>
<style scoped>
.question {
  padding-top: 20px;
  font-weight: bold;
}
</style>
