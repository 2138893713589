<template>
  <div>
    <h2 class="h5 required-field">
      {{ $t(`${FormName}.RoleQuestion`) }}
    </h2>

    <validation-provider
      v-slot="{ errors, valid }"
      name="Role"
      :rules="{ required: true }"
    >
      <b-form-group>
        <b-form-checkbox-group
          id="role"
          v-model="model.Roles"
          :options="roleOptions"
          stacked
        ></b-form-checkbox-group>
      </b-form-group>
      <b-form-invalid-feedback :state="valid">
        <span v-for="(error, index) in errors" :key="index">{{
          $t(error)
        }}</span>
      </b-form-invalid-feedback>
    </validation-provider>

    <template v-if="NotVolunteer">
      <template v-if="model.Roles.includes('Other')">
        <validation-provider
          v-slot="{ errors, valid }"
          name="OtherRoles"
          rules="required"
        >
          <b-form-group
            id="other-roles-group"
            label="Please Specify"
            label-for="other-roles"
          >
            <b-form-input
              id="other-roles"
              class="binput"
              type="text"
              v-model="model.OtherRoles"
            />
          </b-form-group>
          <b-form-invalid-feedback :state="valid">
            <span v-for="(error, index) in errors" :key="index">{{
              $t(error)
            }}</span>
          </b-form-invalid-feedback>
        </validation-provider>
      </template>

      <validation-provider
        v-slot="{ errors, valid }"
        name="country"
        :rules="{ required: true }"
      >
        <b-form-group
          id="country-group"
          label="Country"
          label-for="country"
          class="required-field"
        >
          <b-form-select
            id="country"
            class="binput"
            v-model="model.Country"
            :options="countryOptions"
            :state="errors[0] ? false : valid ? true : null"
          />
        </b-form-group>
        <b-form-invalid-feedback :state="valid">
          <span v-for="(error, index) in errors" :key="index">{{
            $t(error)
          }}</span>
        </b-form-invalid-feedback>
      </validation-provider>

      <template v-if="model.Country === 'Canada'">
        <hr />
        <your-school v-model="model.School" />
        <hr />

        <h2 class="h5">
          {{ $t(`${FormName}.ClassCollectionLabel`) }}
        </h2>
        <validation-provider
          v-slot="{ errors, valid }"
          name="classcollection"
          :rules="{ required: true, min: 1 }"
        >
          <grade-collection
            ref="gradeCollection"
            id="gradecollection"
            description-values
            v-model="model.Grades"
          />
          <b-form-invalid-feedback :state="valid">
            <span v-for="(error, index) in errors" :key="index">{{
              $t(error)
            }}</span>
          </b-form-invalid-feedback>
        </validation-provider>
      </template>
    </template>
  </div>
</template>

<script lang="ts">
import { Component, Vue, Prop, Watch } from "vue-property-decorator";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import GradeCollection from "./GradeCollection.vue";
import YourSchool from "@/components/questions/YourSchool.vue";

@Component({
  components: {
    GradeCollection,
    ValidationProvider,
    ValidationObserver,
    YourSchool,
  },
})
export default class InterstitialDataCollection extends Vue {
  @Prop(String) id!: string;
  @Prop(String) formName!: string;
  @Prop(Object) value!: any;

  model: any = this.value || {};

  @Watch("value")
  onValueChanged() {
    this.model = this.value || {};
  }

  @Watch("model")
  onModelChanged() {
    this.$emit("input", this.model);
  }

  get FormName(): string {
    return this.formName ?? "Canvas";
  }

  get roleOptions() {
    return [
      { text: this.$root.$t("Canvas.Roles.Volunteer"), value: "Volunteer" },
      { text: this.$root.$t("Canvas.Roles.Educator"), value: "Educator" },
      { text: this.$root.$t("Canvas.Roles.Other"), value: "Other" },
    ];
  }

  get countryOptions() {
    return [
      { text: "Canada", value: "Canada" },
      { text: "United States", value: "United States" },
      { text: this.$root.$t("Canvas.Countries.Other"), value: "Other" },
    ];
  }

  get NotVolunteer(): boolean {
    return (
      this.model &&
      this.model.Roles &&
      this.model.Roles.findIndex((x: string) => x !== "Volunteer") >= 0
    );
  }
}
</script>
