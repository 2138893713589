<template>
  <b-container fluid="xl">
    <div v-if="!submissionComplete">
      <h1 class="h2">{{ $t("STEMClubRecordingRequest.Header") }}</h1>
      <p>
        {{ $t("STEMClubRecordingRequest.Intro") }}
      </p>

      <!-- sessions table -->
      <table>
        <thead>
          <tr>
            <th>{{ $t("STEMClubRecordingRequest.Labels.SessionName") }}</th>
            <th>{{ $t("STEMClubRecordingRequest.Labels.CurriculumLink") }}</th>
            <th>
              {{
                $t("STEMClubRecordingRequest.Labels.RecordingAvailabilityDate")
              }}
            </th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(s, idx) in sessions" :key="idx">
            <td>{{ s.name }}</td>
            <td>{{ s.curriculum_link }}</td>
            <td>{{ s.available_date }}</td>
          </tr>
        </tbody>
      </table>

      <hr />
      <!-- Session -->
      <validation-provider
        v-slot="{ errors, valid }"
        name="Session"
        :rules="{ required: true }"
      >
        <b-form-group
          id="session-group"
          :label="$t('STEMClubRecordingRequest.Questions.Session')"
          label-for="session"
          class="required-field"
        >
          <b-form-select
            id="session"
            class="binput"
            v-model="model.SessionId"
            :options="sessionOptions"
            :state="errors[0] ? false : valid ? true : null"
            @input="() => (model.OccurrenceId = null)"
          />
        </b-form-group>
        <b-form-invalid-feedback :state="valid">
          <span v-for="(error, index) in errors" :key="index">{{
            $t(error)
          }}</span>
        </b-form-invalid-feedback>
      </validation-provider>

      <language
        v-model="model.Language"
        hide-label
        @input="() => (model.OccurrenceId = undefined)"
      />

      <validation-provider
        v-slot="{ errors, valid }"
        name="Occurrence"
        :rules="{ required: true }"
      >
        <b-form-group
          id="occurrence-group"
          :label="$t('STEMClubRecordingRequest.Questions.Occurrence')"
          label-for="occurrence"
          class="required-field"
        >
          <b-form-select
            id="occurrrence"
            class="binput"
            v-model="model.OccurrenceId"
            :options="occurrenceOptions"
            :state="errors[0] ? false : valid ? true : null"
          />
        </b-form-group>
        <b-form-invalid-feedback :state="valid">
          <span v-for="(error, index) in errors" :key="index">{{
            $t(error)
          }}</span>
        </b-form-invalid-feedback>
      </validation-provider>

      <validation-observer v-slot="{ handleSubmit }" v-if="model.OccurrenceId">
        <b-form @submit.prevent="handleSubmit(onSubmit)">
          <!-- School / Organization Information -->
          <div>
            <your-school v-model="model.School" />
          </div>

          <!-- Participation Information  -->
          <div>
            <h2 class="h5">
              {{ $t("STEMClubRecordingRequest.Questions.Classes") }}
            </h2>
            <validation-provider
              v-slot="{ errors, valid }"
              name="classcollection"
              :rules="{ required: true, min: 1 }"
            >
              <class-collection
                ref="classCollection"
                id="classcollection"
                v-model="model.Classes"
                description-values
              />
              <b-form-invalid-feedback :state="valid">
                <span v-for="(error, index) in errors" :key="index">{{
                  $t(error)
                }}</span>
              </b-form-invalid-feedback>
            </validation-provider>
          </div>

          <number-of-adults
            allow-zero
            v-model="model.NumAdults"
            :label="$t('STEMClubRecordingRequest.Questions.NumAdults')"
          />

          <terms-and-conditions v-model="AcceptsTerms" />

          <b-button
            id="btn_submit"
            type="submit"
            class="mt-2"
            variant="primary"
            :disabled="!AcceptsTerms"
            >{{ $t("Submit") }}</b-button
          >
        </b-form>
      </validation-observer>
    </div>
    <div v-else>
      <b-container id="confirmationheader">
        <h3>{{ $t("STEMClubRecordingRequest.Confirmation.Header") }}</h3>
      </b-container>
      <hr />
      <p id="confirmationsub">
        <strong>
          {{ $t("STEMClubRecordingRequest.Confirmation.SubHeader") }}
        </strong>
      </p>
      <p
        id="confirmationbody"
        v-html="$t('STEMClubRecordingRequest.Confirmation.Body')"
      />
    </div>
  </b-container>
</template>

<script lang="ts">
import { Component } from "vue-property-decorator";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import YourSchool from "@/components/questions/YourSchool.vue";
import FormBase from "@/types/FormBase";
import Language from "@/components/questions/Language.vue";
import Cohort from "@/components/questions/Cohort.vue";
import ClassCollection from "@/components/ClassCollection.vue";
import NumberOfAdults from "@/components/questions/NumberOfAdults.vue";
import TermsAndConditions from "@/components/questions/TermsAndConditions.vue";
import YesNoRadioOptions from "@/components/questions/YesNoRadioOptions.vue";
import i18n from "@/i18n";

/* config example
{
  "cohorts": [
    {
      "id": 1,
      "sessions": [
        {
          "id": "binary_bracelets",
          "name": {
            "en": "Binary Bracelets",
            "fr": "Le Binaree Bracelettes"
          },
          "available_date": {
            "en": "September 1st 2023",
            "fr": "Septomber le 1st 2023"
          },
          "occurrences": [
            {
              "id": "123 4567 890",
              "date": "2023-01-01",
              "language": "English"
            }
          ],
          "curriculum_link": {
            "en": "Flight/Aerodynamics",
            "fr": "Flight/Aerodynamics_FR"
          }
        }
      ],
      "instance_name": "cohort1"
    }
  ]
}
*/

@Component({
  components: {
    ValidationProvider,
    Language,
    Cohort,
    ValidationObserver,
    YourSchool,
    ClassCollection,
    NumberOfAdults,
    TermsAndConditions,
    YesNoRadioOptions,
  },
})
export default class StemClubRecordingRequest extends FormBase {
  model: any = {
    OccurrenceId: null,
  };

  AcceptsTerms = false;
  cohort_data: any = {};

  get Form(): string {
    return "STEMClubRecordingRequest";
  }

  get Instance(): string | null {
    return this.$route.params.instance;
  }

  mounted() {
    this.GetFormData().then(() => {
      if (this.Instance) {
        this.cohort_data = this.Metadata.cohorts.find(
          (c: any) => c.instance_name === this.Instance
        );
      } else {
        this.GoToFormClosed();
      }
    });
  }

  get sessionOptions(): Array<object> {
    if (this.cohort_data.sessions) {
      return this.cohort_data.sessions.map((s: any) => {
        return {
          text: s.name[i18n.locale],
          value: s.id,
        };
      });
    }

    return [];
  }

  get occurrenceOptions(): Array<object> {
    if (this.cohort_data.sessions) {
      const s: any = this.cohort_data.sessions.find(
        (s: any) => s.id === this.model.SessionId
      );
      if (s && s.occurrences) {
        return s.occurrences
          .filter(
            (o: any) =>
              o.language === this.model.Language &&
              new Date(o.date) < new Date()
          )
          .map((o: any) => {
            return {
              text: o.date,
              value: o.id,
            };
          });
      }
    }

    return [];
  }

  get sessions(): Array<any> {
    if (this.cohort_data.sessions) {
      return this.cohort_data.sessions.map((s: any) => {
        return {
          name: s.name[i18n.locale],
          curriculum_link: s.curriculum_link[i18n.locale],
          available_date: s.available_date[i18n.locale],
        };
      });
    }
    return [];
  }

  onSubmit() {
    const classCollectionValid = (
      this.$refs.classCollection as ClassCollection
    ).validate();

    if (classCollectionValid === true) {
      this.SaveSubmission(this.model);
    }
  }
}
</script>

<style scoped>
hr {
  background-color: rgb(145, 145, 145);
}

.question {
  padding-top: 20px;
  font-weight: bold;
}

table,
th,
td {
  border: 1px solid black;
  padding: 10px;
  margin-top: 10px;
}

thead {
  background-color: #0053a1;
  color: white;
}
</style>
