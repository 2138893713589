import LTSCEvent from "./LTSCEvent";
import PermissionForm from "./PermissionForm";
import Team from "./Team";

export enum RegistrationStatus {
  Attending,
  Waitlisted,
  PendingConfirmation,
  Declined,
  Cancelled,
}

export default class LTSCRegistration {
  Id = "";
  Deleted = false;
  CentralSubmissionBy = "";
  CentralRegistrationId = "";
  Status: RegistrationStatus = RegistrationStatus.Attending;
  EventId = "";
  Event: LTSCEvent | null = null;
  Teams: Team[] = [];
  PermissionForms: PermissionForm[] = [];
}
