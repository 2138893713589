import axios from "axios";
import UseCaseResponse from "@/types/UseCaseResponse";
import BaseApiService from "./BaseApiService";

export default class SurveyApiService extends BaseApiService {
  async CreateResponse(
    survey: string,
    version: number,
    response: object
  ): Promise<UseCaseResponse<string>> {
    return axios
      .post(
        `${this.url}/api/surveys/SurveyResponse/${survey}/${version}`,
        response
      )
      .then((resp) => resp.data as UseCaseResponse<string>);
  }
}
