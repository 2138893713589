import TeamMember from "./TeamMember";

export default class Team {
  Id = "00000000-0000-0000-0000-000000000000";
  Deleted = false;
  ExternalId: string | null = null;
  Name = "";
  RegistrationId = "";
  TeamMembers: TeamMember[] = [];
}
