<template>
  <div>
    <p class="h5">
      {{ Title }}
      <b-icon-question-circle
        v-b-tooltip.hover
        :title="$t('YourSchool.Tooltip')"
      />
    </p>
    <div>
      <org-region-select
        :id="`${id}-org-region`"
        v-show="!cantFindSchoolToggle && !noSchoolToggle"
        class="binput"
        v-model="provinceFilter"
      />

      <validation-provider
        v-slot="{ errors, valid }"
        name="schoolLookup"
        :rules="{ required: true, validschool: true }"
        v-if="!cantFindSchoolToggle && !noSchoolToggle"
      >
        <school-lookup
          :id="`${id}-school`"
          :regionfilter="provinceFilter"
          v-model="model"
          ref="schoolLookup"
          :disabled="cantFindSchoolToggle || noSchoolToggle"
        />
        <b-form-invalid-feedback :state="valid">
          <span v-for="(error, index) in errors" :key="index">{{
            $t(error)
          }}</span>
        </b-form-invalid-feedback>
      </validation-provider>
      <b-btn
        :id="`${id}-btn_cantfindschool`"
        :pressed.sync="cantFindSchoolToggle"
        variant="outline-primary"
        class="mb-2 mr-2 mt-2 btn-lg"
        @click="onCantFindSchoolClicked"
      >
        {{ $t("YourSchool.CantFindSchool") }}
      </b-btn>
      <b-btn
        :id="`${id}-btn_noschool`"
        :pressed.sync="noSchoolToggle"
        variant="outline-primary"
        class="mb-2 mr-2 mt-2 btn-lg"
        @click="onNoSchoolClicked"
      >
        {{ $t("YourSchool.DontHaveSchool") }}
      </b-btn>

      <div v-if="noSchoolToggle === true" class="mt-2">
        <validation-provider
          v-slot="{ errors, valid }"
          name="NoSchoolDetails"
          rules="required"
        >
          <b-form-group
            :id="`${id}-noschool-details-group`"
            :label="$t('YourSchool.NoSchoolContext')"
            :label-for="`${id}-noschool-details`"
            class="required-field"
          >
            <b-form-input
              :id="`${id}-noschool-details`"
              class="binput"
              type="text"
              v-model="model.NoSchoolDetails"
              ref="nsd"
            />
          </b-form-group>
          <b-form-invalid-feedback :state="valid">
            <span v-for="(error, index) in errors" :key="index">{{
              $t(error)
            }}</span>
          </b-form-invalid-feedback>
        </validation-provider>

        <postal-code-input
          :label="$t('YourSchool.PostalCode')"
          v-model="model.NoSchoolPostalCode"
        />
      </div>

      <div v-if="cantFindSchoolToggle === true" class="mt-2">
        <school-info v-model="model" />
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Vue, Watch, Prop } from "vue-property-decorator";
import { ValidationProvider, extend } from "vee-validate";
import SchoolLookup from "../school/SchoolLookup.vue";
import SchoolInfo from "../school/SchoolInfo.vue";
import OrgRegionSelect from "../school/OrgRegionSelect.vue";
import PostalCodeInput from "../PostalCodeInput.vue";

extend("validschool", {
  message: "Validation.Required",
  validate: (value) => value.Id !== undefined,
});

@Component({
  components: {
    ValidationProvider,
    SchoolLookup,
    SchoolInfo,
    OrgRegionSelect,
    PostalCodeInput,
  },
})
export default class YourSchool extends Vue {
  @Prop(Object) value!: any;
  @Prop(String) id!: string;
  @Prop(String) title!: string;
  model: any = this.value || {};

  cantFindSchoolToggle = false;
  noSchoolToggle = false;
  provinceFilter: string | null = null;

  get Title() {
    return this.title || this.$t("YourSchool.Title");
  }

  @Watch("value")
  onValueChanged() {
    this.model = this.value;
  }

  @Watch("model")
  onModelChanged() {
    this.$emit("input", this.model);
  }

  onCantFindSchoolClicked() {
    if (this.cantFindSchoolToggle) {
      this.noSchoolToggle = false;
    }

    this.model = {};
    this.model.NoSchoolDetails = undefined;
    this.model.NoSchoolPostalCode = undefined;
    if (this.$refs.schoolLookup) {
      (this.$refs.schoolLookup as SchoolLookup).Clear();
    }
  }

  onNoSchoolClicked() {
    if (this.noSchoolToggle) {
      this.cantFindSchoolToggle = false;
    }

    this.model = {};
    this.model.NoSchoolDetails = undefined;
    this.model.NoSchoolPostalCode = undefined;
  }
}
</script>
<style scoped>
form .btn.btn-lg,
form .btn-group-lg > .btn {
  font-size: 1.1rem;
  font-weight: 600;
}
</style>
