import { render, staticRenderFns } from "./TSAdventure.vue?vue&type=template&id=5e32f2e7&scoped=true"
import script from "./TSAdventure.vue?vue&type=script&lang=ts"
export * from "./TSAdventure.vue?vue&type=script&lang=ts"
import style0 from "./TSAdventure.vue?vue&type=style&index=0&id=5e32f2e7&prod&scoped=true&lang=css"
import style1 from "./TSAdventure.vue?vue&type=style&index=1&id=5e32f2e7&prod&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5e32f2e7",
  null
  
)

export default component.exports