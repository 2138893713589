<template>
  <b-container fluid="xl">
    <h1>{{ $t("LTSChallenge.Permission.Header") }}</h1>
    <p>
      {{ $t("LTSChallenge.Permission.Intro") }}
    </p>

    <!-- event details -->
    <div v-if="event_details">
      <div>
        <h2 class="h5">
          {{ $t("LTSChallenge.Permission.EventDetails") }}
        </h2>
      </div>
      <div>
        <ul>
          <li>
            {{
              $i18n.locale == "fr"
                ? event_details.DisplayDateFrench
                : event_details.DisplayDateEnglish
            }}
          </li>
          <li>{{ event_details.EventLocation }}</li>
        </ul>
      </div>

      <!-- Educator & Org -->
      <div>
        <h2 class="h5">
          {{ $t("LTSChallenge.Permission.EducatorAndOrganization") }}
        </h2>
      </div>
      <div>
        <ul>
          <li>
            {{ event_details.EducatorFirstName }}
            {{ event_details.EducatorLastName }}
          </li>
          <li>{{ event_details.EducatorOrgName }}</li>
        </ul>
      </div>
    </div>

    <div v-if="!submissionComplete">
      <validation-observer v-slot="{ handleSubmit }">
        <b-form @submit.prevent="handleSubmit(onSubmit)">
          <!-- Student Info -->
          <div>
            <h2 class="h5">
              {{ $t("LTSChallenge.Permission.StudentInformation") }}
            </h2>
            <validation-provider
              v-slot="{ errors, valid }"
              name="StudentFNameVP"
              :rules="{ required: true }"
            >
              <b-form-group
                id="student-fname-group"
                :label="
                  $t('LTSChallenge.Permission.Questions.StudentFirstName')
                "
                class="required-field"
                label-for="student-fname"
              >
                <b-form-input
                  id="student-fname"
                  type="text"
                  class="binput"
                  v-model="model.StudentFirstName"
                />
              </b-form-group>
              <b-form-invalid-feedback :state="valid">
                <span v-for="(error, index) in errors" :key="index">{{
                  $t(error)
                }}</span>
              </b-form-invalid-feedback>
            </validation-provider>

            <validation-provider
              v-slot="{ errors, valid }"
              name="StudentLNameVP"
              :rules="{ required: true }"
            >
              <b-form-group
                id="student-lname-group"
                :label="$t('LTSChallenge.Permission.Questions.StudentLastName')"
                class="required-field"
                label-for="student-lname"
              >
                <b-form-input
                  id="student-lname"
                  type="text"
                  class="binput"
                  v-model="model.StudentLastName"
                />
              </b-form-group>
              <b-form-invalid-feedback :state="valid">
                <span v-for="(error, index) in errors" :key="index">{{
                  $t(error)
                }}</span>
              </b-form-invalid-feedback>
            </validation-provider>

            <validation-provider
              v-slot="{ errors, valid }"
              name="grade-validator"
              :rules="{ required: true }"
            >
              <b-form-group
                id="grade-group"
                :label="$t('LTSChallenge.Permission.Questions.Grade')"
                label-for="grade"
                class="required-field"
              >
                <b-form-select
                  id="grade"
                  class="binput"
                  v-model="model.Grade"
                  :options="gradeOptions"
                  :state="errors[0] ? false : valid ? true : null"
                />
              </b-form-group>
              <b-form-invalid-feedback :state="valid">
                <span v-for="(error, index) in errors" :key="index">{{
                  $t(error)
                }}</span>
              </b-form-invalid-feedback>
            </validation-provider>
          </div>

          <!-- Medical & Allergy -->
          <div>
            <h2 class="h5">
              {{ $t("LTSChallenge.Permission.MedicalAndAllergyInfo") }}
            </h2>
            <p>
              {{ $t("LTSChallenge.Permission.MedicalAndAllergyInfo.Text") }}
            </p>
            <yes-no-radio-options
              id="medical-or-allergies"
              v-model="medical_conditions"
              :label="
                $t(
                  'LTSChallenge.Permission.Questions.MedicalConditionsOrAllergies'
                )
              "
              :required="true"
            />
            <div v-if="medical_conditions">
              <b-form-group
                id="medical-group"
                :label="
                  $t('LTSChallenge.Permission.Questions.MedicalConditions')
                "
                label-for="medical"
              >
                <b-form-textarea
                  id="medical"
                  class="binput"
                  style="min-width: 500px"
                  v-model="model.MedicalConditions"
                />
              </b-form-group>
            </div>
          </div>

          <!-- Photo Consent -->
          <div>
            <h2 class="h5">
              {{ $t("LTSChallenge.Permission.PhotoConsent") }}
            </h2>
            <p>
              {{ $t("LTSChallenge.Permission.PhotoConsent.Text") }}
            </p>
            <yes-no-radio-options
              id="photo-consent"
              v-model="model.PhotoConsent"
              :label="$t('LTSChallenge.Permission.Questions.PhotoConsent')"
              :required="true"
            />
          </div>

          <!-- Longitude Opt In -->
          <div>
            <h2 class="h5">
              {{ $t("LTSChallenge.Permission.LongitudinalOptIn") }}
            </h2>
            <p>
              {{ $t("LTSChallenge.Permission.LongitudinalOptIn.Text") }}
            </p>
            <yes-no-radio-options
              id="longitude-consent"
              v-model="model.LongitudeStudyConsent"
              :label="
                $t('LTSChallenge.Permission.Questions.LongitudeStudyConsent')
              "
              :required="true"
            />
            <div v-if="model.LongitudeStudyConsent">
              <email
                id="guardian-email"
                :label="$t('LTSChallenge.Permission.Questions.GuardianEmail')"
                simple-label
                v-model="model.GuardianEmail"
              />

              <email
                id="student-email"
                :label="$t('LTSChallenge.Permission.Questions.StudentEmail')"
                simple-label
                v-model="model.StudentEmail"
              />
              <p class="mt-4">
                {{
                  $t("LTSChallenge.Permission.LongitudinalOptIn.OptOutLater")
                }}
              </p>
            </div>
          </div>

          <!-- Assumption of Risk -->
          <div>
            <h2 class="h5">
              {{ $t("LTSChallenge.Permission.AssumptionOfRisk") }}
            </h2>
            <p>
              {{ $t("LTSChallenge.Permission.AssumptionOfRisk.Text") }}
            </p>
            <b-form-group
              id="consent-group"
              :label="$t('LTSChallenge.Permission.Consent')"
              label-for="consent"
            >
              <b-form-checkbox id="consent" v-model="model.Consent" />
            </b-form-group>
            <validation-provider
              v-slot="{ errors, valid }"
              name="GuardianNameVP"
              :rules="{ required: true }"
            >
              <b-form-group
                id="guardian-name-group"
                :label="$t('LTSChallenge.Permission.Questions.GuardianName')"
                class="required-field"
                label-for="guardian-name"
              >
                <b-form-input
                  id="guardian-name"
                  type="text"
                  class="binput"
                  v-model="model.GuardianName"
                />
              </b-form-group>
              <b-form-invalid-feedback :state="valid">
                <span v-for="(error, index) in errors" :key="index">{{
                  $t(error)
                }}</span>
              </b-form-invalid-feedback>
            </validation-provider>
            <validation-provider
              v-slot="{ errors, valid }"
              name="SignatureVP"
              :rules="{ required: true }"
            >
              <signature
                id="signature"
                :label="
                  $t('LTSChallenge.Permission.Questions.GuardianSignature')
                "
                v-model="model.Signature"
              />
              <b-form-invalid-feedback :state="valid">
                <span v-for="(error, index) in errors" :key="index">{{
                  $t(error)
                }}</span>
              </b-form-invalid-feedback>
            </validation-provider>
          </div>

          <b-button
            id="btn_submit"
            type="submit"
            class="mt-4"
            variant="primary"
            :disabled="!model.Consent || saving"
            >{{ $t("Submit") }}</b-button
          >
        </b-form>
      </validation-observer>
    </div>
    <div v-else>
      <b-container id="confirmationheader">
        <h3>{{ $t("LTSChallenge.Permission.Confirmation.Header") }}</h3>
      </b-container>
      <hr />
      <p id="confirmationsub">
        <strong>
          {{ $t("LTSChallenge.Permission.Confirmation.SubHeader") }}
        </strong>
      </p>
      <p
        id="confirmationbody"
        v-html="$t('LTSChallenge.Permission.Confirmation.Body')"
      />
    </div>
  </b-container>
</template>

<script lang="ts">
import { Component } from "vue-property-decorator";
import { extend, ValidationProvider, ValidationObserver } from "vee-validate";
import YesNoRadioOptions from "@/components/questions/YesNoRadioOptions.vue";
import { Vue } from "vue-property-decorator";
import ChallengeApiService from "@/services/ChallengeApiService";
import PermissionForm from "@/types/challenge/PermissionForm";
import Signature from "@/components/inputs/Signature.vue";
import Email from "@/components/questions/Email.vue";

extend("event_required", (value) => {
  if (value) return true;
  else return "LTSChallenge.Validation.EventRequired";
});

extend("max_students", {
  validate(value, { max_students }: any) {
    if (value > max_students)
      return "LTSChallenge.Validation.MaxStudentsPerEducator";
    else return true;
  },
  params: ["max_students"],
});

@Component({
  components: {
    ValidationProvider,
    ValidationObserver,
    YesNoRadioOptions,
    Signature,
    Email,
  },
})
export default class LTSChallengePermission extends Vue {
  model: PermissionForm = new PermissionForm();
  medical_conditions = false;

  event_details: any = null;

  submissionComplete = false;
  saving = false;

  challengeApiService: ChallengeApiService = new ChallengeApiService();

  get Form(): string {
    return "LTSChallengePermission";
  }

  get Instance(): string | null {
    return null;
  }

  get RegistrationId(): string | null {
    return this.$route.params.registration_id;
  }

  get gradeOptions(): any[] {
    return [
      {
        text: this.$root.$t("LTSChallenge.Grades.Grade6"),
        value: "Grade 06",
      },
      {
        text: this.$root.$t("LTSChallenge.Grades.Grade7"),
        value: "Grade 07",
      },
      {
        text: this.$root.$t("LTSChallenge.Grades.Grade8"),
        value: "Grade 08",
      },
      {
        text: this.$root.$t("LTSChallenge.Grades.Other"),
        value: "Grade 09",
      },
    ];
  }

  mounted() {
    if (!this.RegistrationId) {
      this.goToFormClosed();
    } else {
      this.model.RegistrationId = this.RegistrationId;
      this.model.DateSigned = new Date().toISOString();

      this.challengeApiService
        .GetEventDetailsByRegistrationId(this.RegistrationId)
        .then((resp) => {
          if (resp.Success) {
            this.event_details = resp.Value;
          } else {
            console.log(resp);
            this.goToFormClosed();
          }
        });
    }
  }

  goToFormClosed() {
    this.$router.push("/FormClosed");
  }

  onSubmit() {
    this.saving = true;
    return this.challengeApiService
      .CreatePermissionForm(this.model)
      .then((resp) => {
        if (!resp.Success) {
          console.log("an error occurred saving the permission form");
          console.log(resp);
        } else {
          this.submissionComplete = true;
        }
      })
      .finally(() => (this.saving = false));
  }
}
</script>

<style scoped>
hr {
  background-color: rgb(145, 145, 145);
}

.question {
  padding-top: 20px;
  font-weight: bold;
}

table,
th,
td {
  border: 1px solid black;
  padding: 10px;
  margin-top: 10px;
}

thead {
  background-color: #0053a1;
  color: white;
}

#waitlist_notification {
  color: #0053a1;
  font-weight: bold;
}
</style>
