// Utility function to get the right configuration for the current environment
function GetConfigForEnvironment(): EnvironmentConfig {
  if (process.env && process.env.NODE_ENV === "development") {
    return DevelopmentConfig;
  }

  const currentHost = window.location.host;

  if (currentHost.includes("sandbox")) {
    return SandboxConfig;
  } else if (currentHost.includes("staging")) {
    return StagingConfig;
  } else if (window.location.host.includes("production")) {
    return MigrationProductionConfig;
  } else {
    return ProductionConfig;
  }
}

export { GetConfigForEnvironment };

//interface for environment configurations
interface EnvironmentConfig {
  idUrl: string;
  apiUrl: string;
  showVersion: boolean;
}

// Development configuration
const DevelopmentConfig: EnvironmentConfig = {
  idUrl: process.env.VUE_APP_LTSOL_ID_URL || "",
  apiUrl: process.env.VUE_APP_LTSOL_APIS_URL || "",
  showVersion: process.env.VUE_APP_SHOW_VERSION === "true",
};

// Sandbox configuration
const SandboxConfig: EnvironmentConfig = {
  idUrl:
    process.env.VUE_APP_LTSOL_ID_URL ||
    "https://auth.sandbox.test.letstalkscience.ca",
  apiUrl:
    process.env.VUE_APP_LTSOL_APIS_URL ||
    "https://api.sandbox.test.letstalkscience.ca",
  showVersion: false,
};

// Staging configuration
const StagingConfig: EnvironmentConfig = {
  idUrl:
    process.env.VUE_APP_LTSOL_ID_URL || "https://ltsolid.staging.ltstest.ca",
  apiUrl:
    process.env.VUE_APP_LTSOL_APIS_URL ||
    "https://ltsolapis.staging.ltstest.ca",
  showVersion: false,
};

// Migration-Production configuration
const MigrationProductionConfig: EnvironmentConfig = {
  idUrl: "https://auth.production.letstalkscience.ca",
  apiUrl: "https://api.production.letstalkscience.ca",
  showVersion: false,
};

// Production configuration
const ProductionConfig: EnvironmentConfig = {
  idUrl: process.env.VUE_APP_LTSOL_ID_URL || "https://auth.letstalkscience.ca",
  apiUrl:
    process.env.VUE_APP_LTSOL_APIS_URL || "https://api.letstalkscience.ca",
  showVersion: false,
};
