import axios from "axios";
import type UseCaseResponse from "@/types/UseCaseResponse";
import BaseApiService from "./BaseApiService";

export default class FileApiService extends BaseApiService {
  async UploadFile(file: File): Promise<UseCaseResponse<string>> {
    await this.defineHeader();

    const data: FormData = new FormData();
    data.append("file", file);
    const config: object = {
      headers: {
        "content-type": "multipart/form-data",
      },
    };

    return axios
      .post(
        `${this.url}/api/files/FormFile?source=LTSOL_Registration`,
        data,
        config
      )
      .then((resp) => resp.data as UseCaseResponse<string>);
  }
}
