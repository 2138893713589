<template>
  <b-container fluid="xl" v-if="elevated">
    <b-form-group id="forms-group" label="Form" label-for="forms">
      <b-form-select
        id="forms"
        class="binput"
        :options="formOptions"
        v-model="selectedFormId"
      />
    </b-form-group>

    <b-form-group
      v-if="Instances.length > 0"
      id="instances-group"
      label="Instances"
      label-for="instances"
    >
      <b-form-select
        id="instances"
        class="binput"
        :options="Instances"
        v-model="selectedInstance"
      />
      <b-button class="ml-2" @click="onClearSelectedInstanceClicked"
        >Clear</b-button
      >
    </b-form-group>

    <div>
      <b-table
        responsive
        striped
        hover
        :busy="loading"
        :fields="fields"
        :items="FilteredRegistrations"
      >
        <template #cell(Registration)="data">
          <button @click="viewRegistration(data)" v-b-modal.modal-reg>
            View
          </button>
        </template>
      </b-table>
    </div>
    <b-modal id="modal-reg" title="Registration Json">
      <pre class="my-4">
        {{ JSON.stringify(selectedRegistration, null, 2) }}
      </pre>
    </b-modal>
  </b-container>
  <div v-else>404</div>
</template>

<script lang="ts">
import { Component, Vue, Watch } from "vue-property-decorator";
import FormApiService from "@/services/FormApiService";
import AuthorizationService from "@/services/AuthorizationService";

@Component
export default class SubmissionList extends Vue {
  name = "";
  model: any = {};
  forms: Array<object> = [];
  selectedFormId: string | null = null;
  selectedInstance: string | null = null;
  registrations: Array<object> = [];
  selectedRegistration: object = {};
  loading = false;
  fields: object[] = [
    {
      key: "FormInstanceName",
      label: "Instance",
    },
    {
      key: "CreatedByName",
      label: "User Name",
    },
    {
      key: "CreatedByEmail",
      label: "Email",
    },
    {
      key: "Created",
      label: "Registered",
    },
    {
      key: "Registration",
      label: "Registration JSON",
    },
    {
      key: "Actions",
      label: "",
    },
  ];

  authService: AuthorizationService = AuthorizationService.getInstance();
  formApiService: FormApiService = new FormApiService();

  elevated = AuthorizationService.getInstance().hasRole([
    "staff",
    "administrator",
  ]);

  get formOptions() {
    return this.forms.map((f: any) => {
      return {
        text: f.Name,
        value: f.Id,
      };
    });
  }

  get Instances(): string[] {
    const instances: string[] = [];
    this.registrations.map((s: any) => {
      if (!instances.includes(s.FormInstanceName)) {
        instances.push(s.FormInstanceName);
      }
    });

    return instances;
  }

  get FilteredRegistrations(): object[] {
    if (this.selectedInstance !== null) {
      return this.registrations.filter((e: any) => {
        return e.FormInstanceName === this.selectedInstance;
      });
    } else {
      return this.registrations;
    }
  }

  @Watch("selectedFormId")
  onSelectedFormChanged() {
    this.getRegistrations();
  }

  mounted() {
    this.formApiService.GetForms().then((resp) => {
      if (resp.Success) {
        this.forms = resp.Value as Array<object>;
        if (this.forms.length > 0) {
          this.selectedFormId = (this.forms[0] as any).Id;
        }
      }
    });
  }

  getRegistrations() {
    const formId: string = this.selectedFormId ?? "";
    this.loading = true;
    this.formApiService
      .GetFormSubmissions(formId)
      .then((resp) => {
        if (resp.Success) {
          this.registrations = resp.Value;
        }
      })
      .finally(() => {
        this.loading = false;
      });
  }

  onClearSelectedInstanceClicked() {
    this.selectedInstance = null;
  }

  viewRegistration(reg: any) {
    this.selectedRegistration = reg.item.RegistrationJson;
  }
}
</script>
