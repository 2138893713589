<template>
  <div>
    <p
      v-if="!simpleLabel"
      :class="required ? 'question required-field' : 'question'"
      :id="`${id}-email-lbl`"
    >
      {{ Label }}
    </p>

    <validation-provider
      v-slot="{ errors, valid }"
      :name="`${id}-vp`"
      :rules="{ required: required, regex: /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/ }"
    >
      <b-form-group
        :id="`${id}-email-group`"
        :label="simpleLabel ? Label : ''"
        :class="required ? 'required-field' : ''"
        :label-for="`${id}-email`"
      >
        <b-form-input
          :id="`${id}-email`"
          type="text"
          class="binput"
          v-model="email"
          @input="onInput"
        />
      </b-form-group>
      <b-form-invalid-feedback :state="valid">
        <span v-for="(error, index) in errors" :key="index">{{
          $t(error)
        }}</span>
      </b-form-invalid-feedback>
    </validation-provider>
  </div>
</template>

<script lang="ts">
import { Component, Vue, Prop } from "vue-property-decorator";
import { ValidationProvider } from "vee-validate";
import { extend } from "vee-validate";
import { regex } from "vee-validate/dist/rules";

extend("regex", {
  ...regex,
  message: "Email.Validation.InvalidFormat",
});

@Component({
  components: {
    ValidationProvider,
  },
})
export default class Email extends Vue {
  @Prop(String) id!: string;
  @Prop(String) value!: string;
  @Prop(String) label!: string;
  @Prop(Boolean) required!: boolean;
  @Prop(Boolean) simpleLabel!: boolean;

  email: string = this.value || "";

  get Label(): string {
    return this.label || this.$t("EmailQuestion").toString();
  }

  /* @Watch("value")
  onValueChanged() {
    this.email = this.value || "";
  } */

  onInput() {
    this.$emit("input", this.email);
  }
}
</script>
<style scoped>
.question {
  padding-top: 20px;
  font-weight: bold;
}
</style>
