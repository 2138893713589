<template>
  <div :id="id">
    <validation-provider
      v-slot="{ errors, valid }"
      name="TermsAndConditions"
      :rules="{ required: true }"
    >
      <b-form-group>
        <b-form-checkbox
          :id="`${id}-tandc`"
          v-model="tandc"
          :unchecked-value="false"
        >
          <p class="required-field mb-0 pb-0" :id="`${id}-tandc-label`">
            <span>
              {{ Label }}
            </span>
          </p>
          <a :href="$t('TermsAndConditions.TermsLink').toString()">
            {{ $t("TermsAndConditions.TermsLink") }}
          </a>
          <br />
          <a :href="$t('TermsAndConditions.PrivacyLink').toString()">
            {{ $t("TermsAndConditions.PrivacyLink") }}
          </a>
        </b-form-checkbox>
        <b-form-invalid-feedback :state="valid">
          <span v-for="(error, index) in errors" :key="index">{{
            $t(error)
          }}</span>
        </b-form-invalid-feedback>
      </b-form-group>
    </validation-provider>
  </div>
</template>

<script lang="ts">
import { Component, Vue, Watch, Prop } from "vue-property-decorator";
import { ValidationProvider } from "vee-validate";

@Component({
  components: {
    ValidationProvider,
  },
})
export default class TermsAndConditions extends Vue {
  @Prop(String) id!: string;
  @Prop({ type: Boolean, default: false }) value!: boolean;
  @Prop(String) label!: string;

  tandc = false;

  mounted() {
    this.tandc = this.value;
  }

  get Label(): string {
    return this.label || this.$t("TermsAndConditions.Label").toString();
  }

  get Error() {
    return this.$t("TermsAndConditions.Error").toString();
  }

  @Watch("value")
  onValueChanged(value: boolean) {
    this.tandc = value;
  }

  @Watch("tandc")
  onChanged(value: boolean) {
    this.$emit("input", value);
  }
}
</script>
<style scoped>
.question {
  padding-top: 20px;
  font-weight: bold;
}
</style>
