import BaseApiService from "./BaseApiService";
import axios from "axios";
import GetOrganizationResponse from "@/types/GetOrganizationResponse";
import UseCaseResponse from "@/types/UseCaseResponse";
import GetOrgEnumResponse from "@/types/GetOrgEnumResponse";

export default class OrganizationApiService extends BaseApiService {
  cache: any = {};

  async GetOrganizations(
    query: string,
    type?: string,
    status?: number
  ): Promise<UseCaseResponse<any[]>> {
    let url = `${this.url}/api/Organization?query=${query}&includeUnverified=true`;
    if (type !== undefined) {
      url += `&type=${type}`;
    }

    if (status !== undefined) {
      url += `&status=${status}`;
    }

    return axios
      .get(url)
      .then((resp) => resp.data as UseCaseResponse<GetOrganizationResponse[]>);
  }

  async GetEnumerationList(
    desc: string,
    locale = ""
  ): Promise<UseCaseResponse<GetOrgEnumResponse[]>> {
    let url = `${this.url}/api/Organization/Enumeration/${desc}`;
    if (locale.length > 0) {
      url += `?locale=${locale}`;
    }

    return axios.get(url).then((resp) => {
      return resp.data as UseCaseResponse<GetOrgEnumResponse[]>;
    });
  }
}
