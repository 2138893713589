import axios from "axios";
import BaseApiService from "./BaseApiService";
import type UseCaseResponse from "@/types/UseCaseResponse";

export default class SSOApiService extends BaseApiService {
  async GetUserValidInterstitialData(): Promise<UseCaseResponse<boolean>> {
    await this.defineHeader();
    return axios
      .get(`${this.url}/api/SSO/Framework/User/ValidInterstitialData`)
      .then((resp) => resp.data as UseCaseResponse<boolean>);
  }
}
