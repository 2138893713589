export default class CreateSubmissionRequest {
  FormName = "";
  InstanceName: string | null = null;
  Data: object = {};

  constructor(f_name: string, i_name: string | null, d: object) {
    this.FormName = f_name;
    this.InstanceName = i_name;
    this.Data = d;
  }
}
