import axios from "axios";
import UseCaseResponse from "@/types/UseCaseResponse";
import BaseApiService from "../BaseApiService";
import IEnumApiService from "./IEnumApiService";

export default class ProgramApiService
  extends BaseApiService
  implements IEnumApiService
{
  async Delete(id: string): Promise<UseCaseResponse<boolean>> {
    await this.defineHeader();
    return axios
      .delete(`${this.url}/api/registration/Program/${id}`)
      .then((resp) => resp.data as UseCaseResponse<boolean>);
  }

  async GetAll(): Promise<UseCaseResponse<object[]>> {
    await this.defineHeader();
    return axios
      .get(`${this.url}/api/registration/Program`)
      .then((resp) => resp.data as UseCaseResponse<object[]>);
  }

  async Create(v: string): Promise<UseCaseResponse<string>> {
    await this.defineHeader();
    return axios
      .post(`${this.url}/api/registration/Program?name=${v}`)
      .then((resp) => resp.data as UseCaseResponse<string>);
  }
}
