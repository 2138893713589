<template>
  <label style="display: block">
    {{ label }}
    <b-form-file
      v-model="file"
      :placeholder="$t('ChooseFile')"
      :drop-placeholder="$t('DropFile')"
      @input="onInput"
    ></b-form-file>
  </label>
</template>

<script lang="ts">
import { Component, Vue, Watch, Prop } from "vue-property-decorator";

@Component
export default class FileInput extends Vue {
  @Prop(File) value!: File | null;
  @Prop(String) label!: string;

  file: File | null = this.value ?? null;

  @Watch("value")
  onValueChanged() {
    this.file = this.value;
  }

  onInput() {
    this.$emit("input", this.file);
  }
}
</script>
