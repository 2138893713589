<template>
  <b-container fluid="xl">
    <div
      style="width: 500px; max-width: 100%; margin: 0 auto; margin-top: 40px"
    >
      <b-img
        fluid
        v-if="Locale === 'fr'"
        src="@/assets/tomatosphere/header-fr.png"
        alt="Le Tomatosphere"
        class="headerimg"
      />
      <b-img
        fluid
        v-else
        src="@/assets/tomatosphere/header-en.png"
        alt="Tomatosphere"
        class="headerimg"
      />
    </div>

    <h1 class="h2">{{ Header }}</h1>

    <div v-if="!submissionComplete">
      <p>
        {{ $t("Tomatosphere.Intro") }}
      </p>
      <hr />

      <validation-observer v-slot="{ handleSubmit }">
        <b-form @submit.prevent="handleSubmit(onSubmit)">
          <your-school v-model="model.School" />

          <h2 class="h5">
            {{ $t("ClassesOrGroups.Label") }}
          </h2>
          <validation-provider
            v-slot="{ errors, valid }"
            name="classcollection"
            :rules="{ required: true, min: 1 }"
          >
            <class-collection
              ref="classCollection"
              id="classcollection"
              v-model="model.Classes"
              description-values
            />
            <b-form-invalid-feedback :state="valid">
              <span v-for="(error, index) in errors" :key="index">{{
                $t(error)
              }}</span>
            </b-form-invalid-feedback>
          </validation-provider>

          <number-of-adults v-model="model.NumAdults" />

          <project-context v-model="model.Context" />

          <language
            :label="$t('Tomatosphere.Questions.Language')"
            v-model="model.Language"
          />

          <learned-about-project v-model="model.LearnedAbout" />

          <feedback v-model="model.Feedback" />

          <mailing-list id="mailing_list" v-model="model.MailingList" />

          <yes-no-radio-options
            id="ordering_seeds"
            v-model="model.OrderingSeeds"
            :label="$t('Tomatosphere.Questions.OrderingSeeds')"
            :required="true"
            name="OrderingSeeds"
          />

          <canada-post-shipping-address
            v-if="model.OrderingSeeds === true"
            v-model="model.ShippingAddress"
          />

          <b-button
            id="btn_submit"
            type="submit"
            class="mt-4"
            variant="primary"
            :disabled="saving"
            >{{ $t("Submit") }}</b-button
          >
        </b-form>
      </validation-observer>
    </div>

    <!-- Confirmation -->
    <div v-else>
      <b-container id="confirmationheader">
        <h3>{{ $t("Tomatosphere.Confirmation.Header") }}</h3>
      </b-container>
      <hr />
      <p id="confirmationsub">
        <strong>
          {{ $t("Tomatosphere.Confirmation.SubHeader") }}
        </strong>
      </p>
      <p id="confirmationbody" v-html="$t('Tomatosphere.Confirmation.Body')" />
      <b-button
        id="confirmationbtncontainer"
        variant="primary"
        :href="$t('Tomatosphere.Confirmation.ButtonLink')"
        size="lg"
      >
        {{ $t("Tomatosphere.Confirmation.Button") }}
      </b-button>
    </div>
  </b-container>
</template>

<script lang="ts">
import { Component } from "vue-property-decorator";
import { extend, ValidationProvider, ValidationObserver } from "vee-validate";
import { min, numeric, min_value } from "vee-validate/dist/rules";
import SchoolLookup from "@/components/school/SchoolLookup.vue";
import SchoolInfo from "@/components/school/SchoolInfo.vue";
import ClassCollection from "@/components/ClassCollection.vue";
import OrganizationApiService from "@/services/OrganizationApiService";
import ProjectContext from "@/components/questions/ProjectContext.vue";
import LearnedAboutProject from "@/components/questions/LearnedAboutProject.vue";
import OrgRegionSelect from "@/components/school/OrgRegionSelect.vue";
import Language from "@/components/questions/Language.vue";
import MailingList from "@/components/questions/MailingList.vue";
import NumberOfAdults from "@/components/questions/NumberOfAdults.vue";
import CanadaPostShippingAddress from "@/components/CanadaPostShippingAddress.vue";
import YourSchool from "@/components/questions/YourSchool.vue";
import FormBase from "@/types/FormBase";
import YesNoRadioOptions from "@/components/questions/YesNoRadioOptions.vue";
import Feedback from "@/components/questions/Feedback.vue";
import i18n from "@/i18n";

extend("min", {
  ...min,
  message: "Validation.MinLength",
});

extend("min_value", {
  ...min_value,
  message: "Validation.ProvideNumericGreaterThanZero",
});

extend("numeric", {
  ...numeric,
  message: "Validation.Numeric",
});

/* example json config
{
  "instances": [
    {
      "name": "ts1",
      "season": "spring 2023",
      "header": {
        "en": "Spring 2021",
        "fr": "Le Spring 2021"
      }
    },
    {
      "name": "ts2",
      "season": "fall 2024",
      "header": {
        "en": "Spring 2022",
        "fr": "Le Spring 2022"
      }
    }
  ]
} */

@Component({
  components: {
    ValidationProvider,
    ValidationObserver,
    SchoolLookup,
    SchoolInfo,
    ClassCollection,
    ProjectContext,
    LearnedAboutProject,
    OrgRegionSelect,
    Language,
    MailingList,
    NumberOfAdults,
    CanadaPostShippingAddress,
    YourSchool,
    YesNoRadioOptions,
    Feedback,
  },
})
export default class Tomatosphere extends FormBase {
  name = "";
  model: any = {
    ShippingAddress: {},
    School: {},
  };

  orgApiService: OrganizationApiService = new OrganizationApiService();

  get Form(): string {
    return "Tomatosphere";
  }

  get Instance(): string | null {
    return this.$route.params.instance;
  }

  get Locale(): string {
    return this.$i18n.locale;
  }

  get InstanceMetadata(): any {
    if (this.Metadata.instances && this.Instance) {
      const im = this.Metadata.instances.find(
        (i: any) => i.name === this.Instance
      );

      return im ?? {};
    }

    return {};
  }

  get Header(): string {
    const im = this.InstanceMetadata;
    if (im.header) {
      return this.InstanceMetadata.header[i18n.locale];
    } else {
      return "";
    }
  }

  mounted() {
    this.GetFormData().then((resp: any) => {
      this.submissionComplete = resp.Submissions > 0;

      if (!this.Instance) {
        this.GoToFormClosed();
      }

      this.model.Season = this.InstanceMetadata.season ?? "";
    });
  }

  onSubmit() {
    const classCollectionValid = (
      this.$refs.classCollection as ClassCollection
    ).validate();

    if (classCollectionValid === true) {
      this.SaveSubmission(this.model);
    }
  }
}
</script>
<style scoped>
.headerimg {
  max-width: 500px;
}

.question {
  padding-top: 20px;
}

#form {
  padding: 10px;
}

#submitbtn {
  margin-top: 25px;
}

.binput {
  max-width: 250px;
}
textarea.binput {
  max-width: 100%;
}

hr {
  background-color: rgb(145, 145, 145);
}

#noschool-details:invalid {
  border: 1px solid red;
}

#noschool-details:valid {
  border: 1px solid green;
}

#confirmationheader {
  margin-top: 30px;
  text-align: center;
}

#confirmationsub {
  margin-top: 30px;
}

#confirmationbody {
  margin-top: 30px;
}

#confirmationbtncontainer {
  margin-top: 30px;
}

#headercontainer {
  text-align: center;
}

.question {
  font-weight: bold;
}
</style>
<style>
@media (max-width: 1199px) {
  .two-col-checkboxes .custom-control-inline,
  .three-col-checkboxes .custom-control-inline {
    width: 100%;
  }
}

@media (min-width: 1200px) {
  .two-col-checkboxes .custom-control-inline {
    margin-right: 1%;
    width: 49%;
  }

  .three-col-checkboxes .custom-control-inline {
    margin-right: 1%;
    width: 32%;
  }
}
</style>
@/types/FormBase
