<template>
  <b-container fluid="xl">
    <div v-if="!submissionComplete">
      <h1 class="h2">{{ $t("DailyDiscovery.Header") }}</h1>

      <p>
        {{ $t("DailyDiscovery.Intro") }}
      </p>

      <!-- weeks table -->
      <table>
        <thead>
          <tr>
            <th>{{ $t("DailyDiscovery.Labels.Week") }}</th>
            <th>{{ $t("DailyDiscovery.Labels.StartDate") }}</th>
            <th>{{ $t("DailyDiscovery.Labels.EndDate") }}</th>
            <th />
          </tr>
        </thead>
        <tbody>
          <tr v-for="(w, idx) in WeekCollection" :key="idx">
            <td>{{ w.week_number }}</td>
            <td>
              {{ $i18n.locale === "fr" ? w.start_date_fr : w.start_date_en }}
            </td>
            <td>{{ $i18n.locale === "fr" ? w.end_date_fr : w.end_date_en }}</td>
            <td>
              <!-- ignoring errors about using both :value and v-model -> this is what boostrap expects -->
              <!-- @vue-skip -->
              <b-checkbox
                :id="`w-cb-${idx}`"
                :unchecked-value="null"
                :value="w.week_id"
                :disabled="weekDisabled(w.week_id)"
                type="checkbox"
                v-model="selectedWeeks"
              />
            </td>
          </tr>
        </tbody>
      </table>

      <hr />

      <validation-observer v-slot="{ handleSubmit }">
        <b-form @submit.prevent="handleSubmit(onSubmit)">
          <your-school v-model="model.School" />

          <h2 class="h5">
            {{ $t("ClassesOrGroups.Label") }}
          </h2>
          <validation-provider
            v-slot="{ errors, valid }"
            name="classcollection"
            :rules="{ required: true, min: 1 }"
          >
            <class-collection
              ref="classCollection"
              id="classcollection"
              v-model="model.Classes"
              description-values
            />
            <b-form-invalid-feedback :state="valid">
              <span v-for="(error, index) in errors" :key="index">{{
                $t(error)
              }}</span>
            </b-form-invalid-feedback>
          </validation-provider>

          <h2 class="h5">
            {{ $t("AboutYou") }}
          </h2>

          <number-of-adults
            :label="$t('DailyDiscovery.Questions.NumAdults')"
            v-model="model.NumAdults"
            allow-zero
          />

          <learned-about-project v-model="model.LearnedAbout" />

          <mailing-list v-model="model.MailingList" />

          <role id="role" v-model="model.Role" />

          <language
            :label="$t('DailyDiscovery.Questions.Language')"
            :options="langOptions"
            v-model="model.Language"
          />

          <b-button
            id="btn_submit"
            type="submit"
            class="mt-2"
            variant="primary"
            :disabled="saving"
            >{{ $t("Submit") }}</b-button
          >
        </b-form>
      </validation-observer>
    </div>

    <!-- Confirmation -->
    <div v-else>
      <b-container id="confirmationheader">
        <h3>{{ $t("DailyDiscovery.Confirmation.Header") }}</h3>
      </b-container>
      <hr />
      <p id="confirmationbody">
        {{ $t("DailyDiscovery.Confirmation.Body") }}
      </p>
    </div>
  </b-container>
</template>

<script lang="ts">
import { Component } from "vue-property-decorator";
import FormBase from "@/types/FormBase";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import SchoolLookup from "@/components/school/SchoolLookup.vue";
import SchoolInfo from "@/components/school/SchoolInfo.vue";
import ClassCollection from "@/components/ClassCollection.vue";
import ProjectContext from "@/components/questions/ProjectContext.vue";
import LearnedAboutProject from "@/components/questions/LearnedAboutProject.vue";
import MailingList from "@/components/questions/MailingList.vue";
import NumberOfAdults from "@/components/questions/NumberOfAdults.vue";
import YourSchool from "@/components/questions/YourSchool.vue";
import YesNoRadioOptions from "@/components/questions/YesNoRadioOptions.vue";
import Role from "@/components/questions/Role.vue";
import Language from "@/components/questions/Language.vue";
import UseCaseResponse from "@/types/UseCaseResponse";

interface week {
  week_id: string;
  end_date_en: string;
  end_date_fr: string;
  week_number: number;
  event_status: string;
  start_date_en: string;
  start_date_fr: string;
}

@Component({
  components: {
    ValidationProvider,
    ValidationObserver,
    SchoolLookup,
    SchoolInfo,
    ClassCollection,
    ProjectContext,
    LearnedAboutProject,
    MailingList,
    NumberOfAdults,
    YourSchool,
    YesNoRadioOptions,
    Role,
    Language,
  },
})
export default class DailyDiscovery extends FormBase {
  name = "";
  model: any = {};
  selectedWeeks: number[] = [];

  get Form(): string {
    return "DailyDiscovery";
  }

  get Instance(): string | null {
    return this.$route.params.instance;
  }

  get Locale(): string {
    return this.$i18n.locale;
  }

  get langOptions() {
    return [
      {
        text: this.$root.$t("DailyDiscovery.Language.Options.English"),
        value: "English",
      },
      {
        text: this.$root.$t("DailyDiscovery.Language.Options.French"),
        value: "French",
      },
      {
        text: this.$root.$t("DailyDiscovery.Language.Options.Both"),
        value: "Both",
      },
    ];
  }

  get WeekCollection(): week[] {
    if (this.Metadata && this.Metadata.instances) {
      const instance = (this.Metadata.instances as any[]).find(
        (i) => (i.instance as string).toLowerCase() === this.Instance
      );
      return (instance.weeks as week[]).filter(
        (w) => w.event_status.toLowerCase() === "open"
      );
    }

    return [];
  }

  get MultiRegistration(): boolean {
    let ret = true;

    if (this.Metadata && this.Metadata.instances) {
      const instance = (this.Metadata.instances as any[]).find(
        (i) => (i.instance as string).toLowerCase() === this.Instance
      );

      ret = instance.multi_registration ?? true;
    }

    return ret;
  }

  weekDisabled(w_id: number): boolean {
    return (
      !this.MultiRegistration &&
      this.selectedWeeks.length > 0 &&
      !this.selectedWeeks.includes(w_id)
    );
  }

  mounted() {
    this.GetFormData();
  }

  onSubmit() {
    const classCollectionValid = (
      this.$refs.classCollection as ClassCollection
    ).validate();

    if (classCollectionValid === true) {
      this.saving = true;
      this.submissionApiService
        .All(
          this.selectedWeeks.map((w) => {
            const s = {
              week_id: w,
              ...this.model,
            };

            return this.submissionApiService.CreateSubmission(
              this.Form,
              this.Instance,
              s
            );
          })
        )
        .then((resps) => {
          if (resps.find((ucr: UseCaseResponse<string>) => !ucr.Success)) {
            console.log("an error occurred saving registrations");
            console.log(resps);
          } else {
            this.submissionComplete = true;
            this.SubmissionComplete();
          }
        })
        .finally(() => (this.saving = false));
    }
  }
}
</script>
<style scoped>
.headerimg {
  max-width: 500px;
}

.question {
  padding-top: 20px;
}

#form {
  padding: 10px;
}

#submitbtn {
  margin-top: 25px;
}

.binput {
  max-width: 250px;
}
textarea.binput {
  max-width: 100%;
}

.step-progress {
  display: inline;
}

hr {
  background-color: rgb(145, 145, 145);
}

.phelp {
  font-style: italic;
}

#noschool-details:invalid {
  border: 1px solid red;
}

#noschool-details:valid {
  border: 1px solid green;
}

#confirmationheader {
  margin-top: 30px;
  text-align: center;
}

#confirmationsub {
  margin-top: 30px;
}

#confirmationbody {
  margin-top: 30px;
}

#confirmationbtncontainer {
  margin-top: 30px;
}

#headercontainer {
  text-align: center;
}

.question {
  font-weight: bold;
}
</style>
<style>
@media (max-width: 1199px) {
  .two-col-checkboxes .custom-control-inline,
  .three-col-checkboxes .custom-control-inline {
    width: 100%;
  }
}

@media (min-width: 1200px) {
  .two-col-checkboxes .custom-control-inline {
    margin-right: 1%;
    width: 49%;
  }

  .three-col-checkboxes .custom-control-inline {
    margin-right: 1%;
    width: 32%;
  }
}

hr {
  background-color: rgb(145, 145, 145);
}

.question {
  padding-top: 20px;
  font-weight: bold;
}

table,
th,
td {
  border: 1px solid black;
  padding: 10px;
  margin-top: 10px;
}

thead {
  background-color: #0053a1;
  color: white;
}

#waitlist_notification {
  color: #0053a1;
  font-weight: bold;
}
</style>
