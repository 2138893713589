<template>
  <b-container fluid="xl">
    <h1>{{ $t("LTSChallenge.Registration.Header") }}</h1>
    <p>
      {{ $t("LTSChallenge.Registration.Intro") }}
    </p>
    <a href="/View/LTSChallenge">{{
      $t("LTSChallenge.Registration.ViewOther")
    }}</a>
    <div v-if="loaded">
      <table>
        <thead>
          <tr>
            <th>
              {{ $t("LTSChallenge.Registration.Table.EventDate") }}
            </th>
            <th>
              {{ $t("LTSChallenge.Registration.Table.EventType") }}
            </th>
            <th>
              {{ $t("LTSChallenge.Registration.Table.EventLanguage") }}
            </th>
            <th>
              {{ $t("LTSChallenge.Registration.Table.EventLocation") }}
            </th>
            <th>
              {{ $t("LTSChallenge.Registration.Table.EventCity") }}
            </th>
            <th>
              {{ $t("LTSChallenge.Registration.Table.NumStudents") }}
            </th>
            <th>
              {{ $t("LTSChallenge.Registration.Table.NumAdults") }}
            </th>
            <th>
              {{ $t("LTSChallenge.Registration.Table.RegistrationStatus") }}
            </th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>
              {{
                $i18n.locale === "fr"
                  ? Event.DisplayDateFrench
                  : Event.DisplayDateEnglish
              }}
            </td>
            <td>
              {{ $t(`LTSChallenge.EventTypes.${RegistrationJson.EventType}`) }}
            </td>
            <td>
              {{ $t(`LTSChallenge.RegistrationLanguage.${EventLanguage}`) }}
            </td>
            <td>{{ Event.Location }}</td>
            <td>{{ Event.City }}</td>
            <td>{{ RegistrationJson.NumStudents }}</td>
            <td>{{ RegistrationJson.NumAdults }}</td>
            <td>
              {{ $t(`LTSChallenge.RegistrationStatus.${RegistrationStatus}`) }}
            </td>
          </tr>
        </tbody>
      </table>

      <b-card class="mt-4" v-if="registration.Status === PendingStatus">
        <template #header>
          <h5>
            {{ $t("LTSChallenge.Registration.PendingStatusHeader") }}
          </h5>
        </template>
        <b-card-text>
          {{ $t("LTSChallenge.Registration.PendingStatusText") }}
        </b-card-text>
        <template #footer>
          <b-container>
            <b-row>
              <b-col cols="1">
                <b-button
                  variant="danger"
                  @click="onUpdatePendingRegistration(false)"
                >
                  {{ $t("LTSChallenge.Registration.CancelPendingStatus") }}
                </b-button>
              </b-col>
              <b-col cols="1">
                <b-button
                  variant="primary"
                  @click="onUpdatePendingRegistration(true)"
                >
                  {{ $t("LTSChallenge.Registration.ConfirmPendingStatus") }}
                </b-button>
              </b-col>
            </b-row>
          </b-container>
        </template>
      </b-card>

      <b-card class="mt-4">
        <template #header>
          <h5>{{ $t("LTSChallenge.Registration.Teams") }}</h5>
          <p>
            {{ $t("LTSChallenge.Registration.Teams.Info") }}
          </p>
          <b-form inline @submit.prevent>
            <b-input-group id="name-group">
              <b-form-input
                id="name"
                type="text"
                :placeholder="$t('LTSChallenge.Registration.NewTeamName')"
                :disabled="!CanAddTeamMembers"
                @keyup.enter="onAddTeamClick"
                v-model="newteam_name"
              />
            </b-input-group>
            <b-button
              class="ml-4"
              variant="primary"
              :disabled="!CanAddTeamMembers"
              @click="onAddTeamClick"
            >
              {{ $t("LTSChallenge.Registration.AddTeam") }}
            </b-button>
            <span
              v-if="!CanAddTeamMembers"
              class="ml-2"
              style="color: lightcoral"
            >
              {{ $t("LTSChallenge.Registration.MaxTeamMembersReached") }}
            </span>
          </b-form>
        </template>
        <div v-if="teams.length === 0" class="no-data-text">
          {{ $t("LTSChallenge.Registration.NoTeamMessage") }}
        </div>
        <div>
          <b-card no-body class="mb-1" v-for="(t, idx) in teams" :key="idx">
            <b-card-header header-tag="header" class="p-1">
              <b-container>
                <b-row no-gutters class="text-center">
                  <b-col cols="6">
                    <b-button
                      squared
                      block
                      v-b-toggle="`c-${idx}`"
                      variant="light"
                    >
                      {{ t.Name }}
                    </b-button>
                  </b-col>
                  <b-col style="background-color: #d8d8d8">
                    <b-badge variant="secondary" pill class="ml-2 mr-2">
                      {{ t.TeamMembers.length }}
                    </b-badge>
                    <b-button
                      squared
                      variant="light"
                      @click="onEditTeamClicked(t)"
                    >
                      {{ $t("LTSChallenge.EditTeam") }}
                    </b-button>
                    <b-button
                      squared
                      variant="light"
                      :disabled="
                        !CanAddTeamMembers ||
                        (t.TeamMembers.length > 3 && !IsVirtual)
                      "
                      @click="onAddTeamMemberClicked(t)"
                    >
                      {{ $t("LTSChallenge.AddTeamMember.Add") }}
                    </b-button>
                    <b-button
                      squared
                      variant="light"
                      @click="onDeleteTeamClicked(t)"
                    >
                      {{ $t("LTSChallenge.Registration.DeleteTeam") }}
                    </b-button>
                  </b-col>
                </b-row>
              </b-container>
              <b-modal
                :title="$t('LTSChallenge.EditTeam')"
                v-model="editing_team"
                @cancel="onEditTeamCancelled"
                @ok="onEditTeamComplete"
              >
                <b-form-input
                  id="editteam_name"
                  type="text"
                  v-model="edit_team.Name"
                  @keyup.enter="onEditTeamComplete"
                />
                <template #modal-footer>
                  <b-button @click="onEditTeamCancelled">{{
                    $t("Cancel")
                  }}</b-button>
                  <b-button variant="primary" @click="onEditTeamComplete">{{
                    $t("Ok")
                  }}</b-button>
                </template>
              </b-modal>
            </b-card-header>
            <b-collapse :id="`c-${idx}`" visible>
              <b-card-body>
                <b-card-text>
                  <div
                    v-if="!t.TeamMembers || t.TeamMembers.length === 0"
                    class="no-data-text"
                  >
                    {{ $t("LTSChallenge.Registration.NoTeamMembersMessage") }}
                    <br />
                  </div>
                  <div v-else>
                    <b-list-group>
                      <b-list-group-item
                        v-for="(m, mIdx) in t.TeamMembers"
                        :key="mIdx"
                        class="d-flex justify-content-between align-items-center"
                      >
                        <b-container>
                          <b-row class="text-center" align-v="center">
                            <b-col cols="4">
                              {{ m.FirstName }} {{ m.LastName }}
                            </b-col>
                            <b-col cols="4">
                              <b-select
                                v-if="!HidePermissionForms"
                                :options="PermissionFormOptions"
                                v-model="m.PermissionFormId"
                                size="sm"
                                class="mr-2"
                                :state="m.PermissionFormId ? true : false"
                                @input="onUpdateTeamMemberPermissionForm(m)"
                              >
                                <template #first>
                                  <b-select-option :value="null">{{
                                    $t(
                                      "LTSChallenge.Registration.NoPermissionForm"
                                    )
                                  }}</b-select-option>
                                  <b-form-select-option
                                    v-if="m.PermissionFormId"
                                    :value="m.PermissionFormId"
                                  >
                                    {{
                                      permission_forms.find(
                                        (pf) => pf.Id === m.PermissionFormId
                                      )?.StudentFirstName
                                    }}
                                    {{
                                      permission_forms.find(
                                        (pf) => pf.Id === m.PermissionFormId
                                      )?.StudentLastName
                                    }}
                                  </b-form-select-option>
                                </template>
                              </b-select>
                            </b-col>
                            <b-col>
                              <b-button
                                variant="light"
                                size="sm"
                                @click="onDeleteTeamMemberClicked(m)"
                                >{{
                                  $t(
                                    "LTSChallenge.Registration.DeleteTeamMember"
                                  )
                                }}</b-button
                              >
                            </b-col>
                          </b-row>
                        </b-container>
                      </b-list-group-item>
                    </b-list-group>
                  </div>
                </b-card-text>
              </b-card-body>
            </b-collapse>
          </b-card>
        </div>
        <!-- add student modal -->
        <b-modal
          :title="$t('LTSChallenge.AddTeamMember.Header')"
          v-model="adding_tm"
        >
          <p>
            {{ $t("LTSChallenge.AddTeamMember.Info") }}
          </p>
          <validation-observer ref="tm_validator">
            <b-form @submit.prevent>
              <validation-provider
                v-slot="{ errors, valid }"
                name="tm-fn-vp"
                :rules="{ required: true }"
              >
                <b-form-group
                  id="tm-fn-group"
                  :label="$t('LTSChallenge.AddTeamMember.TeamMemberFirstName')"
                  class="required-field"
                  label-for="tm-fn"
                >
                  <b-form-input
                    id="tm-fn"
                    class="binput"
                    v-model="edit_tm.FirstName"
                  />
                </b-form-group>
                <b-form-invalid-feedback :state="valid">
                  <span v-for="(error, index) in errors" :key="index">{{
                    $t(error)
                  }}</span>
                </b-form-invalid-feedback>
              </validation-provider>
              <validation-provider
                v-slot="{ errors, valid }"
                name="tm-ln-vp"
                :rules="{ required: true }"
              >
                <b-form-group
                  id="tm-ln-group"
                  :label="$t('LTSChallenge.AddTeamMember.TeamMemberLastName')"
                  class="required-field"
                  label-for="tm-ln"
                >
                  <b-form-input
                    id="tm-ln"
                    class="binput"
                    v-model="edit_tm.LastName"
                  />
                </b-form-group>
                <b-form-invalid-feedback :state="valid">
                  <span v-for="(error, index) in errors" :key="index">{{
                    $t(error)
                  }}</span>
                </b-form-invalid-feedback>
              </validation-provider>
              <b-form-group
                id="tm-pf-group"
                :label="$t('LTSChallenge.Registration.PermissionForm')"
                label-for="tm-pf"
                v-if="!HidePermissionForms"
              >
                <b-select
                  :options="PermissionFormOptions"
                  v-model="edit_tm.PermissionFormId"
                  size="sm"
                  class="mr-2"
                >
                  <template #first>
                    <b-select-option :value="null">{{
                      $t("LTSChallenge.Registration.NoPermissionForm")
                    }}</b-select-option>
                  </template>
                </b-select>
              </b-form-group>
            </b-form>
          </validation-observer>
          <template #modal-footer>
            <b-button @click="cancelAddTM">{{ $t("Cancel") }}</b-button>
            <b-button
              :disabled="saving_tm"
              variant="primary"
              @click="addTMOk"
              >{{ $t("Ok") }}</b-button
            >
          </template>
        </b-modal>
      </b-card>
      <!-- Permission Forms -->
      <b-card class="mt-4" v-if="!HidePermissionForms">
        <template #header>
          <h5>{{ $t("LTSChallenge.Registration.PermissionForms") }}</h5>
          <p style="color: gray">
            {{ $t("LTSChallenge.Registration.PermissionFormLinkLinkLabel") }}
          </p>
          <p>
            <a target="__blank" :href="PermissionsUrl">{{ PermissionsUrl }}</a>
          </p>
          <b-button variant="primary" @click="onAddPFClicked">
            {{ $t("LTSChallenge.Registration.AddPermissionForm") }}
          </b-button>
        </template>
        <div v-if="permission_forms.length === 0" class="no-data-text">
          {{ $t("LTSChallenge.Registration.NoPermissionFormsMessage") }}
        </div>
        <div v-else>
          <b-card
            no-body
            class="mb-1"
            v-for="(pf, idx) in permission_forms"
            :key="idx"
          >
            <b-card-header header-tag="header" class="p-1">
              <b-button squared block v-b-toggle="`pfc-${idx}`" variant="light">
                {{ pf.StudentFirstName }} {{ pf.StudentLastName }}
              </b-button>
            </b-card-header>
            <b-collapse :id="`pfc-${idx}`" visible>
              <b-card-body>
                <b-card-text>
                  <b-container>
                    <b-row>
                      <b-col>
                        {{ $t("LTSChallenge.Registration.Student") }}:
                      </b-col>
                      <b-col>
                        {{ pf.StudentFirstName }} {{ pf.StudentLastName }}
                        <template v-if="pf.StudentEmail">
                          ({{ pf.StudentEmail }})
                        </template>
                      </b-col>
                    </b-row>
                    <hr class="thin-hr" />
                    <b-row>
                      <b-col>
                        {{ $t("LTSChallenge.Registration.PhotoConsent") }}:
                        <b-icon-check
                          v-if="pf.PhotoConsent"
                          variant="success"
                        />
                        <b-icon-x v-else variant="danger" />
                      </b-col>
                      <b-col>
                        {{ $t("LTSChallenge.Registration.LongitudeConsent") }}:
                        <b-icon-check
                          v-if="pf.LongitudeStudyConsent"
                          variant="success"
                        />
                        <b-icon-x v-else variant="danger" />
                      </b-col>
                    </b-row>
                    <hr class="thin-hr" />
                    <b-row>
                      <b-col>
                        {{ $t("LTSChallenge.Registration.MedicalConditions") }}:
                      </b-col>
                      <b-col>
                        {{ pf.MedicalConditions }}
                      </b-col>
                    </b-row>
                  </b-container>
                </b-card-text>
              </b-card-body>
            </b-collapse>
          </b-card>
        </div>
        <b-modal
          :title="$t('LTSChallenge.AddPermission.Header')"
          v-model="adding_pf"
        >
          <p>
            {{ $t("LTSChallenge.AddPermission.Info") }}
          </p>
          <validation-observer ref="pf_validator">
            <b-form @submit.prevent>
              <validation-provider
                v-slot="{ errors, valid }"
                name="pf-student-fn-vp"
                :rules="{ required: true }"
              >
                <b-form-group
                  id="pf-student-fn-group"
                  :label="$t('LTSChallenge.AddPermission.StudentFirstName')"
                  class="required-field"
                  label-for="pf-student-fn"
                >
                  <b-form-input
                    id="pf-student-fn"
                    class="binput"
                    v-model="edit_pf.StudentFirstName"
                  />
                </b-form-group>
                <b-form-invalid-feedback :state="valid">
                  <span v-for="(error, index) in errors" :key="index">{{
                    $t(error)
                  }}</span>
                </b-form-invalid-feedback>
              </validation-provider>
              <validation-provider
                v-slot="{ errors, valid }"
                name="pf-student-ln-vp"
                :rules="{ required: true }"
              >
                <b-form-group
                  id="pf-student-ln-group"
                  :label="$t('LTSChallenge.AddPermission.StudentLastName')"
                  class="required-field"
                  label-for="pf-student-ln"
                >
                  <b-form-input
                    id="pf-student-ln"
                    class="binput"
                    v-model="edit_pf.StudentLastName"
                  />
                </b-form-group>
                <b-form-invalid-feedback :state="valid">
                  <span v-for="(error, index) in errors" :key="index">{{
                    $t(error)
                  }}</span>
                </b-form-invalid-feedback>
              </validation-provider>
              <!-- photo / media consent -->
              <div>
                <yes-no-radio-options
                  id="photo-consent"
                  v-model="edit_pf.PhotoConsent"
                  :label="$t('LTSChallenge.AddPermission.PhotoConsent')"
                  simple-label
                  :required="true"
                />
              </div>
              <!-- longitudinal study consent -->
              <div>
                <yes-no-radio-options
                  id="longitude-consent"
                  v-model="edit_pf.LongitudeStudyConsent"
                  :label="$t('LTSChallenge.AddPermission.LongitudinalStudy')"
                  simple-label
                  :required="true"
                />
              </div>
              <!-- Medical & Allergy -->
              <b-form-group
                id="medical-group"
                :label="$t('LTSChallenge.AddPermission.MedicalConditions')"
                label-for="medical"
              >
                <b-form-textarea
                  id="medical"
                  class="binput"
                  v-model="edit_pf.MedicalConditions"
                />
              </b-form-group>
              <file-input
                :label="$t('LTSChallenge.AddPermission.File')"
                class="mt-2"
                v-model="file"
              />
            </b-form>
          </validation-observer>
          <template #modal-footer>
            <b-button @click="cancelAddPF">{{ $t("Cancel") }}</b-button>
            <b-button variant="primary" @click="addPFOk">{{
              $t("Ok")
            }}</b-button>
          </template>
        </b-modal>
      </b-card>
    </div>
  </b-container>
</template>

<script lang="ts">
import ChallengeApiService from "@/services/ChallengeApiService";
import SubmissionApiService from "@/services/SubmissionApiService";
import { Component, Vue } from "vue-property-decorator";
import { ValidationObserver, ValidationProvider } from "vee-validate";
import { BIcon, BvModalEvent } from "bootstrap-vue";
import Team from "@/types/challenge/Team";
import PermissionForm from "@/types/challenge/PermissionForm";
import LTSCEvent, {
  EventDeliveryModes,
  EventLanguages,
} from "@/types/challenge/LTSCEvent";
import TeamMember from "@/types/challenge/TeamMember";
import LTSCRegistration, {
  RegistrationStatus,
} from "@/types/challenge/LTSCRegistration";
import YesNoRadioOptions from "@/components/questions/YesNoRadioOptions.vue";
import FileInput from "@/components/inputs/FileInput.vue";
import FileApiService from "@/services/FileApiService";

@Component({
  components: {
    ValidationObserver,
    ValidationProvider,
    BIcon,
    YesNoRadioOptions,
    FileInput,
  },
})
export default class LTSChallengeSubmission extends Vue {
  submissionApiService: SubmissionApiService = new SubmissionApiService();
  submission: any = {};

  challengeApiService: ChallengeApiService = new ChallengeApiService();
  events: LTSCEvent[] = [];
  registration: LTSCRegistration = new LTSCRegistration();
  teams: Team[] = [];

  permission_forms: PermissionForm[] = [];
  adding_pf = false;
  edit_pf: PermissionForm = new PermissionForm();

  adding_tm = false;
  edit_tm: TeamMember = new TeamMember();
  saving_tm = false;

  newteam_name = "";
  edit_team = new Team();
  editing_team = false;

  loaded = false;

  file: File | null = null;
  fileApiService: FileApiService = new FileApiService();

  get Id(): string {
    return this.$route.params.id || "";
  }

  get RegistrationJson(): any {
    if (!this.submission.RegistrationJson) return {};
    else return JSON.parse(this.submission.RegistrationJson);
  }

  get Event(): LTSCEvent {
    return (
      this.events.find((e) => e.ExternalId === this.RegistrationJson.EventId) ||
      new LTSCEvent()
    );
  }

  get EventLanguage(): string {
    return EventLanguages[this.Event.Language];
  }

  get RegistrationStatus(): string {
    return RegistrationStatus[this.registration.Status];
  }

  get PendingStatus(): RegistrationStatus {
    return RegistrationStatus.PendingConfirmation;
  }

  get PermissionsUrl(): string {
    return `${window.location.origin}/LTSChallengePermission/${this.registration.Id}`;
  }

  get PermissionFormOptions(): any[] {
    const assigned_pf_ids = this.teams.flatMap((t: Team) => {
      return t.TeamMembers.filter((tm) => tm.PermissionFormId).map(
        (tm) => tm.PermissionFormId
      );
    });

    return this.permission_forms
      .filter((pf) => !assigned_pf_ids.includes(pf.Id))
      .map((pf) => {
        return {
          text: `${pf.StudentFirstName} ${pf.StudentLastName}`,
          value: pf.Id,
        };
      });
  }

  get CanAddTeamMembers(): boolean {
    let team_members = 0;
    this.teams.forEach((t: Team) => {
      team_members += t.TeamMembers?.length || 0;
    });

    return team_members < this.RegistrationJson.NumStudents;
  }

  get IsVirtual(): boolean {
    return this.Event.DeliveryMode === EventDeliveryModes.Virtual;
  }

  get HidePermissionForms(): boolean {
    return (
      this.IsVirtual ||
      !this.Event.EventDate ||
      this.registration.Status === RegistrationStatus.Waitlisted
    );
  }

  mounted() {
    this.challengeApiService
      .All([
        this.challengeApiService.GetEvents(),
        this.challengeApiService.GetRegistrationById(this.Id),
      ])
      .then((resps) => {
        this.events = resps[0].Value;
        this.registration = resps[1].Value;

        this.submissionApiService
          .GetSubmissionById(this.registration.CentralRegistrationId)
          .then((subresp) => {
            this.submission = subresp.Value;
            this.loaded = true;
          });

        this.getTeams();
        this.getPermissionForms();
      });
  }

  getTeams() {
    this.challengeApiService
      .GetTeamsByRegistrationId(this.registration.Id)
      .then((resp) => {
        if (resp.Success) {
          this.teams = resp.Value;
        }
      });
  }

  getPermissionForms() {
    this.challengeApiService
      .GetPermissionFormsByRegistrationId(this.registration.Id)
      .then((resp) => {
        if (resp.Success) {
          this.permission_forms = resp.Value;
        }
      });
  }

  onAddTeamClick() {
    if (this.newteam_name.length > 0 && this.CanAddTeamMembers) {
      const t = new Team();
      t.RegistrationId = this.registration.Id;
      t.Name = this.newteam_name;

      this.challengeApiService.CreateTeam(t).then((resp) => {
        if (resp.Success) {
          t.Id = resp.Value;
          this.teams.push(t);
          this.newteam_name = "";
        }
      });
    }
  }

  onEditTeamClicked(t: Team) {
    this.edit_team = JSON.parse(JSON.stringify(t));
    this.editing_team = true;
  }

  onEditTeamCancelled() {
    this.editing_team = false;
    this.edit_team = new Team();
  }

  onEditTeamComplete() {
    if (this.edit_team && this.edit_team.Id && this.edit_team.Name.length > 0) {
      this.challengeApiService
        .UpdateTeam(this.edit_team)
        .then((resp) => {
          if (resp.Success) {
            const t = this.teams.find((t) => t.Id === this.edit_team.Id);
            if (t) {
              t.Name = this.edit_team.Name;
            }
          } else {
            console.log(resp);
          }
        })
        .finally(() => {
          this.edit_team = new Team();
          this.editing_team = false;
        });
    }
  }

  onAddTeamMemberClicked(t: Team) {
    if (this.CanAddTeamMembers) {
      this.edit_tm = new TeamMember();
      this.edit_tm.TeamId = t.Id;
      this.adding_tm = true;
    }
  }

  onDeleteTeamClicked(t: Team) {
    if (confirm("Delete Team?")) {
      this.challengeApiService.DeleteTeam(t.Id).then((resp) => {
        if (resp.Success) {
          this.teams.splice(this.teams.indexOf(t), 1);
        }
      });
    }
  }

  onUpdateTeamMemberPermissionForm(member: TeamMember) {
    this.challengeApiService.UpdateTeamMember(member).then((resp) => {
      if (!resp.Success) {
        console.log(resp);
      }
    });
  }

  onDeleteTeamMemberClicked(member: TeamMember) {
    this.challengeApiService.DeleteTeamMember(member).then((resp) => {
      if (resp.Success) {
        const t: Team | undefined = this.teams.find(
          (x) => x.Id === member.TeamId
        );
        if (t) {
          t.TeamMembers.splice(t.TeamMembers.indexOf(member), 1);
        }
      } else {
        console.log(resp);
      }
    });
  }

  addTMOk(modalEvent: BvModalEvent) {
    modalEvent.preventDefault();
    (this.$refs.tm_validator as any).validate().then((success: boolean) => {
      if (success && !this.saving_tm) {
        this.saving_tm = true;
        this.challengeApiService
          .CreateTeamMember(this.edit_tm)
          .then((resp) => {
            if (resp.Success) {
              this.adding_tm = false;
              this.edit_tm.Id = resp.Value;
              const t: Team | undefined = this.teams.find(
                (x) => x.Id === this.edit_tm.TeamId
              );
              if (t) {
                t.TeamMembers.push(JSON.parse(JSON.stringify(this.edit_tm)));
              }
            }
          })
          .finally(() => {
            this.saving_tm = false;
          });
      }
    });
  }

  cancelAddTM() {
    this.adding_tm = false;
    this.edit_tm = new TeamMember();
  }

  onAddPFClicked() {
    this.edit_pf = new PermissionForm();
    this.edit_pf.RegistrationId = this.registration.Id;
    this.edit_pf.Id = "00000000-0000-0000-0000-000000000000";
    this.edit_pf.Created = new Date().toDateString();
    this.edit_pf.Modified = this.edit_pf.Created;
    this.edit_pf.DateSigned = new Date().toDateString();
    this.adding_pf = true;
  }

  addPFOk(modalEvent: BvModalEvent) {
    modalEvent.preventDefault();
    (this.$refs.pf_validator as any).validate().then((success: boolean) => {
      if (success) {
        if (this.file) {
          this.fileApiService.UploadFile(this.file).then((resp) => {
            if (resp.Value) {
              this.edit_pf.AttachmentId = resp.Value;
              this.savePermissionForm();
            } else {
              console.log(resp);
            }
          });
        } else {
          this.savePermissionForm();
        }
      }
    });
  }

  savePermissionForm() {
    this.challengeApiService.CreatePermissionForm(this.edit_pf).then((resp) => {
      if (resp.Success) {
        this.edit_pf.Id = resp.Value;
        this.permission_forms.push(JSON.parse(JSON.stringify(this.edit_pf)));
        this.adding_pf = false;
      }
    });
  }

  cancelAddPF() {
    this.adding_pf = false;
    this.edit_pf = new PermissionForm();
  }

  onUpdatePendingRegistration(accept: boolean) {
    const to_update: LTSCRegistration = JSON.parse(
      JSON.stringify(this.registration)
    );

    to_update.Status = accept
      ? RegistrationStatus.Attending
      : RegistrationStatus.Declined;

    this.challengeApiService.UpdateRegistration(to_update).then((resp) => {
      if (resp.Success) {
        this.registration.Status = to_update.Status;
      }
    });
  }
}
</script>
<style scoped>
table,
th,
td {
  border: 1px solid black;
  padding: 10px;
  margin-top: 10px;
}

thead {
  background-color: #0053a1;
  color: white;
}

.no-data-text {
  color: lightgray;
}

.thin-hr {
  margin: 10px;
}
</style>
