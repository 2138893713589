<template>
  <b-container fluid="xl">
    <div v-if="!submissionComplete">
      <h1 class="h2">{{ $t(`${Form}.Header`) }}</h1>
      <p>
        {{ $t(`${Form}.Intro.Text`) }}
      </p>

      <div
        id="cohort_webinars"
        v-if="cohortWebinars.length > 0 && elementVisible('cohort_webinars')"
      >
        <!-- webinar table -->
        <table id="webinar_table" v-if="elementVisible('webinar_table')">
          <thead>
            <tr>
              <th>{{ $t(`${Form}.Labels.Cohort`) }}</th>
              <th>{{ $t(`${Form}.Labels.StartDate`) }}</th>
              <th>{{ $t(`${Form}.Labels.EndDate`) }}</th>
              <th>{{ $t(`${Form}.Labels.Language`) }}</th>
              <th>{{ $t(`${Form}.Labels.DateAndTime`) }}</th>
              <th>{{ $t(`${Form}.Labels.MaterialsAvailable`) }}</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(cw, idx) in cohortWebinars" :key="idx">
              <td>{{ $t(`${Form}.Labels.Cohort`) }} {{ cw.cohort_id }}</td>
              <td>{{ cw.start_date }}</td>
              <td>{{ cw.end_date }}</td>
              <td>{{ $t(`${Form}.Labels.${cw.language}`) }}</td>
              <td>{{ cw.webinar_display }}</td>
              <td>
                <template v-if="cw.materials_available">
                  {{ $t(`${Form}.Labels.Yes`) }}
                </template>
                <template v-else>
                  {{ $t(`${Form}.Labels.No`) }}
                </template>
              </td>
            </tr>
          </tbody>
        </table>

        <hr />
        <cohort
          id="cohort"
          v-if="elementVisible('cohort')"
          v-model="model.CohortId"
          :options="cohortOptions"
          @input="() => (model.WebinarId = undefined)"
        />
        <language
          id="cohort_lang"
          v-if="elementVisible('cohort_lang')"
          v-model="model.CohortLanguage"
          hide-label
          @input="() => (model.WebinarId = undefined)"
        />
        <!-- Select Date and time -->
        <div id="webinar" v-if="elementVisible('webinar')">
          <validation-provider
            v-slot="{ errors, valid }"
            name="Webinar"
            :rules="{ required: true }"
          >
            <b-form-group
              id="webinar-group"
              :label="$t(`${Form}.Questions.Webinar`)"
              label-for="webinar"
              class="required-field"
            >
              <b-form-select
                id="webinar-select"
                class="binput"
                v-model="model.WebinarId"
                :options="webinarOptions"
                :state="errors[0] ? false : valid ? true : null"
              />
            </b-form-group>
            <b-form-invalid-feedback :state="valid">
              <span v-for="(error, index) in errors" :key="index">{{
                $t(error)
              }}</span>
            </b-form-invalid-feedback>
          </validation-provider>
        </div>
      </div>

      <validation-observer
        v-slot="{ handleSubmit }"
        v-if="model.WebinarId || cohortWebinars.length === 0"
      >
        <b-form @submit.prevent="handleSubmit(onSubmit)">
          <!-- School / Organization Information -->
          <div>
            <your-school
              id="your_school"
              v-if="elementVisible('your_school')"
              v-model="model.School"
            />
          </div>

          <!-- Participation Information  -->
          <div id="classes" v-if="elementVisible('classes')">
            <h2 class="h5">
              {{ $t(`${Form}.Questions.Classes.Label`) }}
            </h2>
            <validation-provider
              v-slot="{ errors, valid }"
              name="classcollection"
              :rules="{ required: true, min: 1 }"
            >
              <class-collection
                ref="classCollection"
                id="classcollection"
                v-model="model.Classes"
                description-values
              />
              <b-form-invalid-feedback :state="valid">
                <span v-for="(error, index) in errors" :key="index">{{
                  $t(error)
                }}</span>
              </b-form-invalid-feedback>
            </validation-provider>
          </div>

          <!-- Additional Information -->
          <div id="additional_info" v-if="elementVisible('additional_info')">
            <h2 class="h5">
              {{ $t(`${Form}.Labels.AdditionalInformation`) }}
            </h2>

            <language
              id="language"
              v-if="elementVisible('language')"
              :label="$t(`${Form}.Questions.Language`)"
              v-model="model.Language"
            />

            <number-of-adults
              id="num_adults"
              v-if="elementVisible('num_adults')"
              v-model="model.NumAdults"
              allow-zero
              :label="$t(`${Form}.Questions.NumAdults`)"
            />

            <learned-about-project
              id="learned_about"
              v-if="elementVisible('learned_about')"
              :label="$t(`${Form}.Questions.HeardAboutProgram`)"
              v-model="model.LearnedAbout"
            />

            <mailing-list
              id="mailing_list"
              v-if="elementVisible('mailing_list')"
              v-model="model.MailingList"
            />

            <role
              id="role"
              v-if="elementVisible('role')"
              v-model="model.Role"
            />
          </div>

          <div id="other_info" v-if="elementVisible('other_info')">
            <p class="question" id="other-info-q">
              {{ $t(`${Form}.Questions.OtherInfo`) }}
            </p>
            <b-form-group id="other-info-group">
              <b-form-textarea
                id="other-info-ta"
                class="binput"
                style="min-width: 500px"
                v-model="model.OtherInfo"
              />
            </b-form-group>
          </div>

          <!-- Material Request -->
          <div
            id="materials_request"
            v-if="materialsAvailable && elementVisible('materials_request')"
          >
            <h2 class="h5">
              {{ $t(`${Form}.MaterialRequest.Header`) }}
            </h2>

            <p class="phelp">
              {{ $t(`${Form}.MaterialRequest.SubHeader`) }}
            </p>

            <yes-no-radio-options
              id="request_materials"
              v-if="elementVisible('request_materials')"
              v-model="model.Materials.RequestsMaterials"
              :label="$t(`${Form}.MaterialRequest.Questions.RequestMaterials`)"
              :required="true"
              name="RequestMaterial"
              @input="onRequestsMaterialsChanged"
            />

            <div
              id="relevant_fields"
              v-if="
                model.Materials.RequestsMaterials &&
                elementVisible('relevant_fields')
              "
            >
              <div id="benefit" v-if="elementVisible('benefit')">
                <validation-provider
                  v-slot="{ errors, valid }"
                  name="benefit-vp"
                  :rules="{ required: true }"
                >
                  <b-form-group
                    :label="$t(`${Form}.MaterialRequest.Questions.Benefit`)"
                    label-for="benefit-ta"
                    class="required-field question"
                  >
                    <b-form-textarea
                      id="benefit-ta"
                      v-model="model.Materials.Benefit"
                      style="min-width: 500px"
                    />
                    <b-form-invalid-feedback :state="valid">
                      <span v-for="(error, index) in errors" :key="index">{{
                        $t(error)
                      }}</span>
                    </b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </div>

              <yes-no-radio-options
                id="rural_remote_northern"
                v-if="elementVisible('rural_remote_northern')"
                :label="
                  $t(`${Form}.MaterialRequest.Questions.RuralRemoteNorthern`)
                "
                name="RuralRemoteNorthern"
                :required="true"
                v-model="model.Materials.RuralRemoteNorthern"
              />

              <div
                id="participation_rating"
                v-if="elementVisible('participation_rating')"
              >
                <validation-provider
                  v-slot="{ errors, valid }"
                  name="ParticipationRating-vp"
                  :rules="{ required: true }"
                >
                  <b-form-group
                    id="pr-group"
                    :label="
                      $t(
                        `${Form}.MaterialRequest.Questions.ParticipationRating`
                      )
                    "
                    label-for="pr_select"
                    class="required-field"
                    style="font-weight: bold"
                  >
                    <b-form-select
                      id="pr_select"
                      class="binput"
                      v-model="model.Materials.ParticipationRating"
                      :options="participationRatings"
                      :state="errors[0] ? false : valid ? true : null"
                    />
                  </b-form-group>
                  <b-form-invalid-feedback :state="valid">
                    <span v-for="(error, index) in errors" :key="index">{{
                      $t(error)
                    }}</span>
                  </b-form-invalid-feedback>
                </validation-provider>
              </div>

              <yes-no-radio-options
                id="socio_economic_youth"
                v-if="elementVisible('socio_economic_youth')"
                :label="
                  $t(`${Form}.MaterialRequest.Questions.SocioEconomicYouth`)
                "
                name="SocioEconomicYouth"
                :required="true"
                v-model="model.Materials.SocioEconomicYouth"
              />

              <div id="num_kits" v-if="elementVisible('num_kits')">
                <validation-provider
                  v-slot="{ errors, valid }"
                  name="kitsRequesting"
                  :rules="{
                    required: true,
                    min_value: 1,
                    numeric: true,
                  }"
                >
                  <b-form-group
                    id="num_kits-group"
                    :label="
                      $t(`${Form}.MaterialRequest.Questions.NumberOfKits`)
                    "
                    label-for="num_kits"
                    class="required-field"
                    style="font-weight: bold"
                  >
                    <b-form-input
                      type="number"
                      id="Num_kits"
                      v-model="model.Materials.NumberOfKits"
                      :state="errors[0] ? false : valid ? true : null"
                    />
                  </b-form-group>
                  <b-form-invalid-feedback :state="valid">
                    <span v-for="(error, index) in errors" :key="index">{{
                      $t(error)
                    }}</span>
                  </b-form-invalid-feedback>
                  <p class="text-muted" style="font-style: italic">
                    {{
                      $t(`${Form}.MaterialRequest.Questions.KitAvailability`)
                    }}
                  </p>
                </validation-provider>
              </div>

              <!-- Shipping Details -->
              <canada-post-shipping-address
                id="cp_shipping"
                v-if="elementVisible('cp_shipping')"
                v-model="model.ShippingAddress"
              />
            </div>
          </div>

          <hr />
          <terms-and-conditions
            id="terms"
            v-if="elementVisible('terms')"
            v-model="AcceptsTerms"
          />

          <b-button
            id="btn_submit"
            type="submit"
            class="mt-2"
            variant="primary"
            :disabled="!AcceptsTerms || saving"
            >{{ $t("Submit") }}</b-button
          >
        </b-form>
      </validation-observer>
    </div>
    <div v-else>
      <b-container id="confirmationheader">
        <h3>{{ $t(`${Form}.Confirmation.Header`) }}</h3>
      </b-container>
      <hr />
      <p id="confirmationsub">
        <strong>
          {{ $t(`${Form}.Confirmation.SubHeader`) }}
        </strong>
      </p>
      <p id="confirmationbody" v-html="$t(`${Form}.Confirmation.Body`)" />
    </div>
  </b-container>
</template>

<script lang="ts">
import { Component } from "vue-property-decorator";
import { extend, ValidationProvider, ValidationObserver } from "vee-validate";
import { min, numeric, min_value } from "vee-validate/dist/rules";
import YourSchool from "@/components/questions/YourSchool.vue";
import FormBase from "@/types/FormBase";
import Language from "@/components/questions/Language.vue";
import Cohort from "@/components/questions/Cohort.vue";
import ClassCollection from "@/components/ClassCollection.vue";
import NumberOfAdults from "@/components/questions/NumberOfAdults.vue";
import LearnedAboutProject from "@/components/questions/LearnedAboutProject.vue";
import MailingList from "@/components/questions/MailingList.vue";
import TermsAndConditions from "@/components/questions/TermsAndConditions.vue";
import YesNoRadioOptions from "@/components/questions/YesNoRadioOptions.vue";
import CanadaPostShippingAddress from "@/components/CanadaPostShippingAddress.vue";
import Role from "@/components/questions/Role.vue";
import i18n from "@/i18n";

extend("min", {
  ...min,
  message: "Validation.MinLength",
});

extend("min_value", {
  ...min_value,
  message: "Validation.ProvideNumericGreaterThanZero",
});

extend("numeric", {
  ...numeric,
  message: "Validation.Numeric",
});

/* Give cohorts a name, render un-affected in the table */

@Component({
  components: {
    ValidationProvider,
    Language,
    Cohort,
    ValidationObserver,
    YourSchool,
    ClassCollection,
    NumberOfAdults,
    LearnedAboutProject,
    MailingList,
    TermsAndConditions,
    YesNoRadioOptions,
    CanadaPostShippingAddress,
    Role,
  },
})
export default class YVEDelivery extends FormBase {
  model: any = {
    Materials: {},
    ShippingAddress: {},
    WebinarId: null,
  };

  dateTime = "";
  AcceptsTerms = false;

  get Form(): string {
    return this.$route.name || "";
  }

  get Instance(): string | null {
    return this.$route.params.instance;
  }

  mounted() {
    this.GetFormData().then(() => {
      this.setModelDefaults();
    });
  }

  elementVisible(id: string): boolean {
    const hidden_fields = this.Metadata.hidden_fields ?? [];
    return !hidden_fields.includes(id);
  }

  setModelDefaults() {
    const md = this.Metadata.model_defaults;
    if (md) {
      this.model = {
        ...this.model,
        ...md,
      };
    }
  }

  get participationRatings(): Array<any> {
    return [
      {
        text: this.$root.$t(`${this.Form}.Questions.Ratings.Unable`),
        value: "Unable",
      },
      {
        text: this.$root.$t(`${this.Form}.Questions.Ratings.VeryUnlikely`),
        value: "VeryUnlikely",
      },
      {
        text: this.$root.$t(`${this.Form}.Questions.Ratings.SomewhatUnlikely`),
        value: "SomewhatUnlikely",
      },
      {
        text: this.$root.$t(`${this.Form}.Questions.Ratings.SomewhatLikely`),
        value: "SomewhatLikely",
      },
      {
        text: this.$root.$t(`${this.Form}.Questions.Ratings.Likely`),
        value: "Likely",
      },
    ];
  }

  get materialsAvailable(): boolean {
    if (this.model.CohortId && this.Metadata.cohorts) {
      const c = this.Metadata.cohorts.find(
        (c: any) => c.id === this.model.CohortId
      );

      return c?.materials_available ?? false;
    } else {
      return this.Metadata.materials_available ?? false;
    }
  }

  get cohortOptions(): Array<object> {
    if (this.Metadata.cohorts) {
      return this.Metadata.cohorts.map((c: any) => {
        return {
          text: `${this.$t(`${this.Form}.Labels.Cohort`)} ${c.id}`,
          value: c.id,
        };
      });
    } else {
      return [];
    }
  }

  get webinarOptions(): Array<object> {
    if (this.Metadata.cohorts) {
      const c: any = this.Metadata.cohorts.find(
        (c: any) => c.id === this.model.CohortId
      );
      if (c && c.webinars) {
        return c.webinars
          .filter((w: any) => w.language === this.model.CohortLanguage)
          .map((w: any) => {
            return {
              text: w.display[i18n.locale],
              value: w.id,
            };
          });
      }
    }

    return [];
  }

  get cohortWebinars(): Array<any> {
    if (this.Metadata.cohorts) {
      return this.Metadata.cohorts
        .map((c: any) => {
          return c.webinars.map((w: any) => {
            return {
              cohort_id: c.id,
              start_date: c.start_date[i18n.locale],
              end_date: c.end_date[i18n.locale],
              materials_available: c.materials_available,
              webinar_id: w.id,
              language: w.language,
              webinar_display: w.display[i18n.locale],
            };
          });
        })
        .flat();
    }
    return [];
  }

  onRequestsMaterialsChanged(newValue: boolean) {
    if (!newValue) {
      this.model.Materials = {};
      this.model.ShippingAddress = {};
      this.model.Materials.RequestsMaterials = newValue;
    }
  }

  onSubmit() {
    const classCollectionValid = (
      this.$refs.classCollection as ClassCollection
    ).validate();

    if (classCollectionValid === true) {
      this.SaveSubmission(this.model);
    }
  }
}
</script>

<style scoped>
hr {
  background-color: rgb(145, 145, 145);
}

.question {
  padding-top: 20px;
  font-weight: bold;
}

table,
th,
td {
  border: 1px solid black;
  padding: 10px;
  margin-top: 10px;
}

thead {
  background-color: #0053a1;
  color: white;
}
</style>
