<template>
  <b-container fluid="xl"> unauthorized </b-container>
</template>

<script lang="ts">
import { Component, Vue } from "vue-property-decorator";

@Component
export default class Unauthorized extends Vue {}
</script>
