<template>
  <b-container fluid="xl">
    <h2>Seed Groups</h2>
    <b-form class="mb-4" inline @submit.prevent>
      <b-form-input
        class="mr-2"
        placeholder="Treated Label"
        v-model="newSeedGroup.TreatedLabel"
      />
      <b-form-input
        class="mr-2"
        placeholder="Untreated Label"
        v-model="newSeedGroup.UntreatedLabel"
      />
      <b-form-checkbox
        class="mr-2"
        placeholder="Is Active"
        v-model="newSeedGroup.IsActive"
      >
        Is Active
      </b-form-checkbox>
      <b-button
        class="mr-2"
        variant="primary"
        size="sm"
        @click="onAddSeedGroupClicked"
        >Add</b-button
      >
    </b-form>
    <b-table striped :fields="fields" :items="seedGroups">
      <template #cell(IsActive)="item">
        <b-checkbox disabled v-model="item.item.IsActive" />
      </template>
      <template #cell(Actions)="item">
        <b-icon-pencil
          class="h4 ml-2"
          v-b-tooltip.hover
          :title="$t('Edit')"
          @click="onEditSeedGroupClicked(item.item)"
        />
        <b-icon-trash
          class="h4 ml-2"
          v-b-tooltip.hover
          :title="$t('Delete')"
          @click="onDeleteSeedGroupClicked(item.item)"
        />
      </template>
    </b-table>
    <b-modal v-model="showEditModal" @ok="onEditOk">
      <template #modal-title>Edit Seed Group</template>
      <b-form @submit.prevent>
        <b-form-group label="Treated Label" label-for="treated-label">
          <b-form-input
            id="treated-label"
            v-model="editSeedGroup.TreatedLabel"
          />
        </b-form-group>
        <b-form-group label="Untreated Label" label-for="untreated-label">
          <b-form-input v-model="editSeedGroup.UntreatedLabel" />
        </b-form-group>
        <b-form-checkbox class="mt-4 ml-1" v-model="editSeedGroup.IsActive">
          Is Active
        </b-form-checkbox>
      </b-form>
    </b-modal>
  </b-container>
</template>

<script lang="ts">
import TomatosphereApiService from "@/services/TomatosphereApiService";
import { Component, Vue } from "vue-property-decorator";

@Component
export default class TomatosphereSeedGroups extends Vue {
  seedGroups: any[] = [];

  newSeedGroup: any = {};
  editSeedGroup: any = {};
  showEditModal = false;

  tsApiService: TomatosphereApiService = new TomatosphereApiService();

  fields: object[] = [
    {
      key: "TreatedLabel",
      label: "Treated Label",
    },
    {
      key: "UntreatedLabel",
      label: "Untreated Label",
    },
    {
      key: "IsActive",
      label: "Is Active",
    },
    {
      key: "Actions",
      label: "",
    },
  ];

  mounted() {
    this.getData();
  }

  getData() {
    this.tsApiService.GetSeedGroups().then((resp) => {
      if (resp.Success) {
        this.seedGroups = resp.Value;
      }
    });
  }

  onAddSeedGroupClicked() {
    this.tsApiService.CreateSeedGroup(this.newSeedGroup).then((resp) => {
      if (resp.Success) {
        this.getData();
        this.newSeedGroup = {};
      }
    });
  }

  onDeleteSeedGroupClicked(item: any) {
    if (confirm("Delete Seed Group?")) {
      this.tsApiService.DeleteSeedGroup(item.Id).then((resp) => {
        if (resp.Success) {
          this.getData();
        }
      });
    }
  }

  onEditSeedGroupClicked(item: object) {
    this.editSeedGroup = JSON.parse(JSON.stringify(item));
    this.showEditModal = true;
  }

  onEditOk() {
    this.tsApiService.UpdateSeedGroup(this.editSeedGroup).then((resp) => {
      if (resp.Success) {
        this.getData();
        this.editSeedGroup = {};
      }
    });
  }
}
</script>
