import axios from "axios";
import BaseApiService from "./BaseApiService";

export default class ReportApiService extends BaseApiService {
  // eslint-disable-next-line
  async RunReport(reportName: string, params: any): Promise<any> {    
    const fileName = reportName;
    await this.defineHeader();
    return axios
      .post(`${this.url}/api/registration/Report/${reportName}`, params, {
        responseType: "blob",
      })
      .then((response) => {
        console.log(response);
        if (response && response.data) {
          const url = window.URL.createObjectURL(
            new Blob([response.data as any])
          );
          const link = document.createElement("a");

          link.href = url;
          link.setAttribute("download", `${fileName}.xlsx`); //or any other extension
          document.body.appendChild(link);
          link.click();
          return true;
        } else {
          return false;
        }
      });
  }
}
