import { Vue } from "vue-property-decorator";
import SubmissionApiService from "@/services/SubmissionApiService";
import RouteDataApiService from "@/services/RouteDataApiService";
import AuthorizationService from "@/services/AuthorizationService";

export default abstract class FormBase extends Vue {
  submissionApiService: SubmissionApiService = new SubmissionApiService();
  routeDataApiService: RouteDataApiService = new RouteDataApiService();
  authorizationService: AuthorizationService =
    AuthorizationService.getInstance();

  get submissionComplete(): boolean {
    if (this.show_confirmation) {
      return true;
    } else {
      return this.submission_complete;
    }
  }

  set submissionComplete(value: boolean) {
    this.submission_complete = value;
  }

  private show_confirmation = false;
  private submission_complete = false;
  saving = false;
  Data: any = {};
  DataLoaded = false;

  //abstract
  abstract get Form(): string;
  abstract get Instance(): string | null;

  get Metadata(): any {
    return this.Data.FormMetadata ?? {};
  }

  async GetFormData(): Promise<object> {
    const rd = await this.routeDataApiService.GetRouteData(
      this.Form,
      this.Instance
    );

    this.Data = rd.Value;
    this.DataLoaded = true;

    if (!this.Data.FormId || this.Data.InstanceActive === false) {
      this.GoToFormClosed();
    }

    this.showConfirmation();

    return this.Data;
  }

  showConfirmation() {
    if (
      this.authorizationService.hasRole(["staff"]) &&
      this.$route.query.showConfirmation === "true"
    ) {
      this.show_confirmation = true;
    }
  }

  GoToFormClosed() {
    this.$router.push("/FormClosed");
  }

  async SaveSubmission(model: object): Promise<string> {
    this.saving = true;
    let ret = "";

    try {
      const ucr = await this.submissionApiService.CreateSubmission(
        this.Form,
        this.Instance,
        model
      );

      if (ucr.Success) {
        ret = ucr.Value;
        this.SubmissionComplete();
      } else {
        console.log(ucr);
      }
    } catch (err) {
      console.log("an exception was thrown saving submission: ");
      console.log(err);
    }

    this.saving = false;
    return ret;
  }

  SubmissionComplete() {
    this.submissionComplete = true;
    window.dispatchEvent(new Event("ltscr_form_submit"));
  }
}
