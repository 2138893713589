<template>
  <b-container fluid="xl">
    <h2>Enumerations</h2>

    <div v-for="(e, idx) in enums" :key="idx">
      <h4>{{ e.Name }}</h4>
      <b-input-group style="max-width: 500px">
        <b-form-input v-model="e.NewValue" @keydown.enter="create(e)" />
        <b-input-group-append>
          <b-button variant="primary" @click="create(e)">Add</b-button>
        </b-input-group-append>
      </b-input-group>
      <b-table striped :fields="fields" :items="e.Values">
        <template #cell(Actions)="enumItem">
          <b-icon-trash
            class="h4 ml-2"
            v-b-tooltip.hover
            title="Delete"
            @click="deleteEnumeration(enumItem.item, e)"
          />
        </template>
      </b-table>
      <hr />
    </div>
  </b-container>
</template>

<script lang="ts">
import EventNameApiService from "@/services/enumerations/EventNameApiService";
import EventTypeApiService from "@/services/enumerations/EventTypeApiService";
import ProgramApiService from "@/services/enumerations/ProgramApiService";
import IEnumApiService from "@/services/enumerations/IEnumApiService";
import { Component, Vue } from "vue-property-decorator";

class CREnum {
  Name: string;
  ApiService: IEnumApiService;
  Values: object[] = [];
  NewValue = "";

  constructor(n: string, s: IEnumApiService) {
    this.Name = n;
    this.ApiService = s;
  }

  Load() {
    this.ApiService.GetAll().then((resp: any) => {
      this.Values = resp.Value;
    });
  }

  SaveNew() {
    this.ApiService.Create(this.NewValue).then((resp: any) => {
      if (resp.Success) {
        this.Load();
        this.NewValue = "";
      }
    });
  }

  Delete(id: string) {
    this.ApiService.Delete(id).then((resp: any) => {
      if (resp.Success) {
        this.Load();
        this.NewValue = "";
      }
    });
  }
}

@Component
export default class EnumerationsAdmin extends Vue {
  newEnumValue: string[] = [];

  enums: CREnum[] = [
    new CREnum("Event Names", new EventNameApiService()),
    new CREnum("Event Types", new EventTypeApiService()),
    new CREnum("Programs", new ProgramApiService()),
  ];

  fields: object[] = [
    {
      key: "Name",
      label: "Value",
    },
    {
      key: "Actions",
      label: "",
    },
  ];

  mounted() {
    this.enums.forEach((e: CREnum) => {
      e.Load();
    });
  }

  create(e: CREnum) {
    e.SaveNew();
  }

  deleteEnumeration(item: any, e: CREnum) {
    e.Delete(item.Id);
  }
}
</script>
