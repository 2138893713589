import Subject from "@/types/Subject";

export default class AuthorizationService {
  private static instance: AuthorizationService;
  private readonly subject: Subject;
  //private subjectRoles: string[];

  public static init(subject: Subject) {
    if (!AuthorizationService.instance) {
      AuthorizationService.instance = new AuthorizationService(subject);
    }
  }

  public static getInstance(): AuthorizationService {
    return AuthorizationService.instance;
  }

  private constructor(s: Subject) {
    this.subject = s;
  }

  public get SubjectId(): string {
    return this.subject.SubjectId;
  }

  public get GivenName(): string {
    return this.subject.GivenName;
  }

  public get FamilyName(): string {
    return this.subject.FamilyName;
  }

  public isAdmin(): boolean {
    const roles = ["administrator"]; //, "Staff"];
    return roles.some((r) => this.subject.Roles.includes(r));
  }

  public hasRole(roles: string[]): boolean {
    return roles.some((r) => this.subject.Roles.includes(r)) || this.isAdmin();
  }
}
