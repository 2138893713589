<template>
  <div :id="id">
    <validation-provider
      v-slot="{ errors, valid }"
      :name="`${id}-vp`"
      :rules="{ required: true }"
    >
      <b-form-group
        :id="`${id}-group`"
        :label="Label"
        :label-for="`${id}-select`"
        class="required-field"
      >
        <b-form-select
          :id="`${id}-select`"
          class="binput"
          v-model="cohort"
          :options="Options"
          :state="errors[0] ? false : valid ? true : null"
          @input="onInput"
        />
      </b-form-group>
      <b-form-invalid-feedback :state="valid">
        <span v-for="(error, index) in errors" :key="index">{{
          $t(error)
        }}</span>
      </b-form-invalid-feedback>
    </validation-provider>
  </div>
</template>

<script lang="ts">
import { Component, Vue, Watch, Prop } from "vue-property-decorator";
import { ValidationProvider } from "vee-validate";

@Component({
  components: {
    ValidationProvider,
  },
})
export default class Cohort extends Vue {
  @Prop(Number) value!: number;
  @Prop(String) id!: string;
  @Prop(String) label!: string;
  @Prop(Array) options!: Array<any>;

  cohort: number | null = this.value || null;

  get Label(): string {
    return this.label || this.$t("Cohort").toString();
  }

  get Options(): Array<any> {
    return this.options;
  }

  @Watch("value")
  onValueChanged() {
    this.cohort = this.value;
  }

  onInput() {
    this.$emit("input", this.cohort);
  }
}
</script>
<style scoped>
.question {
  padding-top: 20px;
  font-weight: bold;
}
</style>
