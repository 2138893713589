<template>
  <b-container fluid="xl">
    <div style="width: 500px; max-width: 100%; margin: 50px auto">
      <b-img
        fluid
        v-if="Locale === 'fr'"
        src="@/assets/living-space/fr-header.png"
        :alt="$t('LivingSpace.BannerAlt')"
      />
      <b-img
        v-else
        fluid
        src="@/assets/living-space/en-header.png"
        :alt="$t('LivingSpace.BannerAlt')"
      />
    </div>

    <h1 class="h2">{{ $t("LivingSpace.Header") }}</h1>

    <div v-if="!submissionComplete">
      <p v-html="$t('LivingSpace.Intro.Text')" />
      <hr />

      <validation-observer v-slot="{ handleSubmit }">
        <b-form @submit.prevent="handleSubmit(onSubmit)">
          <h2 class="h5 required-field">
            {{ $t("LivingSpace.Questions.FirstTimeParticipating") }}
          </h2>

          <validation-provider
            v-slot="{ errors, valid }"
            name="FirstTimeParticipating"
            :rules="{ required: true }"
          >
            <b-form-group>
              <b-form-radio-group
                id="firsttimeparticipating"
                v-model="model.FirstTimeParticipating"
                :options="yesNoOptions"
                stacked
              ></b-form-radio-group>
            </b-form-group>
            <b-form-invalid-feedback :state="valid">
              <span v-for="(error, index) in errors" :key="index">{{
                $t(error)
              }}</span>
            </b-form-invalid-feedback>
          </validation-provider>

          <your-school v-model="model.School" />

          <h2 class="h5">
            {{ $t("ClassesOrGroups.Label") }}
          </h2>
          <validation-provider
            v-slot="{ errors, valid }"
            name="classcollection"
            :rules="{ required: true, min: 1 }"
          >
            <class-collection
              ref="classCollection"
              id="classcollection"
              v-model="model.Classes"
              description-values
            />
            <b-form-invalid-feedback :state="valid">
              <span v-for="(error, index) in errors" :key="index">{{
                $t(error)
              }}</span>
            </b-form-invalid-feedback>
          </validation-provider>

          <h2 class="h5">
            {{ $t("AboutYou") }}
          </h2>

          <number-of-adults v-model="model.NumAdults" />

          <project-context v-model="model.Context" />

          <p class="question">
            {{ $t("LivingSpace.Questions.Language.Text") }}
          </p>

          <validation-provider
            v-slot="{ errors, valid }"
            name="language"
            :rules="{ required: true }"
          >
            <b-form-group
              id="lang-group"
              :label="$t('LivingSpace.Questions.Language.SubText')"
              label-for="language"
              class="required-field"
            >
              <b-form-select
                id="language"
                class="binput"
                v-model="model.Language"
                :options="languageOptions"
                :state="errors[0] ? false : valid ? true : null"
              />
            </b-form-group>
            <b-form-invalid-feedback :state="valid">
              <span v-for="(error, index) in errors" :key="index">{{
                $t(error)
              }}</span>
            </b-form-invalid-feedback>
          </validation-provider>

          <learned-about-project v-model="model.LearnedAbout" />

          <feedback v-model="model.Feedback" />

          <mailing-list id="mailinglist" v-model="model.MailingList" />

          <div v-if="EquipmentRequest">
            <p class="question required-field" id="equipmentQuestion">
              {{ $t("LivingSpace.Questions.RequestEquipment.Text") }}
            </p>
            <validation-provider
              v-slot="{ errors, valid }"
              name="RequestEquipment"
              :rules="{ required: true }"
            >
              <b-form-group>
                <b-form-radio-group
                  id="requestequipment"
                  v-model="model.RequestEquipment"
                  :options="requestEquipmentOptions"
                  stacked
                  aria-describedby="equipmentQuestion"
                ></b-form-radio-group>
              </b-form-group>
              <b-form-invalid-feedback :state="valid">
                <span v-for="(error, index) in errors" :key="index">{{
                  $t(error)
                }}</span>
              </b-form-invalid-feedback>
            </validation-provider>
          </div>

          <template v-if="model.RequestEquipment === false">
            <p class="question" id="collectionMethod">
              {{ $t("LivingSpace.Questions.DataCollectionMethod.Text") }}
            </p>
            <i
              v-html="$t('LivingSpace.Questions.DataCollectionMethod.SubText')"
            />
            <validation-provider
              v-slot="{ errors, valid }"
              name="datacollectionmethod"
              :rules="{ required: true }"
            >
              <b-form-group>
                <b-form-radio-group
                  id="datacollectionmethod"
                  v-model="model.DataCollectionMethod"
                  :options="dataCollectionOptions"
                  stacked
                  aria-describedby="collectionMethod"
                />
              </b-form-group>
              <b-form-invalid-feedback :state="valid">
                <span v-for="(error, index) in errors" :key="index">{{
                  $t(error)
                }}</span>
              </b-form-invalid-feedback>
            </validation-provider>
          </template>

          <template v-if="model.RequestEquipment">
            <p class="question">
              {{ $t("LivingSpace.Questions.ConfirmNotReceivedEquipment.Text") }}
            </p>
            <i
              v-html="
                $t('LivingSpace.Questions.ConfirmNotReceivedEquipment.SubText')
              "
            />

            <validation-provider
              v-slot="{ errors, valid }"
              name="ConfirmNotReceived"
              :rules="{ required: true }"
            >
              <b-form-group>
                <b-form-checkbox
                  id="confirmnotreceived"
                  v-model="model.ConfirmNotReceivedEquipment"
                  style="margin-top: 10px"
                  :unchecked-value="null"
                >
                  <span>{{
                    $t(
                      "LivingSpace.Questions.ConfirmNotReceivedEquipment.Confirmation"
                    )
                  }}</span>
                </b-form-checkbox>
                <b-form-invalid-feedback :state="valid">
                  <span v-for="(error, index) in errors" :key="index">{{
                    $t(error)
                  }}</span>
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>

            <template v-if="model.ConfirmNotReceivedEquipment">
              <p class="question">
                {{ $t("LivingSpace.Questions.EquipmentRequested.Text") }}
              </p>

              <validation-provider
                v-slot="{ errors, valid }"
                name="EquipmentRequested"
                :rules="{ required: true }"
              >
                <b-form-group>
                  <b-form-radio-group
                    id="equipmentrequested"
                    v-model="model.EquipmentRequested"
                    :options="equipmentRequestOptions"
                    stacked
                  ></b-form-radio-group>
                </b-form-group>
                <b-form-invalid-feedback :state="valid">
                  <span v-for="(error, index) in errors" :key="index">{{
                    $t(error)
                  }}</span>
                </b-form-invalid-feedback>
              </validation-provider>

              <canada-post-shipping-address v-model="model.ShippingAddress" />
            </template>
          </template>

          <div style="margin-top: 10px">
            <b-button
              id="btn_submit"
              type="submit"
              variant="primary"
              class="mt-2"
              :disabled="saving"
              >{{ $t("Submit") }}</b-button
            >
          </div>
        </b-form>
      </validation-observer>
    </div>

    <!-- Confirmation -->
    <div v-else>
      <b-container id="confirmationheader">
        <h3>{{ $t("LivingSpace.Confirmation.Header") }}</h3>
      </b-container>
      <hr />
      <p id="confirmationsub">
        <strong>
          {{ $t("LivingSpace.Confirmation.SubHeader") }}
        </strong>
      </p>
      <p id="confirmationbody" v-html="$t('LivingSpace.Confirmation.Body')" />
      <b-button
        id="confirmationbtncontainer"
        variant="primary"
        :href="$t('LivingSpace.Confirmation.ButtonLink')"
        size="lg"
      >
        {{ $t("LivingSpace.Confirmation.Button") }}
      </b-button>
    </div>
  </b-container>
</template>

<script lang="ts">
import { Component } from "vue-property-decorator";
import { extend, ValidationProvider, ValidationObserver } from "vee-validate";
import { min, numeric, min_value } from "vee-validate/dist/rules";
import ClassCollection from "@/components/ClassCollection.vue";
import ProjectContext from "@/components/questions/ProjectContext.vue";
import LearnedAboutProject from "@/components/questions/LearnedAboutProject.vue";
import OrganizationApiService from "@/services/OrganizationApiService";
import OrgRegionSelect from "@/components/school/OrgRegionSelect.vue";
import FormBase from "@/types/FormBase";
import Feedback from "@/components/questions/Feedback.vue";
import CanadaPostShippingAddress from "@/components/CanadaPostShippingAddress.vue";
import YourSchool from "@/components/questions/YourSchool.vue";
import NumberOfAdults from "@/components/questions/NumberOfAdults.vue";
import MailingList from "@/components/questions/MailingList.vue";

extend("min", {
  ...min,
  message: "Validation.MinLength",
});

extend("min_value", {
  ...min_value,
  message: "Validation.ProvideNumericGreaterThanZero",
});

extend("numeric", {
  ...numeric,
  message: "Validation.Numeric",
});

@Component({
  components: {
    ValidationProvider,
    ValidationObserver,
    ClassCollection,
    ProjectContext,
    LearnedAboutProject,
    OrgRegionSelect,
    Feedback,
    CanadaPostShippingAddress,
    YourSchool,
    NumberOfAdults,
    MailingList,
  },
})
export default class LivingSpace extends FormBase {
  name = "";
  model: any = {
    ShippingAddress: {},
    School: {},
  };

  orgApiService: OrganizationApiService = new OrganizationApiService();

  get Form(): string {
    return "LivingSpace";
  }

  get Instance(): string | null {
    return this.$route.params.instance;
  }

  get Locale(): string {
    return this.$i18n.locale;
  }

  get languageOptions() {
    return [
      {
        text: this.$root.$t("LivingSpace.Questions.Language.Options.English"),
        value: "English",
      },
      {
        text: this.$root.$t("LivingSpace.Questions.Language.Options.French"),
        value: "French",
      },
    ];
  }

  get dataCollectionOptions() {
    return [
      {
        text: this.$root.$t(
          "LivingSpace.Questions.DataCollectionMethod.Options.CommonDevices"
        ),
        value: "Common Devices",
      },
      {
        text: this.$root.$t(
          "LivingSpace.Questions.DataCollectionMethod.Options.AirQualityMonitor"
        ),
        value: "Air Quality Monitor",
      },
      {
        text: this.$root.$t(
          "LivingSpace.Questions.DataCollectionMethod.Options.MicrobitAndCozir"
        ),
        value: "Micro:bit and COZIR sensor",
      },
    ];
  }

  get equipmentRequestOptions() {
    return [
      {
        text: this.$root.$t(
          "LivingSpace.Questions.EquipmentRequested.Options.AirQualityMonitor"
        ),
        value: "Air Quality Monitor",
      },
      {
        text: this.$root.$t(
          "LivingSpace.Questions.EquipmentRequested.Options.MicrobitAndCozir"
        ),
        value: "Micro:bit and COZIR sensor",
      },
    ];
  }

  get yesNoOptions() {
    return [
      { text: this.$root.$t("YesNoOptions.Yes"), value: true },
      { text: this.$root.$t("YesNoOptions.No"), value: false },
    ];
  }

  get requestEquipmentOptions() {
    return [
      {
        text: this.$root.$t(
          "LivingSpace.Questions.RequestEquipment.Options.Yes"
        ),
        value: true,
      },
      {
        text: this.$root.$t(
          "LivingSpace.Questions.RequestEquipment.Options.No"
        ),
        value: false,
      },
    ];
  }

  get EquipmentRequest(): boolean {
    return this.Metadata.equipment_request ?? false;
  }

  mounted() {
    this.GetFormData().then((resp: any) => {
      this.submissionComplete = resp.Submissions > 0;
    });
  }

  onSubmit() {
    const classCollectionValid = (
      this.$refs.classCollection as ClassCollection
    ).validate();

    if (classCollectionValid === true) {
      this.SaveSubmission(this.model);
    }
  }
}
</script>
<style scoped>
.headerimg {
  max-width: 500px;
}

.question {
  padding-top: 20px;
}

#form {
  padding: 10px;
}

#submitbtn {
  margin-top: 25px;
}

.binput {
  max-width: 250px;
}
textarea.binput {
  max-width: 100%;
}

.step-progress {
  display: inline;
}

hr {
  background-color: rgb(145, 145, 145);
}

.phelp {
  font-style: italic;
}

#noschool-details:invalid {
  border: 1px solid red;
}

#noschool-details:valid {
  border: 1px solid green;
}

#confirmationheader {
  margin-top: 30px;
  text-align: center;
}

#confirmationsub {
  margin-top: 30px;
}

#confirmationbody {
  margin-top: 30px;
}

#confirmationbtncontainer {
  margin-top: 30px;
}

#headercontainer {
  text-align: center;
}

.question {
  font-weight: bold;
}
</style>
