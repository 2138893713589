<template>
  <div :id="id">
    <validation-provider
      v-slot="{ errors, valid }"
      :name="name"
      :rules="{ required: required }"
    >
      <b-form-group :label-for="`${id}-form-group`">
        <template #label>
          <slot name="label">
            <div v-if="simpleLabel">
              {{ Label }}
            </div>
            <p v-else :class="`question ${required ? 'required-field' : ''}`">
              {{ Label }}
            </p>
          </slot>
        </template>
        <b-form-radio-group
          :id="`${id}-radio-group`"
          v-model="selectedOption"
          :options="yesNoOptions"
          stacked
          @input="onChanged"
        >
        </b-form-radio-group>
      </b-form-group>
      <b-form-invalid-feedback :state="valid">
        <span v-for="(error, index) in errors" :key="index">{{
          $t(error)
        }}</span>
      </b-form-invalid-feedback>
    </validation-provider>
  </div>
</template>

<script lang="ts">
import { Component, Vue, Watch, Prop } from "vue-property-decorator";
import { ValidationProvider } from "vee-validate";

@Component({
  components: {
    ValidationProvider,
  },
})
export default class YesNoRadioOptions extends Vue {
  @Prop(String) id!: string;
  @Prop({ default: "" }) value!: string;
  @Prop({ required: true }) label!: string;
  @Prop(String) name!: string;
  @Prop(Object) rules!: object;
  @Prop({ default: false }) required!: boolean;
  @Prop(Boolean) simpleLabel!: boolean;

  selectedOption = this.value || "";

  get Label(): string {
    return this.label;
  }

  get yesNoOptions() {
    return [
      { text: this.$root.$t("YesNoOptions.Yes"), value: true },
      { text: this.$root.$t("YesNoOptions.No"), value: false },
    ];
  }

  @Watch("value")
  onValueChanged() {
    this.selectedOption = this.value;
  }

  onChanged() {
    this.$emit("input", this.selectedOption);
  }
}
</script>
<style scoped>
.question {
  padding-top: 20px;
  font-weight: bold;
}
</style>
