export default class PermissionForm {
  Id = "00000000-0000-0000-0000-000000000000";
  Deleted = false;
  Created = "0001-01-01";
  Modified = "0001-01-01";
  ExternalId = "";
  RegistrationId = "";
  Consent = false;
  DateSigned = "";
  EducatorFirstName = "";
  EducatorLastName = "";
  GuardianName = "";
  GuardianEmail = "";
  Signature = "";
  StudentFirstName = "";
  StudentLastName = "";
  StudentEmail = "";
  Grade = "";
  PhotoConsent = false;
  LongitudeStudyConsent = false;
  MedicalConditions = "";
  DeclineLongitudeStudyReason = "";
  AttachmentId: string | null = null;
}
