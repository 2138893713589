<template>
  <b-container fluid="xl">
    <b-nav vertical>
      <b-nav-item v-for="(r, idx) in routes" :key="idx" :to="r.path">
        {{ r.name }}
      </b-nav-item>
    </b-nav>

    <b-button
      class="mt-4"
      variant="primary"
      :disabled="patchingLocales"
      @click="postLocaleFiles"
    >
      Post Locale Files
    </b-button>
  </b-container>
</template>

<script lang="ts">
import i18n from "@/i18n";
import AuthorizationService from "@/services/AuthorizationService";
import LocalizationApiService from "@/services/LocalizationApiService";
import { Component, Vue } from "vue-property-decorator";
import { RouteRecordPublic } from "vue-router";

@Component
export default class AdminHome extends Vue {
  authService: AuthorizationService = AuthorizationService.getInstance();
  localizationApiService: LocalizationApiService = new LocalizationApiService();
  patchingLocales = false;

  get routes(): Array<any> {
    let i_idx = 0;
    return this.$router
      .getRoutes()
      .filter(
        (rrp: RouteRecordPublic) =>
          rrp.path.includes("Admin") && rrp.name !== "AdminHome"
      )
      .map((rrp: RouteRecordPublic) => {
        i_idx = rrp.path.indexOf(":instance?");
        return {
          name: rrp.name,
          path: i_idx > 0 ? rrp.path.substring(0, i_idx) : rrp.path,
        };
      });
  }

  get ElevatedUser(): boolean {
    return this.authService.hasRole(["localizer", "administrator"]);
  }

  postLocaleFiles() {
    if (this.ElevatedUser) {
      console.log(`patching with file: `);
      const locale_files = JSON.parse(JSON.stringify(i18n.messages));
      console.log(locale_files);
      this.patchingLocales = true;
      this.localizationApiService
        .PatchLocalizationFile("central_registration", locale_files)
        .then((resp) => {
          if (resp.Success) {
            console.log("keys added");
          }
        })
        .catch((ex) => {
          console.log(ex);
        })
        .finally(() => {
          this.patchingLocales = false;
        });
    }
  }
}
</script>
