import axios from "axios";
import UseCaseResponse from "@/types/UseCaseResponse";
import BaseApiService from "./BaseApiService";

export default class RouteDataApiService extends BaseApiService {
  async GetRouteData(formName: string, instanceName: string | null) {
    await this.defineHeader();

    let u = `${this.url}/api/registration/RouteData?formName=${formName}`;

    if (instanceName) {
      u += `&instanceName=${instanceName}`;
    }

    return axios.get(u).then((resp) => resp.data as UseCaseResponse<object[]>);
  }
}
