<template>
  <div>
    <p class="question required-field" :id="`${id}-contactQuestion`">
      {{ Label }}
    </p>

    <validation-provider
      v-slot="{ errors, valid }"
      name="Feedback"
      :rules="{ required: true }"
    >
      <b-form-group>
        <b-form-radio-group
          aria-describedby="contactQuestion"
          :id="`${id}-feedback`"
          v-model="feedback"
          :options="yesNoOptions"
          stacked
        >
        </b-form-radio-group>
      </b-form-group>
      <b-form-invalid-feedback :state="valid">
        <span v-for="(error, index) in errors" :key="index">{{
          $t(error)
        }}</span>
      </b-form-invalid-feedback>
    </validation-provider>
  </div>
</template>

<script lang="ts">
import { Component, Vue, Watch, Prop } from "vue-property-decorator";
import { ValidationProvider } from "vee-validate";

@Component({
  components: {
    ValidationProvider,
  },
})
export default class Feedback extends Vue {
  @Prop(String) id!: string;
  @Prop(Boolean) value!: boolean;
  @Prop(String) label!: string;

  feedback: boolean | null = this.value || null;

  get Label(): string {
    return this.label || this.$t("FeedbackQuestion").toString();
  }

  get yesNoOptions() {
    return [
      { text: this.$root.$t("YesNoOptions.Yes"), value: true },
      { text: this.$root.$t("YesNoOptions.No"), value: false },
    ];
  }

  @Watch("value")
  onValueChanged() {
    this.feedback = this.value;
  }

  @Watch("feedback")
  onChanged() {
    this.$emit("input", this.feedback);
  }
}
</script>
<style scoped>
.question {
  padding-top: 20px;
  font-weight: bold;
}
</style>
