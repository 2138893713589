import axios from "axios";
import UseCaseResponse from "@/types/UseCaseResponse";
import BaseApiService from "./BaseApiService";

export default class VmsApiService extends BaseApiService {
  async GetEnumerationListsBySystemName(
    systemNames: string[]
  ): Promise<UseCaseResponse<object[]>> {
    await this.defineHeader();
    const param = `systemNames=${systemNames.join(`&systemNames=`)}`;
    return axios
      .get(`${this.url}/api/vms/EnumerationList?${param}`)
      .then((resp) => resp.data as UseCaseResponse<object[]>);
  }

  async GetEnumerationListById(id: string): Promise<UseCaseResponse<object>> {
    await this.defineHeader();
    return axios
      .get(`${this.url}/api/vms/EnumerationList/${id}`)
      .then((resp) => resp.data as UseCaseResponse<object>);
  }

  async GetSites(): Promise<UseCaseResponse<any[]>> {
    await this.defineHeader();
    return axios
      .get(`${this.url}/api/vms/Site`)
      .then((resp) => resp.data as UseCaseResponse<any[]>);
  }

  async GetKits(siteId: string | undefined): Promise<UseCaseResponse<any[]>> {
    await this.defineHeader();
    let url = `${this.url}/api/vms/Kit`;

    if (siteId) {
      url += `?siteId=${siteId}`;
    }

    return axios.get(url).then((resp) => resp.data as UseCaseResponse<any[]>);
  }
}
