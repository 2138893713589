import axios from "axios";
import type UseCaseResponse from "@/types/UseCaseResponse";
import BaseApiService from "./BaseApiService";

export default class ZoomApiService extends BaseApiService {
  async GetMeetings(topic_filter: string): Promise<UseCaseResponse<object[]>> {
    await this.defineHeader();
    return axios
      .get(`${this.url}/api/zoom/Meeting?topic_filter=${topic_filter}`)
      .then((resp) => resp.data as UseCaseResponse<object[]>);
  }
}
