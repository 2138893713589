<template>
  <div>
    <h1>Zoom Forms</h1>
    <div v-if="loading" class="spinner"></div>
    <div v-else>
      <div class="input-group mb-3 centered-input-group">
        <input
          type="text"
          class="form-control"
          v-model="searchText"
          placeholder="Filter by event name or date."
          aria-label="Search Text"
          aria-describedby="button-addon2"
        />
        <div class="input-group-append">
          <button
            class="btn btn-outline-secondary"
            type="button"
            v-if="searchText"
            @click="searchText = ''"
          >
            <i class="fas fa-times"></i>
          </button>
        </div>
      </div>
      <div class="table-container">
        <table v-if="filteredZoomForms.length" class="table table-striped">
          <thead>
            <tr>
              <th
                v-for="field in fields"
                :key="field.key"
                @click="sortBy(field.key)"
                :class="{ 'sortable-header': field.key !== 'Actions' }"
              >
                {{ field.label }}
                <span v-if="field.key !== 'Actions'">
                  <span
                    v-if="sortKey === field.key && sortOrders[field.key] === 1"
                    >↓</span
                  >
                  <span
                    v-else-if="
                      sortKey === field.key && sortOrders[field.key] === -1
                    "
                    >↑</span
                  >
                  <span v-else>↕</span>
                </span>
              </th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="zoomForm in filteredZoomForms" :key="zoomForm.Id">
              <td class="td-column event-name">{{ zoomForm.Topic }}</td>
              <td class="td-column event-date">
                {{ formatDate(zoomForm.Start) }}
              </td>
              <td class="td-column event-type">{{ zoomForm.Type }}</td>
              <td class="td-column action">
                <button
                  @click="copyLinkToClipboard(zoomForm)"
                  class="btn btn-primary"
                >
                  Copy Registration Link
                </button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div v-if="totalPages >= 1" class="pagination-container">
        <button
          :disabled="currentPage === 1"
          @click="currentPage = 1"
          class="page-link"
          v-bind:class="{ 'disabled disabled-btn': currentPage === 1 }"
        >
          <i class="fas fa-step-backward"></i> First
        </button>
        <button
          :disabled="currentPage === 1"
          @click="currentPage--"
          class="page-link"
          v-bind:class="{ 'disabled disabled-btn': currentPage === 1 }"
        >
          <i class="fas fa-chevron-left"></i> Previous
        </button>
        <span class="pagination-text"
          >Page {{ currentPage }} of {{ totalPages }}</span
        >
        <button
          :disabled="currentPage === totalPages"
          @click="currentPage++"
          class="page-link"
          v-bind:class="{ 'disabled disabled-btn': currentPage === totalPages }"
        >
          Next <i class="fas fa-chevron-right"></i>
        </button>
        <button
          :disabled="currentPage === totalPages"
          @click="currentPage = totalPages"
          class="page-link"
          v-bind:class="{ 'disabled disabled-btn': currentPage === totalPages }"
        >
          Last <i class="fas fa-step-forward"></i>
        </button>
      </div>

      <p v-else>No Zoom Forms Found</p>
    </div>
  </div>
</template>

<script lang="ts">
import { Vue, Component, Watch } from "vue-property-decorator";
import ZoomFormApiService from "@/services/ZoomFormApiService";

@Component
export default class ZoomForms extends Vue {
  loading = false;
  zoomFormApiService = new ZoomFormApiService();
  zoomForms: any[] = [];
  selectedZoomForm: any = null;
  sortKey = "";
  sortOrders: { [key: string]: number } = {
    Topic: 1,
    Start: 1,
    Type: 1,
  };
  searchText = "";
  currentPage = 1;
  itemsPerPage = 10;
  filteredCount = 0;

  fields: any[] = [
    {
      key: "Topic",
      label: "Event Name",
    },
    {
      key: "Start",
      label: "Event Date",
    },
    {
      key: "Type",
      label: "Event Type",
    },
    {
      key: "Actions",
      label: "",
    },
  ];

  get filteredZoomForms() {
    let result = this.zoomForms;

    //Filter by search text
    if (this.searchText) {
      const lowerCaseSearchText = this.searchText.toLowerCase();

      result = result.filter((zoomForm) => {
        const isTopicMatch =
          zoomForm.Topic.toLowerCase().includes(lowerCaseSearchText);

        const formattedStartDate = this.formatDate(zoomForm.Start);
        const isStartDateMatch = formattedStartDate
          .toLowerCase()
          .includes(lowerCaseSearchText);

        return isTopicMatch || isStartDateMatch;
      });
    }

    //Sort by sort columnName
    if (this.sortKey) {
      result = result.slice().sort((a, b) => {
        let aValue = a[this.sortKey];
        let bValue = b[this.sortKey];

        if (this.sortKey === "Start") {
          aValue = new Date(aValue);
          bValue = new Date(bValue);
        }

        return (
          (aValue === bValue ? 0 : aValue > bValue ? 1 : -1) *
          this.sortOrders[this.sortKey]
        );
      });
    }

    this.filteredCount = result.length;

    const start = (this.currentPage - 1) * this.itemsPerPage;
    const end = start + this.itemsPerPage;
    result = result.slice(start, end);

    return result;
  }

  get totalPages() {
    return Math.ceil(this.filteredCount / this.itemsPerPage);
  }

  sortBy(key: string) {
    this.sortKey = key;
    this.sortOrders[key] = this.sortOrders[key] * -1;
  }

  async mounted() {
    await this.getAllData();
  }

  async getAllData() {
    this.loading = true;
    const response = await this.zoomFormApiService.GetAll();
    if (response.Success) {
      this.zoomForms = response.Value;
    }

    this.loading = false;
  }

  async copyLinkToClipboard(zoomForm: any) {
    const url = window.location.origin + "/zoom/" + zoomForm.ZoomId;

    navigator.clipboard.writeText(url).then(
      function () {
        alert("Zoom link copied to clipboard: \n\n" + url);
      },
      function () {
        alert("Could not copy Zoom link to clipboard \n\n" + url);
      }
    );
  }

  formatDate(dateString: string): string {
    const date = new Date(dateString);
    const options: Intl.DateTimeFormatOptions = {
      year: "numeric",
      month: "long",
      day: "2-digit",
      hour: "2-digit",
      minute: "2-digit",
      hour12: false,
    };

    return new Intl.DateTimeFormat("en-US", options).format(date);
  }

  @Watch("zoomForms.length")
  onZoomFormsLengthChanged(newLength: number) {
    const totalPages = Math.ceil(newLength / this.itemsPerPage);
    if (this.currentPage > totalPages) {
      this.currentPage = totalPages || 1;
    }
  }

  @Watch("searchText")
  onSearchTextChanged() {
    this.currentPage = 1;
  }
}
</script>

<style scoped>
.spinner {
  display: flex;
  justify-content: center;
  align-items: center;
  /* position: fixed; */
  /* left: 0;
        top: 0;
        right: 0;
        bottom: 0; */
  border: 16px solid #f3f3f3;
  border-top: 16px solid #3498db;
  border-radius: 50%;
  width: 60px;
  height: 60px;
  animation: spin 2s linear infinite;
  z-index: 1000;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.centered-input-group {
  width: 25%;
  margin-left: 8%;
}

.sortable-header {
  cursor: pointer;
}

.event-name {
  max-width: 250px;
  word-wrap: break-word;
  overflow-wrap: break-word;
}
.event-date {
  max-width: 100px;
}
.event-type {
  max-width: 70px;
  /* width: 70px; */
}
.action {
  max-width: 100px;
}

.pagination-container {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 10px 0;
  margin-left: 8%;
  margin-right: 8%;
}

.pagination-text {
  margin: 0 10px;
}

:root {
  --disabled-color: rgb(207, 205, 205);
}

.disabled-btn,
.disabled-btn:hover,
.disabled-btn:focus,
.disabled-btn:active {
  cursor: not-allowed;
  color: var(--disabled-color);
}

.table thead {
  background-color: #0054a4;
  color: white;
  border-radius: 10px;
}

.table thead th {
  border-radius: 10px;
  border-bottom: none;
}
.table {
  width: 100%;
}
.table-container {
  width: 85%;
  margin: auto;
  overflow-y: auto;
  max-height: 500px;
}
</style>
