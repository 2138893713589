<template>
  <b-container fluid="xl">
    <div v-if="!submissionComplete">
      <h1 class="h2">{{ $t("LTSChallenge.Header") }}</h1>
      <p>
        {{ $t("LTSChallenge.Intro") }}
      </p>

      <div>
        <p class="question required-field" id="role">
          {{ $t("LTSChallenge.Questions.DeliveryMode.Header") }}
        </p>

        <validation-provider
          v-slot="{ errors, valid }"
          name="delivery"
          :rules="{ required: true }"
        >
          <b-form-group
            id="delivery-group"
            :label="$t('LTSChallenge.Questions.DeliveryMode.SubHeader')"
            label-for="DeliveryMode"
            class="required-field"
          >
            <b-form-select
              id="DeliveryMode"
              class="binput"
              v-model="model.DeliveryMode"
              :options="deliveryModeOptions"
              @input="onDeliverModeChanged"
              :state="errors[0] ? false : valid ? true : null"
            />
          </b-form-group>
          <b-form-invalid-feedback :state="valid">
            <span v-for="(error, index) in errors" :key="index">{{
              $t(error)
            }}</span>
          </b-form-invalid-feedback>
        </validation-provider>
      </div>

      <hr />

      <validation-observer ref="form_vp">
        <b-form
          v-if="model.DeliveryMode !== undefined"
          @submit.prevent="onSubmit"
        >
          <!-- events table -->
          <table>
            <thead>
              <tr>
                <th>{{ $t("LTSChallenge.EventType") }}</th>
                <th>{{ $t("LTSChallenge.EventDate") }}</th>
                <th>{{ $t("LTSChallenge.Language") }}</th>
                <th>{{ $t("LTSChallenge.EventLocation") }}</th>
                <th>{{ $t("LTSChallenge.City") }}</th>
                <th>{{ $t("LTSChallenge.Province") }}</th>
                <th>{{ $t("LTSChallenge.RemainingCapacity") }}</th>
                <th>{{ $t("LTSChallenge.Status") }}</th>
                <th />
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="(e, idx) in eventOptions.filter(
                  (e) =>
                    model.DeliveryMode === 'Both' ||
                    e.type === model.DeliveryMode
                )"
                :key="idx"
                :style="
                  eventClosedOrCancelled(e.status)
                    ? 'background-color:lightgray'
                    : ''
                "
              >
                <td>{{ $t(`LTSChallenge.EventTypes.${e.type}`) }}</td>
                <td>{{ e.date }}</td>
                <td>{{ $t(`LTSChallenge.Language.${e.language}`) }}</td>
                <td>{{ e.location }}</td>
                <td>{{ e.city }}</td>
                <td>{{ e.province }}</td>
                <td>{{ e.remaining_capacity }}</td>
                <td>{{ $t(`LTSChallenge.EventStatus.${e.status_str}`) }}</td>
                <td>
                  <!-- ignoring errors about using both :value and v-model -> this is what boostrap expects -->
                  <!-- @vue-skip -->
                  <b-checkbox
                    v-if="!eventClosedOrCancelled(e.status)"
                    :id="`${
                      e.type === 'Virtual' ? 'v-' : 'ip-'
                    }event-cb-${idx}`"
                    :unchecked-value="null"
                    :value="{
                      EventId: e.id,
                      EventType: e.type,
                      EventStatus: e.status,
                    }"
                    type="checkbox"
                    :disabled="
                      selectedEvents.filter(
                        (x) => x.EventType === e.type && x.EventId !== e.id
                      ).length > 0
                    "
                    v-model="selectedEvents"
                  />
                </td>
              </tr>
            </tbody>
          </table>

          <!-- validator for events table -->
          <validation-provider
            v-slot="{ errors, valid }"
            name="EventValidator"
            rules="event_required"
          >
            <input
              id="event-validator-in"
              type="hidden"
              v-model="validEvents"
            />
            <b-form-invalid-feedback :state="valid">
              <span v-for="(error, index) in errors" :key="index">{{
                $t(error)
              }}</span>
            </b-form-invalid-feedback>
          </validation-provider>

          <p
            id="waitlist_notification"
            class="mt-4 mb-4"
            v-if="
              selectedEvents.find((e) => e.EventStatus === WaitlistedStatus) !==
              undefined
            "
          >
            {{ $t("LTSChallenge.WaitlistNotification") }}
          </p>

          <!-- School / Organization Information -->
          <div class="mt-4">
            <your-school v-model="model.School" />
          </div>

          <!-- Attendance Information -->
          <div v-if="InPersonEvent !== undefined">
            <h2 class="h5">
              {{ $t("LTSChallenge.InPersonAttendanceInformation") }}
            </h2>
            <validation-provider
              v-slot="{ errors, valid }"
              name="InPersonNumStudents"
              :rules="{
                required: true,
                numeric: true,
                min_value: 1,
                max_students: { max_students: MaxStudentsPerEducator },
              }"
            >
              <b-form-group
                id="inperson-numstudents-group"
                :label="$t('LTSChallenge.Questions.InPersonNumStudents')"
                class="required-field"
                label-for="inperson-numstudents"
              >
                <b-form-input
                  id="inperson-numstudents"
                  type="number"
                  class="binput"
                  v-model.number="attendance_info['InPerson'].NumStudents"
                />
              </b-form-group>
              <b-form-text>{{
                $t("LTSChallenge.HelpText.MaxStudentsPerEducator", {
                  ms: MaxStudentsPerEducator,
                })
              }}</b-form-text>
              <b-form-invalid-feedback :state="valid">
                <span v-for="(error, index) in errors" :key="index">{{
                  $t(error)
                }}</span>
              </b-form-invalid-feedback>
            </validation-provider>
            <validation-provider
              v-slot="{ errors, valid }"
              name="vp_inperson-grades"
              :rules="{ required: true }"
            >
              <b-form-group
                :label="$t('LTSChallenge.Questions.InPersonGrades')"
                label-for="inperson-grades"
              >
                <b-form-checkbox-group
                  id="inperson-grades"
                  v-model="attendance_info['InPerson'].Grades"
                  :options="gradeOptions"
                ></b-form-checkbox-group>
              </b-form-group>
              <b-form-invalid-feedback :state="valid">
                <span v-for="(error, index) in errors" :key="index">{{
                  $t(error)
                }}</span>
              </b-form-invalid-feedback>
            </validation-provider>
            <validation-provider
              v-slot="{ errors, valid }"
              name="vp-inperson-othergrades-info"
              :rules="{ required: true }"
              v-if="
                attendance_info['InPerson'].Grades &&
                attendance_info['InPerson'].Grades.includes('Other')
              "
            >
              <b-form-group
                :label="$t('LTSChallenge.Questions.InPersonOtherGradesInfo')"
                label-for="inperson-othergrades-info"
              >
                <b-textarea
                  id="inperson-othergrades-info"
                  v-model="attendance_info['InPerson'].OtherGradeInfo"
                />
              </b-form-group>
              <b-form-invalid-feedback :state="valid">
                <span v-for="(error, index) in errors" :key="index">{{
                  $t(error)
                }}</span>
              </b-form-invalid-feedback>
            </validation-provider>
            <validation-provider
              v-slot="{ errors, valid }"
              name="InPersonNumAdults"
              :rules="{ required: true, numeric: true, min_value: 0 }"
            >
              <b-form-group
                id="inperson-adults-group"
                :label="$t('LTSChallenge.Questions.InPersonNumAdults')"
                class="required-field"
                label-for="inperson-numadults"
              >
                <b-form-input
                  id="inperson-numadults"
                  type="number"
                  class="binput"
                  v-model.number="attendance_info['InPerson'].NumAdults"
                />
              </b-form-group>
              <b-form-invalid-feedback :state="valid">
                <span v-for="(error, index) in errors" :key="index">{{
                  $t(error)
                }}</span>
              </b-form-invalid-feedback>
            </validation-provider>
            <validation-provider
              v-slot="{ errors, valid }"
              name="InPersonPhoneNum"
              :rules="{ required: true, phoneNum: true }"
            >
              <b-form-group
                id="inperson-phone-group"
                :label="$t('LTSChallenge.Questions.InPersonPhone')"
                class="required-field"
                label-for="inperson-phone"
              >
                <b-form-input
                  id="inperson-phone"
                  type="text"
                  class="binput"
                  v-model="attendance_info['InPerson'].PhoneNumber"
                />
              </b-form-group>
              <b-form-invalid-feedback :state="valid">
                <span v-for="(error, index) in errors" :key="index">{{
                  $t(error)
                }}</span>
              </b-form-invalid-feedback>
            </validation-provider>
          </div>
          <div v-if="VirtualEvent !== undefined">
            <h2 class="h5">
              {{ $t("LTSChallenge.VirtualAttendanceInformation") }}
            </h2>
            <validation-provider
              v-slot="{ errors, valid }"
              name="VirtualNumStudents"
              :rules="{ required: true, numeric: true, min_value: 1 }"
            >
              <b-form-group
                id="virtual-numstudents-group"
                :label="$t('LTSChallenge.Questions.VirtualNumStudents')"
                class="required-field"
                label-for="virtual-numstudents"
              >
                <b-form-input
                  id="virtual-numstudents"
                  type="number"
                  class="binput"
                  v-model.number="attendance_info['Virtual'].NumStudents"
                />
              </b-form-group>
              <b-form-invalid-feedback :state="valid">
                <span v-for="(error, index) in errors" :key="index">{{
                  $t(error)
                }}</span>
              </b-form-invalid-feedback>
            </validation-provider>
            <validation-provider
              v-slot="{ errors, valid }"
              name="vp-virtual-grades"
              :rules="{ required: true }"
            >
              <b-form-group
                :label="$t('LTSChallenge.Questions.VirtualGrades')"
                label-for="virtual-grades"
              >
                <b-form-checkbox-group
                  id="virtual-grades"
                  v-model="attendance_info['Virtual'].Grades"
                  :options="gradeOptions"
                ></b-form-checkbox-group>
              </b-form-group>
              <b-form-invalid-feedback :state="valid">
                <span v-for="(error, index) in errors" :key="index">{{
                  $t(error)
                }}</span>
              </b-form-invalid-feedback>
            </validation-provider>
            <validation-provider
              v-slot="{ errors, valid }"
              name="vp-virtual-othergrades-info"
              :rules="{ required: true }"
              v-if="
                attendance_info['Virtual'].Grades &&
                attendance_info['Virtual'].Grades.includes('Other')
              "
            >
              <b-form-group
                :label="$t('LTSChallenge.Questions.VirtualOtherGradesInfo')"
                label-for="virtual-othergrades-info"
              >
                <b-textarea
                  id="virtual-othergrades-info"
                  v-model="attendance_info['Virtual'].OtherGradeInfo"
                />
              </b-form-group>
              <b-form-invalid-feedback :state="valid">
                <span v-for="(error, index) in errors" :key="index">{{
                  $t(error)
                }}</span>
              </b-form-invalid-feedback>
            </validation-provider>
            <validation-provider
              v-slot="{ errors, valid }"
              name="VirtualNumAdults"
              :rules="{ required: true, numeric: true, min_value: 0 }"
            >
              <b-form-group
                id="virtual-adults-group"
                :label="$t('LTSChallenge.Questions.VirtualNumAdults')"
                class="required-field"
                label-for="virtual-numadults"
              >
                <b-form-input
                  id="virtual-numadults"
                  type="number"
                  class="binput"
                  v-model.number="attendance_info['Virtual'].NumAdults"
                />
              </b-form-group>
              <b-form-invalid-feedback :state="valid">
                <span v-for="(error, index) in errors" :key="index">{{
                  $t(error)
                }}</span>
              </b-form-invalid-feedback>
            </validation-provider>
          </div>

          <!-- Additional Information -->
          <div>
            <h2 class="h5">
              {{ $t("LTSChallenge.AdditionalInformation") }}
            </h2>

            <language
              id="language"
              :label="$t('LTSChallenge.Questions.PracticeSessionLanguage')"
              :options="practiceSessionLanguageOptions"
              v-model="model.PracticeSessionLanguage"
            />

            <learned-about-project v-model="model.LearnedAbout" />

            <mailing-list id="mailinglist" v-model="model.MailingList" />

            <role id="role" v-model="model.Role" />
          </div>

          <p class="question" id="accessibility-q">
            {{ $t("LTSChallenge.Questions.AccessibilityRequirements") }}
          </p>
          <b-form-group id="accessibility-group">
            <b-form-textarea
              id="accessibility"
              class="binput"
              style="min-width: 500px"
              v-model="model.AccessibilityRequirements"
            />
          </b-form-group>

          <p class="question" id="other-info-q">
            {{ $t("LTSChallenge.Questions.OtherInfo") }}
          </p>
          <b-form-group id="other-info-group">
            <b-form-textarea
              id="other-info"
              class="binput"
              style="min-width: 500px"
              v-model="model.OtherInfo"
            />
          </b-form-group>

          <hr />

          <terms-and-conditions v-model="acceptedTerms" />

          <b-button
            id="btn_submit"
            type="submit"
            class="mt-2"
            variant="primary"
            :disabled="!acceptedTerms || saving"
            >{{ $t("Submit") }}</b-button
          >
        </b-form>
      </validation-observer>
    </div>
    <div v-else>
      <b-container id="confirmationheader">
        <h3>{{ $t("LTSChallenge.Confirmation.Header") }}</h3>
      </b-container>
      <hr />
      <p id="confirmationsub">
        <strong>
          {{ $t("LTSChallenge.Confirmation.SubHeader") }}
        </strong>
      </p>
      <p id="confirmationbody" v-html="$t('LTSChallenge.Confirmation.Body')" />
    </div>
  </b-container>
</template>

<script lang="ts">
import { Component } from "vue-property-decorator";
import { extend, ValidationProvider, ValidationObserver } from "vee-validate";
import YourSchool from "@/components/questions/YourSchool.vue";
import FormBase from "@/types/FormBase";
import Language from "@/components/questions/Language.vue";
import ClassCollection from "@/components/ClassCollection.vue";
import NumberOfAdults from "@/components/questions/NumberOfAdults.vue";
import LearnedAboutProject from "@/components/questions/LearnedAboutProject.vue";
import MailingList from "@/components/questions/MailingList.vue";
import TermsAndConditions from "@/components/questions/TermsAndConditions.vue";
import Role from "@/components/questions/Role.vue";
import UseCaseResponse from "@/types/UseCaseResponse";
import ChallengeApiService from "@/services/ChallengeApiService";
import LTSCEvent, {
  EventDeliveryModes,
  EventLanguages,
  EventStatus,
} from "@/types/challenge/LTSCEvent";
import i18n from "@/i18n";

extend("event_required", (value) => {
  if (value) return true;
  else return "LTSChallenge.Validation.EventRequired";
});

extend("max_students", {
  validate(value, { max_students }: any) {
    if (value > max_students)
      return "LTSChallenge.Validation.MaxStudentsPerEducator";
    else return true;
  },
  params: ["max_students"],
});

extend("phoneNum", (value) => {
  if (isNaN(value)) return "Validation.PhoneNum.NumbersOnly";
  else if (value.length !== 10) return "Validation.PhoneNum.MustBe10";
  else return true;
});

@Component({
  components: {
    ValidationProvider,
    Language,
    ValidationObserver,
    YourSchool,
    ClassCollection,
    NumberOfAdults,
    LearnedAboutProject,
    MailingList,
    TermsAndConditions,
    Role,
  },
})
export default class LTSChallenge extends FormBase {
  model: any = {
    School: {},
  };

  selectedEvents: Array<any> = [];

  acceptedTerms = false;

  challengeApiService: ChallengeApiService = new ChallengeApiService();
  events: LTSCEvent[] = [];

  attendance_info: any = {
    Virtual: {},
    InPerson: {},
  };

  get Form(): string {
    return "LTSChallenge";
  }

  get Instance(): string | null {
    return this.$route.params.instance;
  }

  get validEvents(): boolean {
    return this.selectedEvents.length > 0;
  }

  get deliveryModeOptions(): Array<any> {
    return [
      {
        text: this.$root.$t(
          "LTSChallenge.Questions.DeliveryMode.Options.InPerson"
        ),
        value: "InPerson",
      },
      {
        text: this.$root.$t(
          "LTSChallenge.Questions.DeliveryMode.Options.Virtual"
        ),
        value: "Virtual",
      },
      {
        text: this.$root.$t("LTSChallenge.Questions.DeliveryMode.Options.Both"),
        value: "Both",
      },
    ];
  }

  get practiceSessionLanguageOptions(): Array<any> {
    return [
      {
        text: this.$root.$t("LanguageField.Options.English"),
        value: "English",
      },
      { text: this.$root.$t("LanguageField.Options.French"), value: "French" },
      { text: this.$root.$t("LTSChallenge.BothLanguages"), value: "Both" },
    ];
  }

  get WaitlistedStatus(): EventStatus {
    return EventStatus.Waitlisted;
  }

  get eventOptions(): Array<any> {
    return this.events
      .filter((e: any) => e.Status !== EventStatus.Draft) /* exclude drafts */
      .filter(
        (e: any) => new Date() < new Date(e.CutoffDate)
      ) /* exclude if we are past the cutoff date */
      .sort((a, b) => {
        if (a.DeliveryMode > b.DeliveryMode) return 1;
        else if (a.DeliveryMode < b.DeliveryMode) return -1;
        else return 0;
      })
      .map((e: any) => {
        return {
          id: e.ExternalId,
          type: EventDeliveryModes[e.DeliveryMode],
          language: EventLanguages[e.Language],
          location: e.Location,
          city: e.City,
          province: e.Province,
          status: e.Status,
          status_str: EventStatus[e.Status],
          remaining_capacity: e.RemainingCapacity,
          date:
            i18n.locale === "fr" ? e.DisplayDateFrench : e.DisplayDateEnglish,
        };
      });
  }

  get gradeOptions(): any[] {
    return [
      {
        text: this.$root.$t("LTSChallenge.Grades.Grade6"),
        value: "Grade 06",
      },
      {
        text: this.$root.$t("LTSChallenge.Grades.Grade7"),
        value: "Grade 07",
      },
      {
        text: this.$root.$t("LTSChallenge.Grades.Grade8"),
        value: "Grade 08",
      },
      {
        text: this.$root.$t("LTSChallenge.Grades.Other"),
        value: "Other",
      },
    ];
  }

  get InPersonEvent(): any {
    return this.selectedEvents.find((e) => e.EventType === "InPerson");
  }

  get VirtualEvent(): any {
    return this.selectedEvents.find((e) => e.EventType === "Virtual");
  }

  get MaxStudentsPerEducator(): number {
    const event = this.events.find(
      (e) => e.ExternalId === this.InPersonEvent.EventId
    );
    if (event) {
      return event.MaxTeamsPerEducator * 4;
    } else {
      return 0;
    }
  }

  mounted() {
    this.GetFormData();

    this.challengeApiService.GetEvents().then((resp) => {
      this.events = resp.Value;
    });
  }

  eventClosedOrCancelled(es: EventStatus): boolean {
    return es === EventStatus.Closed || es === EventStatus.Cancelled;
  }

  onDeliverModeChanged() {
    this.selectedEvents = this.selectedEvents.filter(
      (e) =>
        this.model.DeliveryMode === "Both" ||
        e.EventType === this.model.DeliveryMode
    );
  }

  onSubmit() {
    (this.$refs.form_vp as any).validate().then((success: any) => {
      if (success) {
        this.save();
      } else {
        (this as any).$ScrollToFirstError();
      }
    });
  }

  save() {
    this.saving = true;
    this.submissionApiService
      .All(
        this.selectedEvents.map((e) => {
          const s = {
            ...e,
            ...this.attendance_info[e.EventType],
            ...this.model,
          };

          return this.submissionApiService.CreateSubmission(
            this.Form,
            this.Instance,
            s
          );
        })
      )
      .then((resps) => {
        if (resps.find((ucr: UseCaseResponse<string>) => !ucr.Success)) {
          console.log("an error occurred saving registrations");
          console.log(resps);
        } else {
          this.submissionComplete = true;
          this.SubmissionComplete();
        }
      })
      .finally(() => (this.saving = false));
  }
}
</script>

<style scoped>
hr {
  background-color: rgb(145, 145, 145);
}

.question {
  padding-top: 20px;
  font-weight: bold;
}

table,
th,
td {
  border: 1px solid black;
  padding: 10px;
  margin-top: 10px;
}

thead {
  background-color: #0053a1;
  color: white;
}

#waitlist_notification {
  color: #0053a1;
  font-weight: bold;
}
</style>
