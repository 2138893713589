import axios from "axios";
import UseCaseResponse from "@/types/UseCaseResponse";
import BaseApiService from "./BaseApiService";
import Team from "@/types/challenge/Team";
import PermissionForm from "@/types/challenge/PermissionForm";
import TeamMember from "@/types/challenge/TeamMember";
import LTSCRegistration from "@/types/challenge/LTSCRegistration";
import LTSCEvent from "@/types/challenge/LTSCEvent";

export default class ChallengeApiService extends BaseApiService {
  async GetEvents() {
    await this.defineHeader();
    return axios
      .get(`${this.url}/api/challenge/Event`)
      .then((resp) => resp.data as UseCaseResponse<LTSCEvent[]>);
  }

  async GetRegistrationById(
    id: string
  ): Promise<UseCaseResponse<LTSCRegistration>> {
    await this.defineHeader();
    return axios
      .get(`${this.url}/api/challenge/Registration/${id}`)
      .then((resp) => resp.data as UseCaseResponse<LTSCRegistration>);
  }

  async GetTeamsByRegistrationId(id: string): Promise<UseCaseResponse<Team[]>> {
    await this.defineHeader();
    return axios
      .get(`${this.url}/api/challenge/Team?registration_id=${id}`)
      .then((resp) => resp.data as UseCaseResponse<Team[]>);
  }

  async CreateTeam(team: Team): Promise<UseCaseResponse<string>> {
    await this.defineHeader();
    return axios
      .post(`${this.url}/api/challenge/Team`, team)
      .then((resp) => resp.data as UseCaseResponse<string>);
  }

  async UpdateTeam(team: Team): Promise<UseCaseResponse<boolean>> {
    await this.defineHeader();
    return axios
      .put(`${this.url}/api/challenge/Team/${team.Id}`, team)
      .then((resp) => resp.data as UseCaseResponse<boolean>);
  }

  async DeleteTeam(id: string): Promise<UseCaseResponse<boolean>> {
    await this.defineHeader();
    return axios
      .delete(`${this.url}/api/challenge/Team/${id}`)
      .then((resp) => resp.data as UseCaseResponse<boolean>);
  }

  async CreateTeamMember(member: TeamMember): Promise<UseCaseResponse<string>> {
    await this.defineHeader();
    return axios
      .post(`${this.url}/api/challenge/Team/${member.TeamId}/Member`, member)
      .then((resp) => resp.data as UseCaseResponse<string>);
  }

  async DeleteTeamMember(
    member: TeamMember
  ): Promise<UseCaseResponse<boolean>> {
    await this.defineHeader();
    return axios
      .delete(
        `${this.url}/api/challenge/Team/${member.TeamId}/Member/${member.Id}`
      )
      .then((resp) => resp.data as UseCaseResponse<boolean>);
  }

  async UpdateTeamMember(
    member: TeamMember
  ): Promise<UseCaseResponse<boolean>> {
    await this.defineHeader();
    return axios
      .put(
        `${this.url}/api/challenge/Team/${member.TeamId}/Member/${member.Id}`,
        member
      )
      .then((resp) => resp.data as UseCaseResponse<boolean>);
  }

  async GetPermissionFormsByRegistrationId(
    id: string
  ): Promise<UseCaseResponse<PermissionForm[]>> {
    await this.defineHeader();
    return axios
      .get(`${this.url}/api/challenge/PermissionForm?registration_id=${id}`)
      .then((resp) => resp.data as UseCaseResponse<PermissionForm[]>);
  }

  async CreatePermissionForm(
    pm: PermissionForm
  ): Promise<UseCaseResponse<string>> {
    return axios
      .post(`${this.url}/api/public/challenge/PermissionForm`, pm)
      .then((resp) => resp.data as UseCaseResponse<string>);
  }

  async UpdateRegistration(
    reg: LTSCRegistration
  ): Promise<UseCaseResponse<boolean>> {
    await this.defineHeader();
    return axios
      .put(`${this.url}/api/challenge/Registration/${reg.Id}`, reg)
      .then((resp) => resp.data as UseCaseResponse<boolean>);
  }

  async GetEventDetailsByRegistrationId(
    registration_id: string
  ): Promise<UseCaseResponse<object>> {
    return axios
      .get(
        `${this.url}/api/public/challenge/PermissionForm/EventDetails?registration_id=${registration_id}`
      )
      .then((resp) => resp.data as UseCaseResponse<object>);
  }

  async GetRegistrations(): Promise<UseCaseResponse<LTSCRegistration[]>> {
    await this.defineHeader();
    return axios
      .get(`${this.url}/api/challenge/Registration`)
      .then((resp) => resp.data as UseCaseResponse<LTSCRegistration[]>);
  }
}
