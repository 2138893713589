<template>
  <div :id="id">
    <p :id="`${id}-label`" class="question" v-if="!hideLabel">
      {{ Label }}
    </p>

    <validation-provider
      :id="`${id}-vp`"
      v-slot="{ errors, valid }"
      name="language"
      :rules="{ required: true }"
    >
      <b-form-group
        :id="`${id}-group`"
        :label="SubLabel"
        label-for="language"
        class="required-field"
      >
        <b-form-select
          :id="`${id}-select`"
          class="binput"
          v-model="language"
          :options="Options"
          :state="errors[0] ? false : valid ? true : null"
          @input="onInput"
        />
      </b-form-group>
      <b-form-invalid-feedback :state="valid">
        <span v-for="(error, index) in errors" :key="index">{{
          $t(error)
        }}</span>
      </b-form-invalid-feedback>
    </validation-provider>
  </div>
</template>

<script lang="ts">
import { Component, Vue, Watch, Prop } from "vue-property-decorator";
import { ValidationProvider } from "vee-validate";

@Component({
  components: {
    ValidationProvider,
  },
})
export default class Language extends Vue {
  @Prop(String) value!: string;
  @Prop(String) id!: string;
  @Prop(String) label!: string;
  @Prop(String) sublabel!: string;
  @Prop(Array) options!: Array<any>;
  @Prop(Boolean) hideLabel!: boolean;

  language: string = this.value || "";

  get Label(): string {
    return this.label || this.$t("LanguageField.Question").toString();
  }

  get SubLabel(): string {
    return this.sublabel || this.$t("LanguageField.Label").toString();
  }

  get Options(): Array<any> {
    return this.options || this.languageOptions;
  }

  @Watch("value")
  onValueChanged() {
    this.language = this.value || "";
  }

  onInput() {
    this.$emit("input", this.language);
  }

  get languageOptions() {
    return [
      {
        text: this.$root.$t("LanguageField.Options.English"),
        value: "English",
      },
      { text: this.$root.$t("LanguageField.Options.French"), value: "French" },
    ];
  }
}
</script>
<style scoped>
.question {
  padding-top: 20px;
  font-weight: bold;
}
</style>
