import { render, staticRenderFns } from "./Canvas.vue?vue&type=template&id=43fd3a26&scoped=true"
import script from "./Canvas.vue?vue&type=script&lang=ts"
export * from "./Canvas.vue?vue&type=script&lang=ts"
import style0 from "./Canvas.vue?vue&type=style&index=0&id=43fd3a26&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "43fd3a26",
  null
  
)

export default component.exports