import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import VueGtag from "vue-gtag";
import configurationService from "@/services/ConfigurationService";
import i18n from "./i18n";
import { BootstrapVue, IconsPlugin } from "bootstrap-vue";
import { GetConfigForEnvironment } from "./env-config";

// Import Bootstrap and BootstrapVue CSS files (order is important)
// import "bootstrap/dist/css/bootstrap.css";
import "./bootstrap/bootstrap.css";
import "./fontawesome/css/all.min.css";
import "bootstrap-vue/dist/bootstrap-vue.css";

import VueSignaturePad from "vue-signature-pad";
Vue.use(VueSignaturePad);

document.title = i18n.t("SiteTitle").toString();

// Make BootstrapVue available throughout your project
Vue.use(BootstrapVue);
// Optionally install the BootstrapVue icon components plugin
Vue.use(IconsPlugin);

Vue.config.productionTip = false;
const envConfig = GetConfigForEnvironment();

const config: any = {
  urls: {
    ltsol_id: envConfig.idUrl,
    ltsol_apis: envConfig.apiUrl,
  },
  showVersion: envConfig.showVersion,
};

configurationService.init(config);

Vue.prototype.$ScrollToFirstError = () => {
  const els = document.querySelectorAll(".d-block.invalid-feedback");

  if (els && els.length > 0) {
    els[0].scrollIntoView({ block: "center" });
  }
};

new Vue({
  router,
  i18n,
  render: (h) => h(App),
}).$mount("#app");

Vue.use(
  VueGtag,
  {
    config: {
      id: "UA-10578100-25",
      includes: [
        {
          id: "G-DSXLP5GM1Q",
          params: {
            send_page_view: true,
          },
        },
      ],
      params: {
        sendPageView: true,
      },
    },
  },
  router
);
